































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    name: 'ConfirmModal',
    components: {

    },
})
export default class ConfirmModal extends Vue {

    // Mounted is called initially
    // private mounted() {

    // }

    private confirm() {
        this.$emit('toggleConfirmModal', true);
    }

    private toggleModal() {
        this.$emit('toggleConfirmModal', false);
    }
}
