





































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import moment from 'moment';
import { Module } from '@/models/module';
import { Event } from '@/models/event';
import { moduleWebservice } from '@/webservices/module';
import { eventWebservice } from '@/webservices/event';

import ActivateModuleModal from '@/components/module/manager/ActivateModuleModal.vue';
import ShareModuleModal from '@/components/module/manager/ShareModuleModal.vue';
import DetailedConfirmModal from '@/components/prine/DetailedConfirmModal.vue';

import ModuleProgressStatsSimple from '@/components/module/stats/module-progress-simple/ModuleProgressStatsSimple.vue';

@Component({
    name: 'SharedModuleCard',
    components: {
        ActivateModuleModal,
        ShareModuleModal,
        DetailedConfirmModal,
        ModuleProgressStatsSimple,
    },
})
export default class SharedModuleCard extends Vue {
    @Prop() private module: Module;

    private affectedData: Map<string, number> = new Map();
    private isConfirmModalActive: boolean = false;
    private isModuleCoverUploadModalActive: boolean = false;

    private isModalActive: boolean = false;
    private isShareModalActive: boolean = false;

    private event: Event;
    private isLoading: boolean = true;

    @Action('module/fetchAllModules')
    private fetchAllModules: any;

    // Mounted is called initially
    private async mounted() {
        this.affectedData.set(this.$tc('keywords.block', 2).toString(), this.module.blocks_count);
    }

    private reload() {
        this.fetchAllModules();
    }

    private get storeAppUrl() {
        return process.env.VUE_APP_STORE_URL;
    }

    private async toggleConfirmModal(answer: boolean) {
        if (answer === true) {
            await moduleWebservice.remove(this.module);
            this.isConfirmModalActive = !this.isConfirmModalActive;
            this.fetchAllModules();
        } else {
            this.isConfirmModalActive = !this.isConfirmModalActive;
        }
    }

    private async publish(module: Module) {
        await moduleWebservice.publish(module);
        this.fetchAllModules();
    }

    private toggleActivateModuleModal() {
        this.isModalActive = !this.isModalActive;
    }

    private toggleShareModuleModal() {
        this.isShareModalActive = !this.isShareModalActive;
    }

    private get authorName() {
        if (this.module.author) {
            return this.module.author.firstname.substr(0, 1) + '. ' + this.module.author.lastname;
        }

        return 'Kein Author';
    }

    private get activeClassNames() {
        const groupNames = this.module.activeModules.map((activeModule) => {
            if (activeModule.events.length > 0) {
                return activeModule.events[0].group.name;
            }
        });

        return groupNames.join(', ');
    }
}
