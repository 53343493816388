
















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';

import { GridElementPosition } from '@/components/prine/gridtable/GridElementPosition';
import { Block } from '@/models/block';
import { blockWebservice } from '@/webservices/block';
import { Module } from '@/models/module';
import Modal, { ModalOptions } from '@/components/prine/modal/Modal.vue';
import * as R from 'ramda';
import { HtmlHelper } from '@/components/prine/helpers/HtmlHelper';
import { FormState } from '@/components/prine/enums/FormState';
import DetailedConfirmModal from '@/components/prine/DetailedConfirmModal.vue';

@Component({
    name: 'CreateBlockModal',
    components: {
        Modal,
        DetailedConfirmModal,
    },
})
export default class CreateBlockModal extends Vue {
    private htmlHelper: HtmlHelper = new HtmlHelper();

    private blockData: Block = {} as Block;

    private modalOptions: ModalOptions = {
        width: 1000,
        height: null,
        showDelete: false,
        isLoading: false,
        saveButtonText: 'Speichern',
        saveDisabled: false,
    };
    private errorMsg: string = '';
    private state: FormState = FormState.New;
    private affectedData: Map<string, number> = new Map();
    private isConfirmModalActive: boolean = false;

    @Prop() private block: Block;
    @Prop() private position: GridElementPosition;
    @Prop() private module: Module;

    @Action('module/reloadSelectedModule') private reloadSelectedModule: any;

    private mounted() {
        this.blockData = JSON.parse(JSON.stringify(this.block));
        this.htmlHelper.setFocusById('blockName');

        if (this.blockData.hasOwnProperty('id') && this.blockData.id !== null) {
            this.state = FormState.Update;
            this.modalOptions.showDelete = true;
        }
    }

    /**
     * Emits the toggle method in the parent
     */
    private toggleModal() {
        this.$emit('toggleCreateBlockModal');
    }

    /**
     * Store or update the block
     */
    private store() {
        if (R.isNil(this.blockData.name) || R.isEmpty(this.blockData.name)) {
            this.errorMsg = 'name';
            return false;
        }

        this.modalOptions.isLoading = true;

        if (this.blockData.hasOwnProperty('id') && this.block.id !== null) {
            this.updateBlock(this.blockData);
        } else {
            this.storeBlock(this.blockData);
        }
    }

    /**
     * Store the block
     *
     * @param block Block
     */
    private storeBlock(block: Block) {
        // Set necesarry data before storing
        block.order = this.position.column;
        block.module_id = this.module.id;

        blockWebservice.store(block).then((response) => {
            this.block.id = response.data.id;

            this.toggleModal();
            this.reloadSelectedModule();
            this.modalOptions.isLoading = false;
        });
    }

    /**
     * Update the block
     *
     * @param block Block
     */
    private updateBlock(block: Block) {
        blockWebservice.update(block).then((response) => {
            this.toggleModal();
            this.reloadSelectedModule();
            this.modalOptions.isLoading = false;
        });
    }

    private async toggleConfirmModal(answer: boolean) {
        console.log('toggleConfirmModal triggered');
        if (this.block.topics.length === 0 || answer === true) {
            this.remove();
        } else {
            this.isConfirmModalActive = !this.isConfirmModalActive;
        }
    }

    private remove() {
        console.log('rm');
        this.modalOptions.isLoading = true;
        blockWebservice.remove(this.blockData).then((response) => {
            this.toggleModal();
            this.reloadSelectedModule();
            this.modalOptions.isLoading = false;
        });
    }

    private get modalTitle() {
        if (this.state === FormState.New) {
            return this.$t('labels.newBlock').toString();
        } else {
            return this.$t('labels.updateBlock').toString();
        }
    }
}
