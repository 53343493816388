
























































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import ActiveTab from './tabs/active/ActiveTab.vue';
import SharedTab from './tabs/shared/SharedTab.vue';
import OwnTab from './tabs/own/OwnTab.vue';
import { Module } from '@/models/module';
import { moduleWebservice } from '@/webservices/module';
import Shepherd from 'shepherd.js';
import { User } from '@/models/user';
import { userWebservice } from '@/webservices/user';

@Component({
    name: 'ModuleTabContainer',
    components: {
        ActiveTab,
        SharedTab,
        OwnTab,
    },
})
export default class ModuleTabContainer extends Vue {
    @Getter('module/activeModules')
    private activeModules: Module[];

    @Getter('module/finishedModules')
    private finishedModules: Module[];

    @Getter('module/sharedModules')
    private sharedModules: Module[];

    @Getter('module/ownModules')
    private ownModules: Module[];

    @Action('module/fetchAllModules')
    private fetchAllModules: any;

    @Action('moduleMetadata/fetchAllMetadata')
    private fetchAllMetadata: any;

    @Getter('account/haveAccess') private haveAccess: boolean;

    @Getter('account/currentUser') private currentUser: User;

    private tour = new Shepherd.Tour({
        useModalOverlay: true,
        defaultStepOptions: {
            classes: 'shadow-md bg-purple-dark',
            scrollTo: true,
        },
        exitOnEsc: true,
    });

    private mounted() {
        // After a successful login user haveAccess is set to true
        // So we can safely check haveAccess before loading/displaying sub components(in template by using v-if)
        // It's makes it much faster to check for user access without trying to load lots of stuff
        // Because our default root route is not this component

        if (this.haveAccess) {
            this.fetchAllModules();
            this.fetchAllMetadata();
            if (this.$route.query.hasOwnProperty('defaultTabIndex')) {
                const defaultTabIndex = Number(this.$route.query.defaultTabIndex);

                // Check if the index is lower than 3, if not set it as default to the available modules tab (1)
                this.activeTabIndex = defaultTabIndex <= 2 ? defaultTabIndex : 1;
            }
            if (this.currentUser.show_tour) {
                this.shepherdTour(this);
            }
        }
    }

    private get activeTabIndex() {
        return this.$store.state.setting.manageModulesActiveTabIndex;
    }

    private set activeTabIndex(value) {
        this.$store.commit('setting/setManageModulesActiveTabIndex', value);
    }

    private goToStore() {
        if (process.env.VUE_APP_STORE_URL) {
            window.location.href = process.env.VUE_APP_STORE_URL;
        }
    }

    private shepherdTour(component: ModuleTabContainer) {
        this.tour.addStep({
            title: 'piQuest Tour',
            text: 'Herzlich Willkommen zu piQuest. Du kannst diese Tour jederzeit abbrechen.',
            buttons: [
                {
                    action() {
                        // Before start, load the Grid View
                        const viewTypeGridSelector = document.getElementById('viewTypeGrid');
                        if (viewTypeGridSelector) {
                            viewTypeGridSelector.click();
                        }
                        return this.next();
                    },
                    classes: 'button is-primary',
                    text: 'Next',
                },
                {
                    action() {
                        cancelTour();
                        return this.cancel();
                    },
                    classes: 'button is-primary',
                    text: 'Tour überspringen',
                },
            ],
            id: 'creating',
        });
        this.tour.addStep({
            title: 'Module',
            text:
                '<b>Aktive Module</b> <br>Diese Module sind für Klassen aufgeschaltet.' +
                '<br><br><b>Verfügbare Module</b> <br>Diese Module können für Klassen aufgeschaltet werden.' +
                '<br><br> <b>Eigene Module</b> <br>Diese Module hast du selber erstellt oder du bist sie am Erstellen.',
            attachTo: {
                element: '.tabs',
                on: 'top',
            },
            canClickTarget: false,
            buttons: [
                {
                    action() {
                        return this.back();
                    },
                    classes: 'button is-primary',
                    text: 'Back',
                },
                {
                    action() {
                        return this.next();
                    },
                    classes: 'button is-primary',
                    text: 'Next',
                },
                {
                    action() {
                        cancelTour();
                        return this.cancel();
                    },
                    classes: 'button is-primary',
                    text: 'Tour überspringen',
                },
            ],
            id: 'creating',
        });

        this.tour.addStep({
            title: 'Liste der Module',
            text: `Hier findest du alle Module des jeweiligen Registers (Aktive, Verfügbare oder Eigene Module).`,
            attachTo: {
                element: '.tab-content',
                on: 'top',
            },
            canClickTarget: false,
            scrollTo: false,
            buttons: [
                {
                    action() {
                        return this.back();
                    },
                    classes: 'button is-primary',
                    text: 'Back',
                },
                {
                    action() {
                        const activeElementSelector = document.getElementById('activeElement');
                        if (activeElementSelector) {
                            activeElementSelector.click();
                        }
                        return this.next();
                    },
                    classes: 'button is-primary',
                    text: 'Next',
                },
                {
                    action() {
                        cancelTour();
                        return this.cancel();
                    },
                    classes: 'button is-primary',
                    text: 'Tour überspringen',
                },
            ],
            id: 'creating',
        });

        this.tour.addStep({
            title: 'Layout',
            text: 'Hier kannst du zwischen Kachel-Darstellung und Liste-Darstellung wählen.',
            attachTo: {
                element: '.tab-content .toolbar',
                on: 'bottom-start',
            },
            canClickTarget: false,
            buttons: [
                {
                    action() {
                        return this.back();
                    },
                    classes: 'button is-primary',
                    text: 'Back',
                },
                {
                    action() {
                        return this.next();
                    },
                    classes: 'button is-primary',
                    text: 'Next',
                },
                {
                    action() {
                        cancelTour();
                        return this.cancel();
                    },
                    classes: 'button is-primary',
                    text: 'Tour überspringen',
                },
            ],
            id: 'creating',
        });

        this.tour.addStep({
            title: 'Sidebar',
            text:
                'Hier findest du wichtige Zusatzinformationen zu den Modulen. <br> <br>' +
                '<b>Aktive Module</b> <br>Informationen zur Studententätigkeit' +
                '<br><br><b>Verfügbare Module</b> <br>Informationen zu Änderungen am Modul' +
                '<br><br> <b>Eigene Module</b> <br>Rückmeldungen von Lernenden zu deinem Modul',
            attachTo: {
                element: '.sidebar',
                on: 'left-start',
            },
            buttons: [
                {
                    action() {
                        return this.back();
                    },
                    classes: 'button is-primary',
                    text: 'Back',
                },
                {
                    action() {
                        const viewTypeListSelector = document.getElementById('viewTypeList');
                        if (viewTypeListSelector) {
                            viewTypeListSelector.click();
                        }
                        return this.complete();
                    },
                    classes: 'button is-primary',
                    text: 'Next',
                },
                {
                    action() {
                        cancelTour();
                        return this.cancel();
                    },
                    classes: 'button is-primary',
                    text: 'Tour überspringen',
                },
            ],
            id: 'creating',
        });

        // Before start, load the Grid View
        const selector = document.getElementById('viewTypeGrid');
        if (selector) {
            selector.click();
        }
        this.tour.start();

        async function cancelTour() {
            await userWebservice.changeTour(false);
            await userWebservice.getUserInfo().then((response) => {
                component.$store.commit('account/setCurrentUser', response);
            });
            // Show a snackbar message that tour can be enabled in the settings
            component.$buefy.snackbar.open({
                duration: 7000,
                message: `Du hast die Tour abgebrochen. Sie wird dir nicht mehr angezeigt. Falls du sie wieder aktivieren möchtest, kannst du das in den Einstellungen vornehmen.`,
                type: 'is-primary',
                queue: false,
                position: 'is-top',
            });
        }
    }
}
