


















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';

import GridTable from '@/components/prine/gridtable/GridTable.vue';
import GridElementTopic from '@/components/module/stats/module-progress/elements/topic/GridElementTopic.vue';
import GridElementBlock from '@/components/module/stats/module-progress/elements/block/GridElementBlock.vue';
import { moduleWebservice } from '@/webservices/module';

import { Topic } from '@/models/topic';
import { Block } from '@/models/block';
import { Module } from '@/models/module';
import { User } from '@/models/user';
import { Question } from '@/models/question';
import Shepherd from 'shepherd.js';
import { userWebservice } from '@/webservices/user';

@Component({
    name: 'ModuleProgressStats',
    components: {
        GridTable,
        GridElementTopic,
        GridElementBlock,
    },
})
export default class ModuleProgressStats extends Vue {
    @Getter('account/currentUser') private currentUser: User;
    @Getter('module/selectedModule') private module: Module;
    @Action('module/reloadSelectedModule') private reloadSelectedModule: any;

    private loaded: boolean = false;

    private data = {
        header: new Array<Block>(),
        body: new Array<Topic>(),
    };

    private tour = new Shepherd.Tour({
        useModalOverlay: true,
        defaultStepOptions: {
            classes: 'shadow-md bg-purple-dark',
            scrollTo: true,
        },
        exitOnEsc: true,
    });

    @Watch('module')
    private onModuleValueChange() {
        this.data = this.initializeModule();
    }

    private mounted() {
        // Call load module if there is a module_id in the url
        if (this.$route.query.hasOwnProperty('module_id') && this.$route.query.hasOwnProperty('event_id')) {
            this.loadModuleById(Number(this.$route.query.module_id), Number(this.$route.query.event_id));
        } else {
            // There could be already a module from earlier set. We need to overwrite this
            this.$store.commit('module/resetSelectedModule');
            this.data = this.initializeModule();
            this.loaded = true;
        }
        if (this.currentUser.show_tour) {
            this.startTour(this);
        }
    }

    /**
     * Load the module by a given id
     */
    private async loadModuleById(module_id: number, event_id: number) {
        const loadedModule = await moduleWebservice.getModuleWithEvaluationsById(module_id, event_id);

        this.$store.commit('module/setSelectedModule', loadedModule);

        this.data = this.initializeModule();
        this.loaded = true;
    }

    private initializeModule() {
        const blocks = new Array<Block>();
        let allTopics = new Array<Topic>();

        // CREATE HEADER data
        // Create all blocks
        for (let columnNumber = 1; columnNumber <= this.module.columns; columnNumber++) {
            let block: Block | undefined = this.module.blocks.find((el) => {
                return el.order === columnNumber;
            });

            // Create the block if it wasn't found
            if (block === undefined) {
                block = {} as Block;
                block.id = null;
                block.order = columnNumber;
            }

            // Set reference to topics as well and push it to the array
            blocks.push(block);
        }

        const topics = new Array<Topic>();

        // CREATE BODY data
        // Iterate over all rows and columns and fetch or create the topic
        for (let rowNumber = 1; rowNumber <= this.module.rows; rowNumber++) {
            // Create the topics and connect them to the blocks
            for (let columnNumber = 1; columnNumber <= this.module.columns; columnNumber++) {
                const block: Block | undefined = this.module.blocks.find((el) => {
                    return el.order === columnNumber;
                });

                let topic: Topic | undefined;

                // Fetch the topic in the given block if there is an element at that order (position)
                if (block !== undefined && block.hasOwnProperty('topics') && block.topics.length > 0) {
                    topic = block.topics.find((el) => {
                        return el.order === rowNumber;
                    });
                }

                // Create the topic if it wasn't found
                if (topic === undefined) {
                    topic = {} as Topic;
                    topic.id = null;
                    topic.questions = Array<Question>();
                    topic.order = rowNumber;
                }

                topics.push(topic);
            }
        }

        // Store all topics together in this array
        allTopics = allTopics.concat(topics);

        // Prepare the data for the grid-table
        const data = {
            header: blocks,
            body: allTopics,
        };

        return data;
    }

    private startTour(component: ModuleProgressStats) {
        this.tour.addStep({
            title: 'Detailansicht',
            text: 'Du befindest dich jetzt in der Detailansicht eines Moduls.',
            canClickTarget: false,
            buttons: [
                {
                    action() {
                        return this.next();
                    },
                    classes: 'button is-primary',
                    text: 'Next',
                },
                {
                    action() {
                        cancelTour();
                        return this.cancel();
                    },
                    classes: 'button is-primary',
                    text: 'Tour überspringen',
                },
            ],
            id: 'creating',
        });
        this.tour.addStep({
            title: 'Detailansicht',
            text:
                'Die einzelnen Kacheln sind die Themenbereiche des Moduls. Jeder senkrechte ' +
                'Balken in einer Kachel steht jeweils für eineN LernendeN. <br><br>' +
                'Falls du keine Balken siehst, hat sich noch keinE LernendeR in dein Modul eingeschrieben. <br><br>' +
                'Die Einfärbung des Balkens bedeutet die Abweichung zum Ziel, das sich der/ die Lernende gesetzt hat. <br> <br>' +
                '<b>rot</b>: Der/ Die Lernende hat sich unter seinem Ziel eingeschätzt oder sich eine Markierung gesetzt. <br>' +
                '<b>grün</b>: Der/ Die Lernende übertrifft sein Ziel.',
            attachTo: {
                element: '.grid-container',
                on: 'top',
            },
            canClickTarget: false,
            buttons: [
                {
                    action() {
                        return this.back();
                    },
                    classes: 'button is-primary',
                    text: 'Back',
                },
                {
                    action() {
                        return this.next();
                    },
                    classes: 'button is-primary',
                    text: 'Next',
                },
                {
                    action() {
                        cancelTour();
                        return this.cancel();
                    },
                    classes: 'button is-primary',
                    text: 'Tour überspringen',
                },
            ],
            id: 'creating',
        });
        this.tour.addStep({
            title: 'Thema-Details',
            text: 'Wenn du ein einzelnes Thema anklickst, erfährst du Details in Bezug auf dieses Thema.',
            canClickTarget: false,
            buttons: [
                {
                    action() {
                        return this.complete();
                    },
                    classes: 'button is-primary',
                    text: 'Tour-Schritt beenden',
                },
            ],
            id: 'creating',
        });
        this.tour.start();

        async function cancelTour() {
            await userWebservice.changeTour(false);
            await userWebservice.getUserInfo().then((response) => {
                component.$store.commit('account/setCurrentUser', response);
            });
            // Show a snackbar message that tour can be enabled in the settings
            component.$buefy.snackbar.open({
                duration: 7000,
                message: `Du hast die Tour abgebrochen. Sie wird dir nicht mehr angezeigt. Falls du sie wieder aktivieren möchtest, kannst du das in den Einstellungen vornehmen.`,
                type: 'is-primary',
                queue: false,
                position: 'is-top',
            });
        }
    }
}
