

















































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import { moduleWebservice } from '@/webservices/module';
import { QuestionStats } from '@/models/questionstats';
import { UserStats } from '@/models/userstats';
import { EvaluationStats } from '@/models/evaluationstats';
import { Topic } from '@/models/topic';
import Shepherd from 'shepherd.js';
import { User } from '@/models/user';
import { userWebservice } from '@/webservices/user';

@Component({
    name: 'DetailedTopicView',
    components: {},
})
export default class DetailedTopicView extends Vue {
    private loading = {
        homeworks: false,
        usersStats: false,
        questionStats: false,
    };

    @Prop() private eventId: number;
    @Prop() private topicId: number;
    @Prop() private topic: Topic;

    @Getter('account/currentUser') private currentUser: User;

    private questionStats: QuestionStats[] = [];
    private userStats: UserStats[] = [];

    private tour = new Shepherd.Tour({
        useModalOverlay: true,
        defaultStepOptions: {
            classes: 'shadow-md bg-purple-dark',
            scrollTo: true,
        },
        exitOnEsc: true,
    });

    private async mounted() {
        this.loadQuestionStats();
        this.loadUsersStats();
        if (this.currentUser.show_tour) {
            this.shepherdTour(this);
        }
    }

    private async loadQuestionStats() {
        this.loading.questionStats = true;
        this.questionStats = await moduleWebservice.loadQuestionsByTopic(this.eventId, this.topicId);
        this.loading.questionStats = false;
    }

    private async loadUsersStats() {
        this.loading.usersStats = true;
        this.userStats = await moduleWebservice.loadUsersByTopic(this.eventId, this.topicId);
        this.loading.usersStats = false;
    }

    private getFormattedTime(duration: number) {
        return this.formatDuration(duration);
    }

    private pad(n: number) {
        return n.toString().slice(-3).length === 1 ? '0' + n.toString().slice(-3) : n.toString().slice(-3);
    }

    private formatDuration(seconds: number) {
        let minutes: number = Math.floor(seconds / 60);
        seconds = seconds % 60;

        const hours: number = Math.floor(minutes / 60);
        minutes = minutes % 60;

        return this.pad(hours) + 'h:' + this.pad(minutes) + 'm:' + this.pad(seconds) + 's';
    }

    private tooFastAnswered(seconds: number) {
        return seconds != null && seconds < 120;
    }

    private getGradeStudentString(evaluationStats: EvaluationStats[]) {
        const studentGrades = evaluationStats
            .map(function(stats) {
                return stats.firstname + ' ' + stats.lastname + ': ' + stats.grade;
            })
            .join('\n');

        return studentGrades;
    }

    private shepherdTour(component: DetailedTopicView) {
        this.tour.addStep({
            title: 'Register Statistik',
            text: 'In diesem Register sind die Selbsteinschätzungen der Lernenden aufgelistet.',
            buttons: [
                {
                    action() {
                        return this.complete();
                    },
                    classes: 'button is-primary',
                    text: 'OK',
                },
                {
                    action() {
                        cancelTour();
                        return this.cancel();
                    },
                    classes: 'button is-primary',
                    text: 'Tour überspringen',
                },
            ],
            id: 'creating',
        });
        this.tour.start();

        async function cancelTour() {
            await userWebservice.changeTour(false);
            await userWebservice.getUserInfo().then((response) => {
                component.$store.commit('account/setCurrentUser', response);
            });
            // Show a snackbar message that tour can be enabled in the settings
            component.$buefy.snackbar.open({
                duration: 7000,
                message: `Du hast die Tour abgebrochen. Sie wird dir nicht mehr angezeigt. Falls du sie wieder aktivieren möchtest, kannst du das in den Einstellungen vornehmen.`,
                type: 'is-primary',
                queue: false,
                position: 'is-top',
            });
        }
    }
}
