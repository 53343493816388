































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Topic } from '@/models/topic';
import { GridElementPosition } from '@/components/prine/gridtable/GridElementPosition';
import { Getter } from 'vuex-class';
import { Evaluation } from '@/models/evaluation';
import { Question } from '@/models/question';
import Modal, { ModalOptions } from '@/components/prine/modal/Modal.vue';
import { UserExtended } from '@/models/userextended';

@Component({
    name: 'GridElementTopicStatsModal',
    components: {
        Modal,
    },
})
export default class GridElementTopicStatsModal extends Vue {
    // @Prop() private topic: Topic;
    @Prop() private user: UserExtended;
    @Prop() private evaluations: Evaluation[];
    @Prop() private questions: Question[];
    @Prop() private position: GridElementPosition;
    @Prop() private topic: Topic;

    private modalOptions: ModalOptions = {
        width: 600,
        height: null,
        showDelete: false,
        isLoading: false,
        saveButtonText: 'Speichern',
        saveDisabled: false,
    };

    /**
     * Emits the toggle method in the parent
     */
    private toggleModal() {
        this.$emit('toggleStatsModal');
    }

    private questionTitle(questionId: number) {
        const question: Question = this.questions.find((el) => {
            return el.id === questionId;
        })!;
        return question.title;
    }

    private diff(grade: number) {
        return Math.round((grade - this.user.goal) * 10) / 10;
    }
}
