import Vue from 'vue';
import Vuex, { MutationTree, ActionTree } from 'vuex';
import { Subject } from '../../../models/subject';
import { subjectWebservice } from '@/webservices/subject';

Vue.use(Vuex);

interface State {
    subjects: Subject[];
}

const mutations: MutationTree<State> = {
    setSubjects: (currentState, subjects) => (currentState.subjects = subjects),
};

const actions: ActionTree<State, any> = {
    async loadSubjects({ commit }) {
        try {
            const response = await subjectWebservice.getAll();
            commit('setSubjects', response.data);
        } catch (error) {
            console.error(error);
        }
    },
};

const getters = {
    subjects(currentState: State) {
        return currentState.subjects;
    },
};

const state: State = {
    subjects: Array<Subject>(),
};

const module = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};

export default module;
