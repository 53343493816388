





























































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    name: 'DetailedConfirmModal',
    components: {
    },
})

export default class DetailedConfirmModal extends Vue {

    @Prop() private affectedData: Map<string, number>;
    @Prop() private name: string[];
    @Prop() private localizationKey: string;

    private isLoading: boolean = false;

    private confirmText: string = '';

    public confirm() {
        this.isLoading = true;
        this.$emit('toggleConfirmModal', true);
    }

    public toggleModal() {
         this.$emit('toggleConfirmModal', false);
    }

    get isConfirmed() {
        return this.confirmText.toLowerCase() === 'remove' ||  this.confirmText.toLowerCase() === 'löschen';
    }
}
