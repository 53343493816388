
export class HtmlHelper {

    public setFocusById(elId: string) {
        setTimeout(() => {
            const el: HTMLElement | null = document.getElementById(elId);
            if (el !== null) {
                el.focus();
            }
        }, 20);
    }

}
