





















import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import { ViewType } from '@/components/prine/enums/ViewType';
import { moduleWebservice } from '@/webservices/module';
import { Module } from '@/models/module';

import ModuleTab from '@/components/module/manager/tabs/ModuleTab.vue';
import ActivityFeedModuleChanges from './ActivityFeedModuleChanges.vue';

import SharedModuleList from './SharedModuleList.vue';
import SharedModuleGrid from './SharedModuleGrid.vue';
import EmptyListMsg from '@/components/prine/EmptyListMsg.vue';

@Component({
    name: 'SharedTab',
    components: {
        ModuleTab,
        ActivityFeedModuleChanges,
        SharedModuleList,
        SharedModuleGrid,
        EmptyListMsg,
    },
})
export default class SharedTab extends Vue {
    private ViewType = ViewType;

    @Getter('module/sharedModules')
    private sharedModules: Module[];
}
