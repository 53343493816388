








































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import { evaluationWebservice } from '@/webservices/evaluation';
import { Evaluation } from '@/models/evaluation';
import moment from 'moment';
import { Event } from '@/models/event';
import { Question } from '@/models/question';
import FeedContainer from '@/components/prine/feed/FeedContainer.vue';

@Component({
    name: 'ActivityFeedStudent',
    components: {
        FeedContainer,
    },
})
export default class ActivityFeedStudent extends Vue {
    private evaluations: Evaluation[] = [];
    private isLoading: boolean = false;
    private loadNewestEvaluationsInterval: NodeJS.Timeout;
    private skip: number = 0;
    private take: number = 8;

    private async mounted() {
        this.loadNewestEvaluations();

        this.loadNewestEvaluationsInterval = setInterval(async () => {
            this.loadNewestEvaluations();
        }, 60 * 1000); // Every minute
    }

    private destroyed() {
        clearInterval(this.loadNewestEvaluationsInterval);
    }

    private async loadMoreFeed() {
        this.take += this.take;
        this.loadNewestEvaluations();
    }

    private async loadNewestEvaluations() {
        this.isLoading = true;
        this.evaluations = await evaluationWebservice.getNewestEvaluations(0, this.take);
        this.evaluations = this.filterEvaluations(this.evaluations);
        this.isLoading = false;
    }

    private filterEvaluations(evaluations: Evaluation[]) {
        // Filter out all evaluations where the question has been deleted
        return this.evaluations.filter((evaluation) => {
            return evaluation.question !== null;
        });
    }

    private getEventTypeString(event: Event) {
        if (event.event_type_id === 1) {
            return 'Hausaufgabe: ' + event.topic.title;
        } else if (event.event_type_id === 2) {
            return 'Prüfung: ' + event.topic.title;
        } else if (event.event_type_id === 3) {
            if (event.modules !== null && event.modules.length > 0) {
                return 'Modul: ' + event.modules[0].name;
            }
        }
    }

    private getPosition(evaluation: Evaluation) {
        if (evaluation.event.event_type_id === 3) {
            if (
                evaluation.question &&
                evaluation.question.topics !== null &&
                evaluation.question.topics.length > 0 &&
                evaluation.question.topics[0].block !== null
            ) {
                return evaluation.question.topics[0].block.order + '.' + evaluation.question.topics[0].order;
            }
        }
    }

    private toggleActivityFeed() {
        this.$emit('toggleActivityFeed');
    }
}
