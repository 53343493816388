import axios from 'axios';
import { Group } from '../models/group';
import { User } from '../models/user';

class GroupWebservice {
    public getAll() {
        return axios.get('/groups');
    }

    public store(name: string) {
        return axios.post('/groups/store', {
            name,
        });
    }

    public update(group: Group) {
        return axios.post('/groups/update', {
            name: group.name,
            id: group.id,
        });
    }

    // public archive(group: Group) {
    //     return axios.post('/groups/archive', {
    //         id: group.id,
    //     });
    // }

    public groupUsers(group: Group) {
        return axios.get<User[]>('/groups/users', {
            params: {
                id: group.id,
            },
        });
    }

    public toggleUserActive(user: User, group: Group) {
        // FIXME: Check if the caller is doing the then
        return axios.post('/groups/' + group.id + '/users/' + user.id + '/toggleActive', {});
    }
}

export const groupWebservice = new GroupWebservice();
