


















































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Topic } from '@/models/topic';
import { GridElementPosition } from '@/components/prine/gridtable/GridElementPosition';
import { Block } from '@/models/block';
import { Module } from '@/models/module';
import { Getter } from 'vuex-class';
import { EvaluationsPerUser } from '@/models/evaluationperuser';
import { User } from '@/models/user';
import GridElementTopicColoredSlice from './GridElementTopicColoredSlice.vue';

@Component({
    name: 'GridElementTopic',
    components: {
        GridElementTopicColoredSlice,
    },
})
export default class GridElementTopic extends Vue {
    @Prop() private topic: Topic;
    @Prop() private position: GridElementPosition;
    @Prop() private block: Block;
    @Prop() private users: User[];

    private currentlyHovered: boolean = false;
    private isModalActive: boolean = false;

    @Getter('module/selectedModule') private module: Module;

    private get getTitle(): string {
        if (this.topic.title.length > 40) {
            return this.topic.title.substring(0, 40) + '..';
        } else {
            return this.topic.title;
        }
    }

    private get isExistingTopic(): boolean {
        return this.topic.hasOwnProperty('title');
    }

    private get isExistingBlock(): boolean {
        return this.block.hasOwnProperty('name');
    }

    private get progressPerTopic() {
        if (this.topic.stats) {
            return Math.round(this.topic.stats.progress * 100) / 100 + '%';
        } else {
            return null;
        }
    }

    private get avgPerTopic() {
        if (this.topic.stats) {
            return 'Ø ' + Math.round(this.topic.stats.avg * 100) / 100;
        } else {
            return null;
        }
    }

    private get preparedEvaluations() {
        let preparedEvaluations = Array<EvaluationsPerUser>();

        if (this.topic && this.topic.hasOwnProperty('id') && this.topic.evaluations_per_user && this.users) {
            // Iterate over all users in the group, check if there are evaluations per user
            this.users.forEach((user) => {
                const foundEvaluationsPerUser = this.topic.evaluations_per_user[user.id];

                if (foundEvaluationsPerUser) {
                    // Calculate average
                    const average =
                        foundEvaluationsPerUser.evaluations
                            .map((evaluation) => {
                                return evaluation.grade;
                            })
                            .reduce((gradeA, gradeB) => {
                                return gradeA + gradeB;
                            }) / foundEvaluationsPerUser.evaluations.length;

                    // Compare it with the goal
                    const diff = average - foundEvaluationsPerUser.goal;

                    foundEvaluationsPerUser.avg = average;
                    foundEvaluationsPerUser.diff = diff;

                    preparedEvaluations.push(foundEvaluationsPerUser);
                } else {
                    preparedEvaluations.push({
                        user_id: user.id,
                        name: user.firstname + ' ' + user.lastname,
                        goal: 0,
                        evaluations: [],
                        avg: 0,
                        diff: 10,
                    });
                }
            });

            // Sort the array by the diff
            preparedEvaluations = preparedEvaluations.sort((a, b) => {
                return a.diff > b.diff ? 1 : -1;
            });
        }

        return preparedEvaluations;
    }
}
