import Vue from 'vue';
import Vuex, { MutationTree, ActionTree } from 'vuex';
import { User } from '@/models/user';
import axios from 'axios';
import Cookies from 'js-cookie';

Vue.use(Vuex);

interface State {
    current_user: User;
    loggedIn: boolean;
    haveAccess: boolean;
}

const mutations: MutationTree<State> = {
    setCurrentUser: (currentState, current_user) => (currentState.current_user = current_user),

    setApiToken: (currentState, api_token) => {
        // Update the local storage by hand
        // localStorage.removeItem('api_token');
        // localStorage.setItem('api_token', api_token);
        axios.defaults.headers.common.Authorization = 'Bearer '.concat(api_token);
        currentState.loggedIn = true;

        // Set the cookie
        Cookies.set('api_token', api_token, {
            domain: process.env.VUE_APP_COOKIE_DOMAIN,
        });
    },

    setHaveAccess: (currentState, access) => {
        currentState.haveAccess = access;
    },

    logout: (currentState) => {
        Cookies.remove('api_token', {
            domain: process.env.VUE_APP_COOKIE_DOMAIN,
        });
        // localStorage.clear();
        currentState.loggedIn = false;
        (currentState.current_user = {
            id: 0,
            firstname: '',
            lastname: '',
            email: '',
            new_email: '',
            email_confirmation: '',
            password: '',
            password_confirmation: '',
            new_password: '',
            email_confirmed: false,
            activated: false,
            role_id: 0,
            goal: 0,
            show_tour: false,
        }),
            console.log('Reset the account currentUser and the loggedIn status');
    },
};

const actions: ActionTree<State, any> = {};

const getters = {
    currentUser(currentState: State) {
        return currentState.current_user;
    },

    loggedIn(currentState: State): boolean {
        return currentState.loggedIn;
    },

    haveAccess(currentState: State): boolean {
        return currentState.haveAccess;
    },

    isEmailConfirmed(currentState: State): boolean {
        return currentState.current_user.email_confirmed;
    },

    isActivated(currentState: State): boolean {
        return currentState.current_user.activated;
    },
};

const state: State = {
    loggedIn: false,
    haveAccess: false,
    current_user: {
        id: 0,
        firstname: '',
        lastname: '',
        email: '',
        new_email: '',
        email_confirmation: '',
        password: '',
        password_confirmation: '',
        new_password: '',
        email_confirmed: false,
        activated: false,
        role_id: 0,
        goal: 0,
        show_tour: false,
    },
};

const module = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};

export default module;
