



















































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { User } from '@/models/user';
import { Getter } from 'vuex-class';
import AppSwitcher from './AppSwitcher.vue';
import AccountDropdown from './AccountDropdown.vue';

@Component({
    name: 'Navbar',
    components: {
        AppSwitcher,
        AccountDropdown,
    },
})
export default class Navbar extends Vue {
    protected isActive: boolean = false;

    @Getter('account/loggedIn') private loggedIn: boolean;
    @Getter('account/haveAccess') private haveAccess: boolean;
    @Getter('account/currentUser') private currentUser: User;

    private toggleNav() {
        this.isActive = !this.isActive;
    }

    private hideMenu(element: string) {
        setTimeout(() => {
            this.isActive = false;
        }, 100);
    }

    private toHome() {
        if (this.loggedIn && this.haveAccess) {
            this.$router.push('/');
        } else {
            this.$router.push({
                name: 'Login',
            });
        }
    }
}
