

































































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';

import { moduleWebservice } from '@/webservices/module';
import { moduleMetadataWebservice } from '@/webservices/moduleMetadata';

import { Module } from '@/models/module';
import Modal, { ModalOptions } from '@/components/prine/modal/Modal.vue';

import { PiquestSubject } from '@/models/piquestSubject';
import { SchoolLevel } from '@/models/schoollevel';
import { Schooltype } from '@/models/schooltype';
import { Language } from '@/models/language';
import { Country } from '@/models/country';
import { ModulePrice } from '@/models/modulePrice';
import { Tag } from '@/models/tag';

import { ModuleMetaData } from '@/models/moduleMetaData';
import * as R from 'ramda';

@Component({
    name: 'EditPublishMetadataModal',
    components: {
        Modal,
    },
})
export default class EditPublishMetadataModal extends Vue {
    private moduleData: Module = {} as Module;
    private disabled: boolean = false;
    private loaded: boolean = false;
    private errorMsg: string = '';
    private isLoadingModuleData: boolean = true;

    private selectedTags: Tag[] = [];

    @Getter('moduleMetadata/piquestSubjects') private piquestSubjects: PiquestSubject[];
    @Getter('moduleMetadata/schoollevels') private schoollevels: SchoolLevel[];
    @Getter('moduleMetadata/schooltypes') private schooltypes: Schooltype[];
    @Getter('moduleMetadata/languages') private languages: Language[];
    @Getter('moduleMetadata/countries') private countries: Country[];
    @Getter('moduleMetadata/modulePrices') private modulePrices: ModulePrice[];
    @Getter('moduleMetadata/tags') private tags: Tag[];

    @Action('subject/loadSubjects') private loadSubjects: any;

    private modalOptions: ModalOptions = {
        width: null,
        height: null,
        showDelete: false,
        isLoading: false,
        saveButtonText: 'Aktualisieren',
        saveDisabled: false,
    };

    @Prop() private module: Module;

    private async mounted() {
        this.moduleData = JSON.parse(JSON.stringify(this.module));
        await this.loadMetadata(this.module);
        this.loaded = true;
    }

    /**
     * Load the metadata by a given id
     */
    private async loadMetadata(module: Module) {
        if (module.module_meta_data_id) {
            this.moduleData.metaData = await moduleMetadataWebservice.getMetadataById(module.module_meta_data_id);
            this.selectedTags = this.moduleData.metaData.tags;
            this.isLoadingModuleData = false;
        }
    }

    /**
     * Emits the toggle method in the parent
     */
    private toggleModal() {
        this.$emit('toggleEditPublishModal');
    }

    /**
     * Store or update the block
     */
    private async update() {
        if (!this.validate()) {
            return;
        }

        this.modalOptions.isLoading = true;

        // Set the selected tags manually
        // There was a bug setting it directly in the mutliselect, probably because it could be null
        if (this.moduleData.metaData) {
            this.moduleData.metaData.tags = this.selectedTags;
            await moduleMetadataWebservice.updateMetadata(this.moduleData.metaData);
            this.showSuccessNotification();
        }

        this.toggleModal();
        this.modalOptions.isLoading = false;
    }

    /**
     * Show a snackbar notification banner
     */
    private showSuccessNotification() {
        this.$buefy.snackbar.open({
            duration: 5000,
            message: 'Die Modul-Metadaten wurden erfolgreich aktualisiert',
            type: 'is-success',
            queue: true,
            position: 'is-top',
        });
    }

    private validate() {
        if (this.moduleData.metaData && R.isEmpty(this.moduleData.metaData.description)) {
            this.errorMsg = 'description';
            return false;
        }

        this.errorMsg = '';
        return true;
    }

    private customMultiselectLabel({ title }: { title: string }) {
        return `${title}`;
    }
}
