import axios from 'axios';

class GeneralFeedbackWebservice {
    public send(type: string, content: string) {
        return axios.post('/general-feedbacks/send', {
            type,
            content,
        });
    }
}

export const generalFeedbackWebservice = new GeneralFeedbackWebservice();
