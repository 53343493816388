
















import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import ModuleProgressStudentGridTable from './ModuleProgressStudentGridTable.vue';
import { Topic } from '@/models/topic';
import { UserExtended } from '@/models/userextended';

@Component({
    name: 'ModuleProgressStudentBox',
    components: {
        ModuleProgressStudentGridTable,
    },
})
export default class ModuleProgressStudentBox extends Vue {
    @Prop() private user: UserExtended;
    @Prop() private topic: Topic;
    @Prop() private index: number;

    private showDetailStats() {
        console.log('Show detail stats');
    }
}
