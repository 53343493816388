





























import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import moment from 'moment';
import FeedContainer from '@/components/prine/feed/FeedContainer.vue';
import { synchronizationWebservice } from '@/webservices/synchronization';
import { SharedFeedEntry } from '@/models/sharedFeedEntry';

@Component({
    name: 'ActivityFeedModuleChanges',
    components: {
        FeedContainer,
    },
})
export default class ActivityFeedModuleChanges extends Vue {
    private loadNewestChangesInterval: NodeJS.Timeout;
    private isLoading: boolean = false;
    private take: number = 8;
    private changes: SharedFeedEntry[] = [];

    private async mounted() {
        this.loadNewestChanges();

        this.loadNewestChangesInterval = setInterval(async () => {
            this.loadNewestChanges();
        }, 60 * 1000); // Every minute
    }

    private destroyed() {
        clearInterval(this.loadNewestChangesInterval);
    }

    private async loadMoreFeed() {
        this.take += this.take;
        this.loadNewestChanges();
    }

    private async loadNewestChanges() {
        this.isLoading = true;
        this.changes = await synchronizationWebservice.getChanges(this.take);
        this.isLoading = false;
    }

    private getStatusIcon(statusName: string) {
        if (statusName === 'new') {
            return 'fa-plus';
        } else if (statusName === 'updated') {
            return 'fa-edit';
        } else if (statusName === 'deleted') {
            return 'fa-trash';
        }
    }

    private getTitle(sharedFeedEntry: SharedFeedEntry) {
        const changeMap = new Map();
        changeMap.set('updated', 'geändert');
        changeMap.set('new', 'erstellt');
        changeMap.set('deleted', 'gelöscht');

        const subjectMap = new Map();
        subjectMap.set('module', 'das Modul');
        subjectMap.set('block', 'der Block');
        subjectMap.set('topic', 'das Topic');
        subjectMap.set('question', 'die Frage');

        const subject = subjectMap.get(sharedFeedEntry.type);
        const change = changeMap.get(sharedFeedEntry.feed_status_name);

        return subject + '"' + sharedFeedEntry.name + '"' + change;
    }
}
