






























import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import { Solution } from '@/models/solution';
import { Question } from '@/models/question';

@Component({
    name: 'TopicLinkBlock',
})
export default class TopicLinkBlock extends Vue {
    @Prop() private question: Question;

    private webLinkQuestion(question: Question) {
        // console.log(question);
        let link = '';
        if (question.is_local_link) {
            link = process.env.VUE_APP_BACKEND_BASE_URL + question.link;
        } else {
            link = this.addhttp(question.link);
        }
        return link;
    }

    private isURL(str: string) {
        const pattern = new RegExp(
            '(https?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
                '(\\#[-a-z\\d_]*)?$',
            'i'
        ); // fragment locator
        return !!pattern.test(str);
    }

    private addhttp(link: string) {
        if (this.isURL(link)) {
            if (!/^(f|ht)tps?:\/\//i.test(link)) {
                link = 'http://' + link;
            }
        }
        return link;
    }
}
