













import { Component, Prop, Vue } from 'vue-property-decorator';
import BackToTop from '@/components/prine/BackToTop.vue';

@Component({
    name: 'BackToTopContainer',
    components: {
        'back-to-top': BackToTop,
    },
})
export default class BackToTopContainer extends Vue {}
