import { render, staticRenderFns } from "./ModuleProgressStats.vue?vue&type=template&id=22651274&scoped=true&"
import script from "./ModuleProgressStats.vue?vue&type=script&lang=ts&"
export * from "./ModuleProgressStats.vue?vue&type=script&lang=ts&"
import style0 from "./ModuleProgressStats.vue?vue&type=style&index=0&id=22651274&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22651274",
  null
  
)

export default component.exports