



































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import moment from 'moment';
import { Module } from '@/models/module';
import { Event } from '@/models/event';
import { moduleWebservice } from '@/webservices/module';
import { eventWebservice } from '@/webservices/event';

import DetailedConfirmModal from '@/components/prine/DetailedConfirmModal.vue';

import ModuleProgressStatsSimple from '@/components/module/stats/module-progress-simple/ModuleProgressStatsSimple.vue';

@Component({
    name: 'ActiveModuleCard',
    components: {
        DetailedConfirmModal,
        ModuleProgressStatsSimple,
    },
})
export default class ActiveModuleCard extends Vue {
    @Prop() private module: Module;

    private affectedData: Map<string, number> = new Map();
    private isConfirmModalActive: boolean = false;
    private isModuleCoverUploadModalActive: boolean = false;

    private isModalActive: boolean = false;

    private event: Event;
    private isLoading: boolean = true;

    @Action('module/fetchAllModules')
    private fetchAllModules: any;

    // Mounted is called initially
    private async mounted() {
        this.affectedData.set(this.$tc('keywords.block', 2).toString(), this.module.blocks_count);

        // Load the event if its an active event
        const event = await eventWebservice.getEventById(this.module.event_id);
        this.event = event;
        this.isLoading = false;
    }

    private async toggleConfirmModal(answer: boolean) {
        if (answer === true) {
            await moduleWebservice.remove(this.module);
            this.isConfirmModalActive = !this.isConfirmModalActive;
            this.fetchAllModules();
        } else {
            this.isConfirmModalActive = !this.isConfirmModalActive;
        }
    }

    private toggleFinished() {
        moduleWebservice.toggleFinished(this.module).then((res) => {
            this.fetchAllModules();
        });
    }

    private get cardStyle() {
        return this.module.status.name === 'finished' ? 'finished-card' : '';
    }
}
