
























import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';

@Component({
    name: 'FeedContainer',
})
export default class FeedContainer extends Vue {
    @Prop() private data: any[];
    @Prop() private title: string;
    @Prop() private isLoading: boolean;

    private isFullPage: boolean = false;

    private reload() {
        this.$emit('reload');
    }
}
