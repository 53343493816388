import Vue from 'vue';
import Vuex from 'vuex';

// Import different modules
import account from './modules/account/store';
import group from './modules/group/store';
import subject from './modules/subject/store';
import homework from './modules/homework/store';
import exam from './modules/exam/store';
import setting from './modules/setting/store';
import module from './modules/module/store';
import moduleMetadata from './modules/moduleMetadata/store';

import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);

export default new Vuex.Store({
    plugins: [createPersistedState()],

    modules: {
        // All modules
        account,
        group,
        subject,
        homework,
        exam,
        setting,
        module,
        moduleMetadata,
    },
});
