
























import { Component, Prop, Vue } from 'vue-property-decorator';
import { Topic } from '@/models/topic';
import { GridElementPosition } from '@/components/prine/gridtable/GridElementPosition';
import { Getter } from 'vuex-class';
import { User } from '@/models/user';
import * as R from 'ramda';
import { Evaluation } from '@/models/evaluation';
import { Module } from '@/models/module';
import { Block } from '@/models/block';
import TopicViewModal from './TopicViewModal.vue';

@Component({
    name: 'GridElementTopic',
    components: {
        TopicViewModal,
    },
})
export default class GridElementTopic extends Vue {
    @Prop() private topic: Topic;
    @Prop() private block: Block;
    @Prop() private module: Module;
    @Prop() private position: GridElementPosition;
    @Prop() private goal: number;

    @Getter('account/currentUser') private currentUser: User;

    private isTopicViewModalActive: boolean = false;

    private get getYellowGrayColoring(): string {
        if (this.position.row <= 3) {
            // Green
            // return 'rgba(107,167,112, ' + (position.row * (1 / 3) + ');');

            // Yellow
            return 'background-color: rgba(253,191,94, ' + (this.position.row * (1 / 3) + ');');
        } else {
            // Red
            // return 'rgba(191,77,40, ' + ((position.row - 3) * (1 / this.module.rows)) + ');';

            // Blue
            return 'background-color: rgba(25,54,81, ' + (this.position.row - 3) * (1 / this.module.rows) + ')';
        }
    }

    private get isExistingTopic(): boolean {
        return this.topic.hasOwnProperty('title');
    }

    private toggleTopicViewModal() {
        this.isTopicViewModalActive = !this.isTopicViewModalActive;
    }
}
