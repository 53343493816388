import axios from 'axios';
import HttpException from './HttpException';
import { FeedbackStudent } from '@/models/feedbackStudent';

class FeedbackWebservice {
    public async loadNewestStudentFeedbacks(take: number = 8) {
        try {
            const response = await axios.get<FeedbackStudent[]>('/feedbacks/newest', {
                params: {
                    take,
                },
            });
            return response.data;
        } catch (error) {
            throw new HttpException(error.response.status, 'There was an error fetching the module by an id');
        }
    }

    public async toggleSolved(feedback_id: number) {
        // Call webservice as post and use await
        try {
            const response = await axios.post('/feedbacks/toggleSolved', {
                student_feedback_question_id: feedback_id,
            });

            return response.data;
        } catch (error) {
            throw new HttpException(error.response.status, 'There was an error toggling the solved flag');
        }
    }
}

export const feedbackWebservice = new FeedbackWebservice();
