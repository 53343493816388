






















































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Topic } from '@/models/topic';
import { GridElementPosition } from '@/components/prine/gridtable/GridElementPosition';
import CreateTopicModal from './CreateTopicModal.vue';
import { Block } from '@/models/block';
import GridElementBlock from '@/components/module/elements/block/GridElementBlock.vue';
import { Module } from '@/models/module';
import { Getter } from 'vuex-class';
import { Drag, Drop } from 'vue-drag-drop';
import { Position } from 'vue-router/types/router';
import { topicWebservice } from '@/webservices/topic';
import { ModuleStatusValues } from '@/models/moduleStatus';

@Component({
    components: {
        CreateTopicModal,
        Drag,
        Drop,
    },
})
export default class GridElementTopic extends Vue {
    public moduleStatus = ModuleStatusValues;

    @Prop() private topic: Topic;
    @Prop() private position: GridElementPosition;
    @Prop() private block: Block;

    @Getter('module/selectedModule') private module: Module;

    private isModalActive: boolean = false;
    private over: boolean = false;

    private get isExistingTopic(): boolean {
        return this.topic.hasOwnProperty('title');
    }

    private get isExistingBlock(): boolean {
        return this.block.hasOwnProperty('name');
    }

    private getColor(position: GridElementPosition): string {
        if (position.row <= 3) {
            // Green
            // return 'rgba(107,167,112, ' + (position.row * (1 / 3) + ');');

            // Yellow
            return 'rgba(253,191,94, ' + (position.row * (1 / 3) + ');');
        } else {
            // Red
            // return 'rgba(191,77,40, ' + ((position.row - 3) * (1 / this.module.rows)) + ');';

            // Blue
            return 'rgba(25,54,81, ' + (position.row - 3) * (1 / this.module.rows) + ')';
        }
    }

    private toggleCreateTopicModal() {
        this.isModalActive = !this.isModalActive;
    }

    private async handleDrop(receiverTopic: any) {
        await topicWebservice.swap(receiverTopic.topic.id, this.block.id!, this.position.row);
        this.$emit('reloadModule');
    }
}
