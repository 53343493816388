

















































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';

import GridTable from '@/components/prine/gridtable/GridTable.vue';
import GridElementTopic from '@/components/module/configurator/elements/topic/GridElementTopic.vue';
import GridElementBlock from '@/components/module/configurator/elements/block/GridElementBlock.vue';
import ModuleConfiguratorMeta from '@/components/module/configurator/ModuleConfiguratorMeta.vue';
import { moduleWebservice } from '@/webservices/module';
import ModuleCoverUploadModal from '@/components/module/configurator/ModuleCoverUploadModal.vue';
import DetailedConfirmModal from '@/components/prine/DetailedConfirmModal.vue';
import { blockWebservice } from '@/webservices/block';
import { topicWebservice } from '@/webservices/topic';

import { Topic } from '@/models/topic';
import { Block } from '@/models/block';
import { Module } from '@/models/module';
import { User } from '@/models/user';
import { Question } from '@/models/question';

import * as R from 'ramda';

@Component({
    components: {
        GridTable,
        GridElementTopic,
        GridElementBlock,
        ModuleConfiguratorMeta,
        ModuleCoverUploadModal,
        'detailed-remove-column-confirm-modal': DetailedConfirmModal,
        'detailed-remove-row-confirm-modal': DetailedConfirmModal,
    },
})
export default class ModuleConfigurator extends Vue {
    @Getter('account/currentUser') private currentUser: User;
    @Getter('module/selectedModule') private module: Module;
    @Action('module/reloadSelectedModule') private reloadSelectedModule: any;

    private loaded: boolean = false;
    private editMode: boolean = false;
    private readOnly: boolean = false;

    private affectedData: Map<string, number> = new Map();
    private detailedConfirmModalOptions = {
        name: '',
        localizationKey: '',
    };

    private isModuleCoverUploadModalActive: boolean = false;
    private isColumnConfirmModalActive: boolean = false;
    private isRowConfirmModalActive: boolean = false;

    private data = {
        header: new Array<Block>(),
        body: new Array<Topic>(),
    };

    @Watch('module')
    private onModuleValueChange() {
        this.data = this.initializeModule();
    }

    private mounted() {
        this.loadModule();
        // this.affectedData.set(this.$tc('keywords.block', 2).toString(), this.module.blocks_count);
    }

    private loadModule() {
        // Call load module if there is a module_id in the url
        if (this.$route.query.hasOwnProperty('module_id')) {
            console.log('Has module id in url: ' + this.$route.query.module_id);
            this.loadModuleById(Number(this.$route.query.module_id));
        } else {
            // There could be already a module from earlier set. We need to overwrite this
            this.$store.commit('module/resetSelectedModule');
            this.data = this.initializeModule();
            this.loaded = true;
        }
    }

    /**
     * Load the module by a given id
     */
    private async loadModuleById(id: number) {
        const loadedModule = await moduleWebservice.getModuleById(id);

        this.$store.commit('module/setSelectedModule', loadedModule);

        this.data = this.initializeModule();
        this.loaded = true;
    }

    /**
     * Add an additional row to the GridTable
     */
    private addRow() {
        this.module.rows++;

        // Store the updated rows in the webservice and reload the data
        moduleWebservice
            .update(this.module)
            .then((response) => {
                this.reloadSelectedModule();
                this.showNotification('Eine neue Zeile wurde hinzugefügt.');
            })
            .catch((error) => {
                console.error(error);
            });
    }

    /**
     * Add an additional column to the GridTable
     */
    private addColumn() {
        this.module.columns++;

        // Store the updated columns in the webservice and reload the data
        moduleWebservice
            .update(this.module)
            .then((response) => {
                this.reloadSelectedModule();
                this.showNotification('Eine neue Spalte wurde hinzugefügt.');
            })
            .catch((error) => {
                console.error(error);
            });
    }

    private getLastColumnBlock() {
        const lastColumn: number = this.module.columns;
        const lastBlock: Block | undefined = this.module.blocks.find((block) => {
            return block.order === lastColumn;
        });
        return lastBlock;
    }

    private removeColumn() {
        // check if the last block[column] in the module is the one that is being removed
        if (!R.isNil(this.getLastColumnBlock())) {
            const numberOfTopics: number = this.module.blocks[this.module.blocks.length - 1].topics.length;

            // If there is a block but the number of topics is 0 we can still delete the whole column
            if (numberOfTopics === 0) {
                // Manually delete the last block
                this.deleteLastColumnBlock();
            } else {
                this.affectedData.set(this.$tc('keywords.topic', numberOfTopics).toString(), numberOfTopics);
                this.detailedConfirmModalOptions.name = this.module.columns.toString();
                this.detailedConfirmModalOptions.localizationKey = 'moduleColumn';

                this.toggleRemoveColumnModal();
            }
        } else {
            this.module.columns--;
            this.updateModule();
        }
    }

    private toggleRemoveColumnModal(answer: boolean = false) {
        this.isColumnConfirmModalActive = !this.isColumnConfirmModalActive;
        if (answer === true) {
            this.deleteLastColumnBlock();
        }
    }

    private deleteLastColumnBlock() {
        const block: Block = this.getLastColumnBlock()!;
        blockWebservice.remove(block).then((response) => {
            this.module.columns--;
            this.updateModule();
        });
    }

    private countTopicsInRow(row: number) {
        const lastRow: number = this.module.rows;
        let count: number = 0;
        this.module.blocks.forEach((block) => {
            block.topics.forEach((topic) => {
                if (topic.order === lastRow) {
                    count++;
                }
            });
        });
        return count;
    }

    private removeRow() {
        // check if the last block[column] in the module is the one that is being removed
        if (this.countTopicsInRow(this.module.rows) > 0) {
            const numberOfTopics: number = this.countTopicsInRow(this.module.rows);
            this.affectedData.set(this.$tc('keywords.topic', numberOfTopics).toString(), numberOfTopics);
            this.detailedConfirmModalOptions.name = this.module.rows.toString();
            this.detailedConfirmModalOptions.localizationKey = 'moduleRow';

            this.toggleRemoveRowModal();
        } else {
            this.module.rows--;
            this.updateModule();
        }
    }

    private toggleRemoveRowModal(answer: boolean = false) {
        this.isRowConfirmModalActive = !this.isRowConfirmModalActive;
        if (answer === true) {
            this.deleteLastRowTopics();
        }
    }

    private deleteLastRowTopics() {
        const lastRow: number = this.module.rows;

        this.module.blocks.forEach((block) => {
            block.topics.forEach((topic) => {
                if (topic.order === lastRow) {
                    topicWebservice.remove(topic);
                }
            });
        });
        this.module.rows--;
        this.updateModule();
    }

    private updateModule() {
        // Store the updated columns in the webservice and reload the data
        moduleWebservice
            .update(this.module)
            .then((response) => {
                this.reloadSelectedModule();
                this.showNotification('Die letzte Spalte/Zeile wurde entfernt.');
            })
            .catch((error) => {
                console.error(error);
            });
    }

    private imageStored() {
        this.toggleModuleCoverUploadModal();
        this.reloadSelectedModule();
    }

    private initializeModule() {
        const blocks = new Array<Block>();
        let allTopics = new Array<Topic>();

        // CREATE HEADER data
        // Create all blocks
        for (let columnNumber = 1; columnNumber <= this.module.columns; columnNumber++) {
            let block: Block | undefined = this.module.blocks.find((el) => {
                return el.order === columnNumber;
            });

            // Create the block if it wasn't found
            if (block === undefined) {
                block = {} as Block;
                block.id = null;
                block.order = columnNumber;
            }

            // Set reference to topics as well and push it to the array
            blocks.push(block);
        }

        const topics = new Array<Topic>();

        // CREATE BODY data
        // Iterate over all rows and columns and fetch or create the topic
        for (let rowNumber = 1; rowNumber <= this.module.rows; rowNumber++) {
            // Create the topics and connect them to the blocks
            for (let columnNumber = 1; columnNumber <= this.module.columns; columnNumber++) {
                const block: Block | undefined = this.module.blocks.find((el) => {
                    return el.order === columnNumber;
                });

                let topic: Topic | undefined;

                // Fetch the topic in the given block if there is an element at that order (position)
                if (block !== undefined && block.hasOwnProperty('topics') && block.topics.length > 0) {
                    topic = block.topics.find((el) => {
                        return el.order === rowNumber;
                    });
                }

                // Create the topic if it wasn't found
                if (topic === undefined) {
                    topic = {} as Topic;
                    topic.id = null;
                    topic.questions = Array<Question>();
                    topic.order = rowNumber;
                }

                topics.push(topic);
            }
        }

        // Store all topics together in this array
        allTopics = allTopics.concat(topics);

        // Prepare the data for the grid-table
        const data = {
            header: blocks,
            body: allTopics,
        };

        return data;
    }

    private toggleEditModus() {
        this.editMode = !this.editMode;
    }

    /**
     * Show a snackbar notification banner
     */
    private showNotification(additionalText: string = '') {
        this.$buefy.snackbar.open({
            duration: 3000,
            message: `Die Änderungen am Modul wurden korrekt gespeichert. ${additionalText}`,
            type: 'is-primary',
            queue: false,
            position: 'is-top',
        });
    }

    get getRemoveColumnButtonCssPosition() {
        let style = 'bottom: 0px;';
        style += ' right: calc(100% - 120px);';
        return style;
    }

    get getRemoveRowButtonCssPosition() {
        let style = 'bottom: 70px;';
        style += 'left: 0px;';
        return style;
    }

    get currentColumnsTable() {
        return this.editMode ? ' is-11' : 'is-12';
    }

    get moduleExists() {
        return this.module.id !== null;
    }

    get hasCoverImage() {
        return !R.isNil(this.module.cover_path) && !R.isEmpty(this.module.cover_path);
    }

    private toggleModuleCoverUploadModal() {
        this.isModuleCoverUploadModalActive = !this.isModuleCoverUploadModalActive;
    }
}
