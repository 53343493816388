
















import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';

import Modal, { ModalOptions } from '@/components/prine/modal/Modal.vue';

import * as R from 'ramda';
import { Topic } from '@/models/topic';
import { Solution } from '@/models/solution';
import { Question } from '@/models/question';
import TopicLinkBlock from './TopicLinkBlock.vue';
import TopicSolutionBlock from './TopicSolutionBlock.vue';
import TopicViewContent from './TopicViewContent.vue';

@Component({
    name: 'TopicViewModal',
    components: {
        Modal,
        TopicLinkBlock,
        TopicSolutionBlock,
        TopicViewContent,
    },
})
export default class TopicViewModal extends Vue {
    @Prop() private topic: Topic;

    private modalOptions: ModalOptions = {
        width: 1000,
        height: null,
        showDelete: false,
        isLoading: false,
        saveButtonText: 'Okay',
        saveDisabled: false,
    };

    /**
     * Emits the toggle method in the parent
     */
    private toggleModal() {
        this.$emit('toggleTopicViewModal');
    }

    private giveFeedback() {
        console.log('Not yet implemented');
    }
}
