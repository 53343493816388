






















import { Component, Prop, Vue } from 'vue-property-decorator';
import router from 'vue-router';
import { Getter } from 'vuex-class';
import { User } from './models/user';
import { userWebservice } from '@/webservices/user';
import GeneralFeedbackButton from '@/components/prine/GeneralFeedbackButton.vue';
import OfflineChecker from '@/components/prine/offline/OfflineChecker.vue';
import Navbar from '@/components/layouts/Navbar.vue';
import Footer from '@/components/layouts/Footer.vue';
import BackToTopContainer from '@/components/layouts/BackToTopContainer.vue';

@Component({
    name: 'App',
    components: {
        GeneralFeedbackButton,
        OfflineChecker,
        'page-footer': Footer,
        'page-navbar': Navbar,
        BackToTopContainer,
    },
})
export default class App extends Vue {
    protected isActive: string = '';
    protected $version: string = '';

    @Getter('account/loggedIn') private loggedIn: boolean;
    @Getter('account/isEmailConfirmed') private isEmailConfirmed: boolean;
    @Getter('account/isActivated') private isActivated: boolean;
    @Getter('account/currentUser') private currentUser: User;

    get isDemoUser(): boolean {
        return this.currentUser.email.includes('demo') && this.currentUser.email.includes('@piquest.ch');
    }

    // Mounted is called initially
    private mounted() {
        console.log('App version: ' + this.$version);

        if (this.loggedIn) {
            // reload the user info
            userWebservice
                .getUserInfo()
                .then((getUserInfoResponse) => {
                    this.$store.commit('account/setCurrentUser', getUserInfoResponse);
                    this.$store.commit('account/setHaveAccess', true);
                    if (!getUserInfoResponse.activated) {
                        console.log('AccountNotActivated');
                        this.$router.push({ name: 'AccountNotActivated' });
                    }
                })
                .catch((error: Error) => {
                    this.$store.commit('account/setHaveAccess', false);
                    console.log('NotAuthorized');
                    if (!this.$route.path.includes('/auth/')) {
                        this.$router.push({ name: 'NotAuthorized' });
                    }
                });
        } else if (!window.location.href.includes('auth')) {
            this.$router.push({ name: 'Login' });
        }
    }
}
