































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import { Module } from '@/models/module';
import { moduleWebservice } from '@/webservices/module';
import Modal, { ModalOptions } from '@/components/prine/modal/Modal.vue';

@Component({
    name: 'ModuleCoverUploadModal',
    components: {
        Modal,
    },
})
export default class ModuleCoverUploadModal extends Vue {
    @Prop() private module: Module;

    private file: File | null = null;
    private errorMsg: string = '';
    private uploading: boolean = false;

    private modalOptions: ModalOptions = {
        width: 800,
        height: null,
        showDelete: false,
        isLoading: false,
        saveButtonText: 'Speichern',
        saveDisabled: false,
    };

    @Watch('file')
    private onFileChange() {
        let reader: any;
        reader = new FileReader();
        reader.onload = (imgsrc: any) => {
            if (imgsrc.target != null) {
                const el: HTMLImageElement = document.getElementById('uploadImage') as HTMLImageElement;
                if (el) {
                    const definitelyAnElement = el;
                    el.src = imgsrc.target.result;
                }
            }
        };
        if (this.file !== null && this.file.size > 0 && this.file.size <= 20971520) {
            reader.readAsDataURL(this.file);
            this.errorMsg = '';
        } else if (this.file !== null && this.file.size > 20971520) {
            this.errorMsg = 'fileTooBig';
            this.file = null;
        }
    }

    get fileNotEmpty() {
        return this.file !== null && this.file.size > 0;
    }

    private reset() {
        this.deleteSelectedFile();
    }

    private deleteSelectedFile() {
        this.file = {} as File;
    }

    private store() {
        const formData = new FormData();
        if (!this.validatePicture()) {
            return 0;
        }
        this.errorMsg = '';

        if (this.module.id !== null && this.file != null) {
            this.uploading = true;
            this.modalOptions.isLoading = true;

            formData.append('cover_picture', this.file);
            formData.append('module_id', this.module.id.toString());
            moduleWebservice
                .uploadPicture(this.module, formData)
                .then((response) => {
                    this.imageStored();
                    this.uploading = false;
                    this.modalOptions.isLoading = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.errorMsg = 'server';
                    this.uploading = false;
                    this.modalOptions.isLoading = false;
                });
        }
    }

    private validatePicture() {
        if (this.file === null) {
            this.errorMsg = 'imageHasNotChanged';
            return false;
        } else if (this.file != null && this.file.size > 20971520) {
            this.errorMsg = 'fileTooBig';
        } else {
            return true;
        }
    }

    /**
     * Emits the toggle method in the parent
     */
    private toggleModal() {
        this.$emit('toggleModuleCoverUploadModal');
    }

    /**
     * Emits the imageStored method in the parent
     */
    private imageStored() {
        this.$emit('imageStored');
    }
}
