import Vue from 'vue';
import Vuex, { MutationTree, ActionTree } from 'vuex';
import { Homework } from '../../../models/homework';

Vue.use(Vuex);

interface State {
    open_homeworks: Homework[];
    closed_homeworks: Homework[];
}

const mutations: MutationTree<State> = {
    setOpenHomeworks: (currentState, homeworks: Homework[]) => (currentState.open_homeworks = homeworks),
    setClosedHomeworks: (currentState, homeworks: Homework[]) => (currentState.closed_homeworks = homeworks),
};

const actions: ActionTree<State, any> = {};

const getters = {
    openHomeworks(currentState: State) {
        return currentState.open_homeworks;
    },

    closedHomeworks(currentState: State) {
        return currentState.closed_homeworks;
    },
};

const state: State = {
    open_homeworks: Array<Homework>(),
    closed_homeworks: Array<Homework>(),
};

const module = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};

export default module;
