












































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Topic } from '@/models/topic';
import { GridElementPosition } from '@/components/prine/gridtable/GridElementPosition';
import { Getter } from 'vuex-class';
import DetailedTopicStatsModal from '@/components/module/stats/topic/DetailedTopicStatsModal.vue';
import { Module } from '@/models/module';
import { scaleLinear } from 'd3-scale';
import { isArray } from 'util';

@Component({
    name: 'GridElementTopic',
    components: { DetailedTopicStatsModal },
})
export default class GridElementTopic extends Vue {
    @Prop() private topic: Topic;
    @Prop() private position: GridElementPosition;
    @Prop() private event_id: number;

    private currentlyHovered: boolean = false;
    private isStatsModalActive: boolean = false;

    private get calculateBackground() {
        if (this.topic.stats && this.topic.stats.progress === 0) {
            return 'background-color: rgba(235,235,235, 1.0)';
        } else {
            if (this.topic.stats && this.topic.stats.progress) {
                // tslint:disable-next-line:max-line-length
                return `background-image: -webkit-linear-gradient(left, ${this.calculateAvgColor} 0%,${this.calculateAvgColor} ${this.topic.stats.progress}%,#ffffff ${this.topic.stats.progress}%);`;
            }
        }

        return null;
    }

    private get calculateAvgColor() {
        return scaleLinear<string>()
            .domain([4, 5, 6])
            .range(['red', 'orange', 'green'])(this.topic.stats.avg);
    }

    private get isExistingTopic(): boolean {
        return this.topic.hasOwnProperty('title');
    }

    private get avgPerTopic() {
        if (this.topic.stats) {
            return 'Ø ' + Math.round(this.topic.stats.avg * 100) / 100;
        } else {
            return null;
        }
    }

    private get progressPerTopic() {
        if (this.topic.stats) {
            return Math.round(this.topic.stats.progress * 100) / 100 + '%';
        } else {
            return null;
        }
    }

    private openDetailedTopicStatsModal() {
        if (this.isExistingTopic) {
            console.log('openDetailedTopicStatsModal in GridElementTopic');
            this.isStatsModalActive = true;
        }
    }
    private toggleDetailedTopicStatsModal() {
        console.log('toggleDetailedTopicStatsModal in GridElementTopic');
        this.isStatsModalActive = !this.isStatsModalActive;
    }
}
