


















import { Component, Prop, Vue } from 'vue-property-decorator';
import { GridElementPosition } from '@/components/prine/gridtable/GridElementPosition';
import { Block } from '@/models/block';

import { Module } from '@/models/module';

@Component({
    name: 'GridElementBlock',
    components: {},
})
export default class GridElementBlock extends Vue {
    @Prop() private block: Block;
    @Prop() private position: GridElementPosition;
    @Prop() private module: Module;

    private get isExistingBlock(): boolean {
        return this.block.hasOwnProperty('name');
    }
}
