export interface ModuleStatus {
    name: string;
    title: string;
    color: string;
}

export enum ModuleStatusValues {
    DRAFT = 'draft',
    AWAITING_CONFIRMATION = 'awaiting_confirmation',
    CONFIRMATION_DECLINED = 'confirmation_declined',
    PUBLISHED = 'published',
    PURCHASED = 'purchased',
    ACTIVE = 'active',
    SHARED = 'shared',
    FINISHED = 'finished',
}
