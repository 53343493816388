








import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import { Module } from '@/models/module';
import OwnModuleCard from './OwnModuleCard.vue';

@Component({
    name: 'OwnModuleGrid',
    components: {
        OwnModuleCard,
    },
})
export default class OwnModuleGrid extends Vue {
    @Getter('module/ownModules')
    private ownModules: Module[];
}
