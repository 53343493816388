



















import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import { scaleLinear } from 'd3-scale';
import { EvaluationsPerUser } from '@/models/evaluationperuser';
import DetailedTopicStatsModal from '@/components/module/stats/topic/DetailedTopicStatsModal.vue';
import { Module } from '@/models/module';
import { Topic } from '@/models/topic';
import { GridElementPosition } from '@/components/prine/gridtable/GridElementPosition';

@Component({
    name: 'GridElementTopicColoredSlice',
    components: {
        DetailedTopicStatsModal,
    },
})
export default class GridElementTopicColoredSlice extends Vue {
    @Prop() private evaluationsPerUser: EvaluationsPerUser;
    @Prop() private topic: Topic;
    @Prop() private position: GridElementPosition;

    @Getter('module/selectedModule') private module: Module;

    private isStatsModalActive: boolean = false;

    private calculateBackgroundColor(evaluationsPerUser: EvaluationsPerUser) {
        const diff = evaluationsPerUser.diff;

        if (diff === 10) {
            return '#fff';
        }

        // Determine the color
        return scaleLinear<string>()
            .domain([1.2, 0, -1.2])
            .range(['green', 'white', 'red'])(diff);
    }

    private toggleDetailedTopicStatsModal() {
        this.isStatsModalActive = !this.isStatsModalActive;
    }
}
