import Vue from 'vue';
import router from 'vue-router';
import Home from '../components/Home.vue';

import CreateHomework from '../components/homework/CreateHomework.vue';
import ManageHomeworks from '../components/homework/ManageHomeworks.vue';

import CreateExam from '../components/exam/CreateExam.vue';
import ManageExams from '../components/exam/ManageExams.vue';

import ManageSubjects from '../components/subject/ManageSubjects.vue';

import ManageGroups from '../components/group/ManageGroups.vue';

import AccountSettings from '../components/profile/AccountSettings.vue';

import Login from '../components/auth/Login.vue';
import Logout from '../components/auth/Logout.vue';
import Register from '../components/auth/Register.vue';
import ConfirmEmail from '../components/auth/ConfirmEmail.vue';
import ConfirmInfo from '../components/auth/ConfirmInfo.vue';
import ForgotPassword from '../components/auth/ForgotPassword.vue';
import ResetPassword from '../components/auth/ResetPassword.vue';
import AccountNotActivated from '../components/AccountNotActivated.vue';

import ModuleConfigurator from '../components/module/configurator/ModuleConfigurator.vue';
import ModuleViewer from '../components/module/viewer/ModuleViewer.vue';
import ModuleTabContainer from '../components/module/manager/ModuleTabContainer.vue';

import ModuleProgressStats from '../components/module/stats/module-progress/ModuleProgressStats.vue';
import DemoViewMagicLinkAutoLogin from '../components/auth/DemoViewMagicLinkAutoLogin.vue';
// tslint:disable-next-line:max-line-length
import ModuleProgressStudentContainer from '../components/module/stats/module-progress-student/ModuleProgressStudentContainer.vue';

import BubbleChartEvent from '../components/chart/BubbleChartEvent.vue';

import NotAuthorized from '@/components/NotAuthorized.vue';

Vue.use(router);

export default new router({
    routes: [
        // Root
        {
            path: '/',
            name: 'Home',
            component: ModuleTabContainer,
        },
        {
            path: '/demo',
            name: 'DemoViewMagicLinkAutoLogin',
            component: DemoViewMagicLinkAutoLogin,
        },
        /*
        // Homeworks
        {
            path: '/homeworks',
            name: 'ManageHomeworks',
            component: ManageHomeworks,
        },
        {
            path: '/homeworks/new',
            name: 'CreateHomework',
            component: CreateHomework,
        },
        {
            path: '/homeworks/update',
            name: 'UpdateHomework',
            component: CreateHomework,
        },

        // Exams
        {
            path: '/exams',
            name: 'ManageExams',
            component: ManageExams,
        },
        {
            path: '/exams/new',
            name: 'CreateExam',
            component: CreateExam,
        },
        {
            path: '/exams/update',
            name: 'UpdateExam',
            component: CreateExam,
        },
        */
        //  Groups
        {
            path: '/groups',
            name: 'ManageGroups',
            component: ManageGroups,
        },

        // Subjects
        {
            path: '/subjects',
            name: 'ManageSubjects',
            component: ManageSubjects,
        },

        // Modules
        {
            path: '/modules',
            name: 'ModuleTabContainer',
            component: ModuleTabContainer,
        },
        {
            path: '/modules/configurator',
            name: 'ModuleConfigurator',
            component: ModuleConfigurator,
        },
        {
            path: '/modules/viewer',
            name: 'ModuleViewer',
            component: ModuleViewer,
        },

        // Stats
        {
            path: '/stats/progress',
            name: 'ModuleProgressStats',
            component: ModuleProgressStats,
        },

        {
            path: '/stats/progress-students',
            name: 'ModuleProgressStudentContainer',
            component: ModuleProgressStudentContainer,
        },

        // Profile
        {
            path: '/auth/account',
            name: 'AccountSettings',
            component: AccountSettings,
        },

        // Authentication
        {
            path: '/auth/login',
            name: 'Login',
            component: Login,
        },
        {
            path: '/auth/logout',
            name: 'Logout',
            component: Logout,
        },
        {
            path: '/auth/register',
            name: 'Register',
            component: Register,
        },
        {
            path: '/auth/confirmEmail',
            name: 'ConfirmEmail',
            component: ConfirmEmail,
        },
        {
            path: '/auth/confirmInfo',
            name: 'ConfirmInfo',
            component: ConfirmInfo,
        },
        {
            path: '/auth/forgotPassword',
            name: 'ForgotPassword',
            component: ForgotPassword,
        },
        {
            path: '/auth/resetPassword',
            name: 'ResetPassword',
            component: ResetPassword,
        },
        {
            path: '/account-status',
            name: 'AccountNotActivated',
            component: AccountNotActivated,
        },

        // Charts
        {
            name: 'BubbleChartEvent',
            path: '/charts/BubbleChartEvent',
            component: BubbleChartEvent,
        },

        // Not Authorized route
        {
            name: 'NotAuthorized',
            path: '/noaccess',
            component: NotAuthorized,
        },
    ],
});
