import axios from 'axios';

class UploadFileWebservice {
    public upload(formData: FormData) {
        return axios.post<string>('/files/upload', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    }

    public uploadSource(formData: FormData) {
        return axios.post<string>('/files/uploadSource', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    }
}

export const uploadFileWebservice = new UploadFileWebservice();
