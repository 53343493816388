































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import { ViewType } from '@/components/prine/enums/ViewType';
import { moduleWebservice } from '@/webservices/module';
import { Module } from '@/models/module';

import ModuleTab from '@/components/module/manager/tabs/ModuleTab.vue';
import ActivityFeedFeedback from './ActivityFeedFeedback.vue';
import EmptyListMsg from '@/components/prine/EmptyListMsg.vue';

import OwnModuleList from './OwnModuleList.vue';
import OwnModuleGrid from './OwnModuleGrid.vue';

@Component({
    name: 'OwnTab',
    components: {
        ModuleTab,
        ActivityFeedFeedback,
        OwnModuleList,
        OwnModuleGrid,
        EmptyListMsg,
    },
})
export default class OwnTab extends Vue {
    private ViewType = ViewType;

    @Getter('module/ownModules')
    private ownModules: Module[];
}
