import Vue from 'vue';
import App from './App.vue';
import router from './routing/router';
import store from './vuex/store';
// import './helpers/registerServiceWorker'

// Import Sentry for error logging framework
import Raven from 'raven-js';
import ClipLoader from 'vue-spinner/src/ClipLoader.vue';
import Buefy from 'buefy';
import axios, { AxiosError, AxiosResponse } from 'axios';
import VTooltip from 'v-tooltip';
import Multiselect from 'vue-multiselect';
import * as log from 'loglevel';

log.setLevel(log.levels.DEBUG);

// https://github.com/Akryum/v-tooltip
// Easy tooltips, popovers and dropdown with Popper.js
Vue.use(VTooltip);

// Importing custom fonts for jsPDF
// Fonts have to converted before importing
// https://rawgit.com/MrRio/jsPDF/master/fontconverter/fontconverter.html
// http://raw.githack.com/MrRio/jsPDF/master/docs/index.html

import './assets/fonts/Nexa/NexaBold-normal.js';
import './assets/fonts/Nexa/NexaHeavy-normal.js';

// Import MomentsJS
import moment from 'moment';

// Main Sass file
// import './assets/sass/setup.scss';
import './assets/sass/spacing.scss';
import './assets/sass/style.scss';

// Font awesome icons
// imported in public/index.html
// <link rel="stylesheet" href="assets/css/fontawesome-pro.css">

// Import vue-spinner clip-loader component
Vue.component('clip-loader', ClipLoader);

// https://vue-multiselect.js.org/
Vue.component('multiselect', Multiselect);

// -- BUEFY --
// Vue JS Components for Bulma
Vue.use(Buefy, {
    defaultIconPack: 'fas',
});

Vue.prototype.buefy = Buefy;

// -- Filters --
Vue.filter('truncate', (text: string, stop: number, clamp: string) => {
    return text.slice(0, stop) + (stop < text.length ? clamp || '...' : '');
});

Vue.filter('fromAgo', (value: string) => {
    if (value) {
        return moment(String(value)).fromNow();
    }
});

// ===== LOCALIZATION =====
// All localization is included here
import i18n from './localization/localization';
Vue.config.productionTip = false;

// Fetch the token
// const token = localStorage.getItem('api_token');
import Cookies from 'js-cookie';
const token = Cookies.get('api_token');

// If the token exist, set it in the authorization
if (token) {
    // Axios configuration
    axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
    axios.defaults.headers.common.Authorization = 'Bearer '.concat(token);
    store.commit('account/setApiToken', token);
}

// Define the base api url
axios.defaults.baseURL = process.env.VUE_APP_API_URL;

Vue.prototype.BACKEND_BASE_URL = process.env.VUE_APP_BACKEND_BASE_URL;

// CURRENT VERSION NUMBER (18004 -> 18 year, 004 day of the year)
Vue.prototype.$version = '5.0.0.22280';

// Sentry error logging framework
Raven.config('https://3144e7d896e04f4fafe04126d7127891@sentry.io/306963', {
    release: Vue.prototype.$version,
    shouldSendCallback: () => {
        // Only send sentry callback when sentry is enabled in the env file
        if (process.env.VUE_APP_SENTRY_ENABLED !== undefined) {
            return process.env.VUE_APP_SENTRY_ENABLED!.trim() === 'true';
        }

        return false;
    },
}).install(); // TODO: check if its still working

// Add a 401 response interceptor
axios.interceptors.response.use(
    (response: AxiosResponse) => {
        return response;
    },
    (error: AxiosError) => {
        // check if there is a 401 response and the user is not on auth pages
        if (error.response && error.response.status === 401 && !window.location.href.includes('auth')) {
            window.location.href = '/#/auth/logout';
            // check for laravel passport inavlid_credentials error
        } else if (
            error.response !== undefined &&
            error.response.data.error !== 'invalid_credentials' &&
            error.response.status === 401 &&
            !window.location.href.includes('auth')
        ) {
            window.location.href = '/#/auth/logout';
            // check if the server is not responding at all
        } else if (error.response === undefined) {
            console.log('Gets called when offline');
            // window.location.href = '/#/auth/logout';
        } else {
            return Promise.reject(error);
        }
    }
);

// Vue router interceptor
router.beforeEach((to, from, next) => {
    const loggedIn = store.getters['account/loggedIn'];
    const currentUser = store.getters['account/currentUser'];
    const isActivated = store.getters['account/isActivated'];
    const isEmailConfirmed = store.getters['account/isEmailConfirmed'];

    if (
        loggedIn &&
        !isEmailConfirmed &&
        (currentUser.role_id === 3 || currentUser.role_id === 1) &&
        !to.fullPath.includes('/auth/')
    ) {
        next({ name: 'ConfirmEmail' });
    } else if (
        loggedIn &&
        (currentUser.role_id === 3 || currentUser.role_id === 1) &&
        !isActivated &&
        !to.fullPath.includes('/auth/') &&
        to.name !== 'AccountNotActivated' &&
        to.name !== 'DemoViewMagicLinkAutoLogin'
    ) {
        console.log('No dont go here');
        next({ name: 'AccountNotActivated' });
    } else {
        next();
    }
});

// Vue shepherd
import 'shepherd.js/dist/css/shepherd.css';

// ===== VUE =====
// Create Vue Object and add all necesarry parts
new Vue({
    i18n,
    router,
    store,
    render: (h) => h(App),
}).$mount('#app');
