import axios from 'axios';
import HttpException from './HttpException';

class SynchronizationWebservice {
    public async getChanges(take: number = 8) {
        try {
            const response = await axios.get('/synchronizations/getChanges', {
                params: {
                    take,
                },
            });
            return response.data;
        } catch (error) {
            throw new HttpException(error.response.status, 'There was an error fetching the newest changes');
        }
    }
}

export const synchronizationWebservice = new SynchronizationWebservice();
