






























































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Subject } from '../../models/subject';
import { Topic } from '../../models/topic';
import { subjectWebservice } from '../../webservices/subject';
import ConfirmModal from './../prine/ConfirmModal.vue';
import { FormState } from './../prine/enums/FormState';

@Component({
    name: 'CreateSubjectModal',
    components: {
        ConfirmModal,
    },
})
export default class CreateSubjectModal extends Vue {
    private FormState = FormState;

    private subject: Subject = {
        title: '',
        token: '',
        id: 0,
        topics: Array<Topic>(),
    };

    private isConfirmModalActive: boolean = false;
    private errorMsg: string = '';
    private serverError: string = '';
    private state: FormState = FormState.New;

    @Prop() private selectedSubject: Subject;

    // Mounted is called initially
    private mounted() {
        if (this.selectedSubject && this.selectedSubject.hasOwnProperty('title')) {
            // this.subject =this.selectedSubject;
            this.subject = JSON.parse(JSON.stringify(this.selectedSubject));
            this.state = FormState.Update;
        }
    }

    private store() {
        // console.log('store method fired from child')

        if (this.subject.title === '') {
            this.errorMsg = 'title';
            return false;
        } else if (this.subject.token === '') {
            this.errorMsg = 'token';
            return false;
        }

        if (this.state === FormState.New) {
            // console.log('store new fired from child')
            subjectWebservice
                .store(this.subject)
                .then(function(response) {
                    // console.log('store new then fired from child')
                    self.toggleModal();
                })
                .catch(function(error) {
                    self.serverError = error.response.data;
                });
        } else if (this.state === FormState.Update) {
            //  console.log('store update fired from child')
            subjectWebservice
                .update(this.subject)
                .then(function(response) {
                    // console.log('store update then fired from child')
                    self.toggleModal();
                })
                .catch(function(error) {
                    self.serverError = error.response.data;
                });
        }
        const self = this;
    }

    private toggleConfirmModal(answer: boolean) {
        this.isConfirmModalActive = !this.isConfirmModalActive;
        if (answer) {
            this.remove();
        }
    }

    private remove() {
        const self = this;
        subjectWebservice
            .remove(this.subject)
            .then((response) => {
                self.toggleModal();
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    private toggleModal() {
        this.fetchSubjects();
        this.$emit('toggleCreateSubjectModal');
    }

    private fetchSubjects() {
        this.$emit('fetchSubjects', this.subject.title);
    }
}
