import axios from 'axios';
import { Topic } from '../models/topic';

class TopicWebservice {
    public store(topic: Topic) {
        console.log('Topic type', topic.topic_type);

        return axios.post<Topic>('/topics/store', {
            title: topic.title,
            description: topic.description,
            order: topic.order,
            block_id: topic.block_id,
            questions: topic.questions,
            topic_type_id: topic.topic_type!.id, // Set the 1 as default (normal)
        });
    }

    public update(topic: Topic) {
        return axios.post<Topic>('/topics/update', {
            id: topic.id,
            title: topic.title,
            description: topic.description,
            order: topic.order,
            questions: topic.questions,
            topic_type_id: topic.topic_type != null ? topic.topic_type.id : 1, // Set the 1 as default (normal)
        });
    }

    public remove(topic: Topic) {
        return axios.post('/topics/remove', {
            id: topic.id,
        });
    }

    public swap(topic_id: number, new_block_id: number, new_order: number) {
        return axios.post<Topic>('/topics/swap', {
            topic_id,
            new_block_id,
            new_order,
        });
    }
}

export const topicWebservice = new TopicWebservice();
