import { render, staticRenderFns } from "./GridElementTopicStatsModal.vue?vue&type=template&id=82cd3d86&scoped=true&"
import script from "./GridElementTopicStatsModal.vue?vue&type=script&lang=ts&"
export * from "./GridElementTopicStatsModal.vue?vue&type=script&lang=ts&"
import style0 from "./GridElementTopicStatsModal.vue?vue&type=style&index=0&id=82cd3d86&scoped=true&style=scss&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "82cd3d86",
  null
  
)

export default component.exports