























import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import { Module } from '@/models/module';
import { moduleWebservice } from '@/webservices/module';

@Component({
    name: 'ModuleConfiguratorMeta',
    components: {},
})
export default class ModuleConfiguratorMeta extends Vue {
    @Prop() private module: Module;

    // Used for disp
    private showTooltip: boolean = true;

    private mounted() {
        // Do not show the tooltip when there is already a name
        this.showTooltip = this.module.name.length === 0;
    }

    /**
     * On name or description change call the corresponding store/update method
     */
    @Watch('module.name')
    @Watch('module.description')
    private onModuleNameValueChange() {
        // The person has started to type, hide the information that it needs a name
        this.showTooltip = false;

        if (this.module.id !== null) {
            this.updateModule();
        } else {
            this.storeModule();
        }
    }

    /**
     * Store the module data
     */
    private storeModule() {
        moduleWebservice.store(this.module).then((response) => {
            this.module.id = response.data.id;

            // Add the module_id in the params so the drag&drop and also when the user reload it does not break
            this.$router.replace({ path: '/modules/configurator', query: { module_id: response.data.id.toString() } });

            this.showNotification();
        });
    }

    /**
     * Update the module data
     */
    private updateModule() {
        moduleWebservice.update(this.module).then((response) => {
            this.showNotification();
        });
    }

    /**
     * Show a snackbar notification banner
     */
    private showNotification() {
        this.$buefy.snackbar.open({
            duration: 5000,
            message: `Die Änderungen am Modul wurden korrekt gespeichert`,
            type: 'is-primary',
            queue: true,
            position: 'is-top',
        });
    }
}
