<template>
<!-- Define the height fixed because the chart is always 800 pixel height + 100pixel for header. So the footer is not overlapping -->
<div class="container-fluid" style="height: 900px">
    <div class="row">
        <div class="col-lg-12">
            <div id="echart">
                <h2 class="title">{{topic_name}}</h2>
                <router-link  class="button is-primary" style="margin-bottom:20px" :to="return_url">Zurück</router-link>

                <IEcharts :loading="chart_loading" class="echartPunchCard" :option="punchCardOption" :resizable="resizable" :auto-resize="autoresize"></IEcharts>
            </div>
        </div>
    </div>
</div>
</template>

<script type="text/babel">
import IEcharts from 'vue-echarts-v3/src/full.js';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
import axios from "axios";
export default {

    components: {
        IEcharts,
        PulseLoader,
    },
    mounted() {
        this.event_id = this.$route.params.event_id;
        this.return_url = this.$route.params.return_url;

        this.load();
    },
    props: [],
    data() {
        return {
            event_id: null,
            return_url: '',
            repeatable: false,
            chart_data: null,
            resizable: true,
            autoresize: true,
            chart_loading: true,
            topic_name: null,
            punchCardOption: {

                legend: {
                    data: [''],
                    left: 'right'
                },
                tooltip: {
                    position: 'inside',
                    formatter: function(params) {
                        // return  params.value[0]+ '<br/>'+ Grade Number
                        //         params.value[1]+ '<br/>'+ Question Number
                        //         params.value[2]+ '<br/>'+ Scale of the circle
                        //         params.value[3]+ '<br/>'+ Number of Users
                        //         params.value[4]+ '<br/>'+ Avg of grades
                        //         params.value[5]+ '<br/>'; Users[[firstname,lastname,grade],[...]] in this rounded grade group
                        var tooltip = 'Question ' + (params.value[1] + 1) + '</br>Avg is : ' + params.value[4] + ' of ' + params.value[3] + ' Users';

                        // Add users with their grade to tooltip
                        params.value[5].forEach(function(object, index) {
                            tooltip += '</br>' + object[0] + ' ' + object[1] +' : ' + object[2] ;
                            // tooltip += '</br>' + object[0] + (object[1] !== undefined ? object[1] : '') +' : ' + object[2] ;
                        });

                        return tooltip;
                    }
                },
                grid: {
                    left: 2,
                    bottom: 10,
                    right: 10,
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    data: ['0', '1', '1.5', '2', '2.5', '3', '3.5', '4', '4.5', '5', '5.5', '6', ''], // Grade
                    boundaryGap: false,
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: '#999',
                            type: 'dashed'
                        }
                    },
                    axisLine: {
                        show: false
                    }
                },
                yAxis: {
                    type: 'category',
                    data: [], // Questions Titles
                    axisLine: {
                        show: false
                    },
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: '#999',
                            type: 'dashed'
                        }
                    },
                },
                series: [{
                    name: '',
                    type: 'scatter',
                    symbolSize: function(val) {
                        return val[2] * 2;
                    },
                    data: [], // Avg Users Grade Grouped togather
                    animationDelay: function(idx) {
                        return idx * 5;
                    }
                }]
            },
        };
    },
    methods: {
        load() {
            var self = this;

            axios.get('/charts/usersByEvent', {
                params: {
                    event_id: self.event_id,
                }
            }).then(function(response) {

                self.chart_data = response.data;
                self.topic_name = response.data.topic_name;
                var questions_title = [];
                var questionIndex = 0;

                self.chart_data.questions.forEach(function(object, index) {
                    questions_title = questions_title.concat(object.title);
                    questionIndex += 1;
                });

                self.punchCardOption.yAxis.data = questions_title;

                var sampleData = [];

                // Initialization of all the sample data
                for (var i = 0; i < questionIndex; i++) {
                    sampleData[i] = [];
                    for (var j = 0 ; j <= 6; j = j + 0.5) {
                        sampleData[i][j + ''] = {
                            sum_of_grades: 0,
                            grade_number: 0,
                            number_of_users: 0,
                            users: [],
                        };
                    }
                }

                // Iterate over all users and their questions, round their grades and count how many times which grade has occured
                for (var user in self.chart_data.evaluations) {
                    var index = 0;
                    for (var question in self.chart_data.evaluations[user]) {

                        if (sampleData[index] != undefined) {

                            // Round by 0.5
                            var rounded_grade = Math.round(self.chart_data.evaluations[user][question].grade * 2) / 2;

                            var grade = self.chart_data.evaluations[user][question].grade;

                            var rounded_grade_key = rounded_grade + '';

                            sampleData[index][rounded_grade_key].sum_of_grades += grade;
                            sampleData[index][rounded_grade_key].grade_number = grade;
                            sampleData[index][rounded_grade_key].number_of_users += 1;

                            // Add the username and grade to users array for displaying in tooltip per each rounded grade group
                            sampleData[index][rounded_grade_key].users.push([self.chart_data.evaluations[user][question][0].user_firstname,
                                                                             self.chart_data.evaluations[user][question][1].user_lastname,
                                                                             grade]);

                            index += 1;
                        }
                    }
                }

                // The scale factor should never be bigger than the max scale (it is dependend on the amount of users)
                var maxScale = 30;
                var scale = (maxScale / Object.keys(self.chart_data.evaluations).length);

                for (var question in sampleData) {
                    for (var grade in sampleData[question]) {

                        self.punchCardOption.series[0].data.push([
                            grade,
                            parseInt(question),
                            (sampleData[question][grade].number_of_users * scale) != 0 ? (sampleData[question][grade].number_of_users * scale + 5) : 0,
                            sampleData[question][grade].number_of_users,
                            (sampleData[question][grade].sum_of_grades / sampleData[question][grade].number_of_users).toFixed(2),
                            sampleData[question][grade].users,
                        ]);
                    }
                }

                self.chart_loading = false;
            })
            .catch(function(error) {
                console.log(error);
            });
        },
    },
};
</script>

<style scoped>
.echartPunchCard {
    width: auto;
}

#echart {
    height: 800px;
}
</style>
