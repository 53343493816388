













































































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import moment from 'moment';
import { Module } from '@/models/module';
import { ModuleStatusValues } from '@/models/moduleStatus';
import { Event } from '@/models/event';
import { moduleWebservice } from '@/webservices/module';
import { eventWebservice } from '@/webservices/event';

import ActivateModuleModal from '@/components/module/manager/ActivateModuleModal.vue';
import PublishModuleModal from '@/components/module/manager/PublishModuleModal.vue';
import EditPublishMetadataModal from '@/components/module/manager/EditPublishMetadataModal.vue';
import ShareModuleModal from '@/components/module/manager/ShareModuleModal.vue';
import DetailedConfirmModal from '@/components/prine/DetailedConfirmModal.vue';
import ModuleCoverUploadModal from '@/components/module/configurator/ModuleCoverUploadModal.vue';
import OwnModuleDuplicateButton from '@/components/module/manager/tabs/own/OwnModuleDuplicateButton.vue';

import ModuleProgressStatsSimple from '@/components/module/stats/module-progress-simple/ModuleProgressStatsSimple.vue';
import { User } from '@/models/user';

@Component({
    name: 'OwnModuleCard',
    components: {
        ActivateModuleModal,
        ShareModuleModal,
        PublishModuleModal,
        DetailedConfirmModal,
        ModuleCoverUploadModal,
        ModuleProgressStatsSimple,
        EditPublishMetadataModal,
        OwnModuleDuplicateButton,
    },
})
export default class OwnModuleCard extends Vue {
    public moduleStatus = ModuleStatusValues;
    @Prop() private module: Module;

    private affectedData: Map<string, number | string[] | number[]> = new Map();
    private isConfirmModalActive: boolean = false;
    private isModuleCoverUploadModalActive: boolean = false;

    private isModalActive: boolean = false;
    private isPublishModalActive: boolean = false;
    private isEditPublishModalActive: boolean = false;
    private isShareModalActive: boolean = false;

    private event: Event;
    private isLoading: boolean = true;
    private users: User[] = [];

    @Action('module/fetchAllModules')
    private fetchAllModules: any;

    private mounted() {
        this.loadData();
    }

    // Mounted is called initially
    private async loadData() {
        this.affectedData.set(this.$tc('keywords.block', 2).toString(), this.module.blocks_count);
        this.users = await moduleWebservice.sharedUsers(this.module.id!);
        const emails = this.users.map((user) => user.email);
        this.affectedData.set(this.$t('labels.alreadySharedWith').toString(), emails);
    }

    private reload() {
        this.fetchAllModules();
    }

    private async toggleConfirmModal(answer: boolean) {
        if (answer === true) {
            await moduleWebservice.remove(this.module);
            this.isConfirmModalActive = !this.isConfirmModalActive;
            this.fetchAllModules();
        } else {
            this.isConfirmModalActive = !this.isConfirmModalActive;
        }
    }

    private async publish(module: Module) {
        await moduleWebservice.publish(module);
        this.fetchAllModules();
    }

    get storeAppUrl() {
        return process.env.VUE_APP_STORE_URL;
    }

    private async viewInStore() {
        console.log(this.storeAppUrl);
        const url = this.storeAppUrl + '/modules/' + this.module.id;
        window.open(url, '_blank');
    }

    private imageStored() {
        this.fetchAllModules();
        this.toggleModuleCoverUploadModal();
    }

    private toggleActivateModuleModal() {
        this.isModalActive = !this.isModalActive;
    }

    private togglePublishModuleModal() {
        this.isPublishModalActive = !this.isPublishModalActive;
    }

    private toggleShareModuleModal() {
        this.isShareModalActive = !this.isShareModalActive;
    }

    private toggleModuleCoverUploadModal() {
        this.isModuleCoverUploadModalActive = !this.isModuleCoverUploadModalActive;
    }

    private get activeClassNames() {
        if (!this.module.activeModules) {
            return '';
        }

        const groupNames = this.module.activeModules.map((activeModule) => {
            if (activeModule.events.length > 0) {
                return activeModule.events[0].group.name;
            }
        });

        return groupNames.join(', ');
    }

    private getTooltipText(name: string) {
        if (name === this.moduleStatus.AWAITING_CONFIRMATION) {
            return 'Sie werden per Mail benachrichtigt, sobald Ihr Modul bestätigt wurde.';
        } else if (name === this.moduleStatus.CONFIRMATION_DECLINED) {
            return 'Ihr Modul wurde leider vom piQuest Administrator abgelehnt.';
        } else if (name === this.moduleStatus.PUBLISHED) {
            return 'Ihr Modul wurde bestätigt und ist nun im piQuest Store verfügbar.';
        }
    }
}
