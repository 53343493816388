




































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import { Topic } from '@/models/topic';
import TopicLinkBlock from './TopicLinkBlock.vue';
import TopicSolutionBlock from './TopicSolutionBlock.vue';
import Shepherd from 'shepherd.js';
import { User } from '@/models/user';
import { userWebservice } from '@/webservices/user';

@Component({
    name: 'TopicViewContent',
    components: {
        TopicLinkBlock,
        TopicSolutionBlock,
    },
})
export default class TopicViewContent extends Vue {
    @Prop() private topic: Topic;
    private isOpen: boolean = false;

    @Getter('account/currentUser') private currentUser: User;

    private tour = new Shepherd.Tour({
        useModalOverlay: true,
        defaultStepOptions: {
            classes: 'shadow-md bg-purple-dark',
            scrollTo: true,
        },
        exitOnEsc: true,
    });

    private mounted() {
        if (this.currentUser.show_tour) {
            this.shepherdTour(this);
        }
    }

    private shepherdTour(component: TopicViewContent) {
        this.tour.addStep({
            title: 'Register Fragen',
            text: 'Du kannst hier alle Fragen des Moduls anschauen.',
            buttons: [
                {
                    action() {
                        return this.complete();
                    },
                    classes: 'button is-primary',
                    text: 'OK',
                },
                {
                    action() {
                        cancelTour();
                        return this.cancel();
                    },
                    classes: 'button is-primary',
                    text: 'Tour überspringen',
                },
            ],
            id: 'creating',
        });
        this.tour.start();

        async function cancelTour() {
            await userWebservice.changeTour(false);
            await userWebservice.getUserInfo().then((response) => {
                component.$store.commit('account/setCurrentUser', response);
            });
            // Show a snackbar message that tour can be enabled in the settings
            component.$buefy.snackbar.open({
                duration: 7000,
                message: `Du hast die Tour abgebrochen. Sie wird dir nicht mehr angezeigt. Falls du sie wieder aktivieren möchtest, kannst du das in den Einstellungen vornehmen.`,
                type: 'is-primary',
                queue: false,
                position: 'is-top',
            });
        }
    }
}
