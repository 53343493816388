













































































































































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Group } from '../../models/group';
import { User } from '../../models/user';
import { Subject } from '../../models/subject';
import { Homework } from '../../models/homework';
import { subjectWebservice } from '../../webservices/subject';
import ReportSlider from '../prine/evaluations/ReportSlider.vue';
import { Exam } from '../../models/exam';

@Component({
    name: 'UserSubjectsModal',
    components: {
        ReportSlider,
    },
})
export default class UserSubjectsModal extends Vue {
    private tableSettings = {
        openedDetailed: Array<number>(),
    };

    private subjects = new Array<Subject>();
    private notDoneExams = new Array<Exam>();
    private notDoneHomeworks = new Array<Homework>();

    private loader = {
        loadingSubjects: false,
        loadingNotDoneEvents: false,
        color: '#fdc05d',
        size: '50px',
    };
    @Prop() private user: User;
    @Prop() private group: Group;

    // Mounted is called initially
    private mounted() {
        this.fetchUserSubjectsByGroup(this.user, this.group);
    }

    private fetchUserSubjectsByGroup(user: User, group: Group) {
        console.log('fetchUserSubjectsByGroup fired');
        this.loader.loadingSubjects = true;
        const self = this;
        // Fetch all groups
        subjectWebservice.getUserSubjectsByGroup(user, group).then((response) => {
            self.subjects = response.data;
            self.loader.loadingSubjects = false;
        });
    }

    private fetchUserNotDoneEvents(subject: Subject) {
        console.log('fetchUserNotDoneEvents fired');
        const self = this;
        this.loader.loadingNotDoneEvents = true;
        subjectWebservice
            .fetchUserNotDoneEvents(this.user, this.group, subject)
            .then((response) => {
                self.notDoneExams = response.data.exams;
                self.notDoneHomeworks = response.data.homeworks;
                self.loader.loadingNotDoneEvents = false;
            })
            .catch((error) => {
                console.log(error);
            });
    }

    private toggleDetails(subject: Subject) {
        const self = this;
        self.notDoneExams = new Array<Exam>();
        self.notDoneHomeworks = new Array<Homework>();
        if (this.tableSettings.openedDetailed.includes(subject.id)) {
            this.tableSettings.openedDetailed = [];
            self.fetchUserNotDoneEvents(subject);
        } else {
            this.tableSettings.openedDetailed = [];
            setTimeout(() => {
                self.tableSettings.openedDetailed.push(subject.id);
                self.fetchUserNotDoneEvents(subject);
            }, 100);
        }
    }

    private toggleModal() {
        this.$emit('toggleUserSubjectsModal', this.user);
    }
}
