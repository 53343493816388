var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-table',{attrs:{"data":_vm.modules,"mobile-cards":true,"perPage":_vm.tableSettings.perPage,"opened-detailed":_vm.tableSettings.openedDetailed,"striped":_vm.tableSettings.isStriped,"paginated":_vm.tableSettings.isPaginated,"hoverable":_vm.tableSettings.isHoverable,"default-sort":"name","default-sort-direction":"asc"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('b-table-column',{attrs:{"field":"name","label":_vm.$t('keywords.name'),"sortable":""}},[_c('router-link',{attrs:{"to":{ name: 'ModuleConfigurator', query: { module_id: props.row.id } }}},[_vm._v(" "+_vm._s(props.row.name)+" ")])],1),_c('b-table-column',{attrs:{"field":"tools","label":_vm.$t('keywords.actions')}},[(
                        props.row.status.name !== 'active' &&
                            props.row.status.name !== 'finished' &&
                            props.row.publishedModule === null
                    )?_c('a',{staticClass:"button is-primary action-button",attrs:{"data-cy":'activateModuleButton_' + props.row.id},on:{"click":function($event){return _vm.showActivateModal(props.row)}}},[_c('span',{staticClass:"icon is-small"},[_c('i',{staticClass:"fal fa-graduation-cap"})]),_c('span',[_vm._v("Für Klasse aufschalten")])]):_vm._e(),(
                        props.row.status.name !== 'active' &&
                            props.row.status.name !== 'finished' &&
                            props.row.publishedModule === null
                    )?_c('a',{staticClass:"button is-primary action-button",attrs:{"data-cy":'shareModuleButton_' + props.row.id},on:{"click":function($event){return _vm.showShareModal(props.row)}}},[_c('span',{staticClass:"icon is-small"},[_c('i',{staticClass:"fal fa-share-square"})]),_c('span',[_vm._v("Teilen")])]):_vm._e(),(
                        props.row.status.name !== 'active' &&
                            props.row.status.name !== 'finished' &&
                            props.row.publishedModule === null
                    )?_c('a',{staticClass:"button is-primary action-button",attrs:{"data-cy":'publishModuleButton_' + props.row.id},on:{"click":function($event){return _vm.showPublishModal(props.row)}}},[_c('span',{staticClass:"icon is-small"},[_c('i',{staticClass:"fal fa-upload"})]),_c('span',[_vm._v("Veröffentlichen")])]):_vm._e(),(
                        props.row.publishedModule !== null &&
                            props.row.publishedModule.status !== null &&
                            (props.row.publishedModule.status.name === _vm.moduleStatus.AWAITING_CONFIRMATION ||
                                props.row.publishedModule.status.name === _vm.moduleStatus.PUBLISHED)
                    )?_c('a',{staticClass:"button is-primary action-button",attrs:{"data-cy":'editpublishModuleButton_' + props.row.id},on:{"click":function($event){return _vm.showEditPublishModal(props.row)}}},[_c('span',{staticClass:"icon is-small"},[_c('i',{staticClass:"fal fa-pen"})])]):_vm._e()]),_c('b-table-column',{attrs:{"field":"duplicate","label":_vm.$t('keywords.duplicate'),"centered":""}},[_c('b-tooltip',{attrs:{"label":_vm.$t('labels.duplicateModule'),"position":"is-right"}},[_c('own-module-duplicate-button',{staticClass:"copy-icon",attrs:{"moduleId":props.row.id},on:{"reloadModules":_vm.reload}})],1)],1),_c('b-table-column',{attrs:{"field":"remove","label":_vm.$t('keywords.remove'),"centered":""}},[_c('a',{staticClass:"delete-icon",on:{"click":function($event){return _vm.showConfirmModal(props.row)}}},[_c('b-tooltip',{attrs:{"label":_vm.$t('labels.deleteModule'),"position":"is-right"}},[_c('span',{staticClass:"icon"},[_c('i',{staticClass:"fa fa-trash"})])])],1)])]}}])}),(_vm.isConfirmModalActive)?_c('detailed-confirm-modal',{attrs:{"name":_vm.selectedModule.name,"localizationKey":"module","affectedData":_vm.affectedData},on:{"toggleConfirmModal":_vm.toggleConfirmModal}}):_vm._e(),(_vm.isActivateModalActive)?_c('activate-module-modal',{attrs:{"module":_vm.selectedModule},on:{"toggleActivateModuleModal":_vm.toggleActivateModuleModal,"reload":_vm.reload}}):_vm._e(),(_vm.isShareModalActive)?_c('share-module-modal',{attrs:{"module":_vm.selectedModule},on:{"toggleShareModuleModal":_vm.toggleShareModuleModal,"reload":_vm.reload}}):_vm._e(),(_vm.isPublishModalActive)?_c('publish-module-modal',{attrs:{"module":_vm.selectedModule},on:{"togglePublishModuleModal":_vm.togglePublishModuleModal,"reload":_vm.reload}}):_vm._e(),(_vm.isEditPublishModalActive)?_c('edit-publish-metadata-modal',{attrs:{"module":_vm.selectedModule},on:{"toggleEditPublishModal":function($event){_vm.isEditPublishModalActive = !_vm.isEditPublishModalActive},"reload":_vm.reload}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }