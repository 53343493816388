import axios from 'axios';
import { Evaluation } from '../models/evaluation';
import HttpException from './HttpException';

class EvaluationWebservice {
    public async getNewestEvaluations(skip: number = 0, take: number = 8) {
        try {
            const response = await axios.get<Evaluation[]>('/evaluations/newest', {
                params: {
                    skip,
                    take,
                },
            });
            return response.data;
        } catch (error) {
            throw new HttpException(error.response.status, 'There was an error fetching the newest evaluations');
        }
    }

    public async getEvaluationsByEventId(event_id: number) {
        try {
            const response = await axios.get<Evaluation[]>('/evaluations/byEventId', {
                params: {
                    event_id,
                },
            });

            return response.data;
        } catch (error) {
            throw new HttpException(
                error.response.status,
                'There was an error fetching the newest evaluations by an event id'
            );
        }
    }
}

export const evaluationWebservice = new EvaluationWebservice();
