







































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import { Topic } from '@/models/topic';
import DetailedTopicView from './DetailedTopicView.vue';
import BubbleChartView from './BubbleChartView.vue';
import { GridElementPosition } from '@/components/prine/gridtable/GridElementPosition';
import TopicViewContent from '@/components/module/viewer/elements/topic/TopicViewContent.vue';

@Component({
    name: 'DetailedTopicStatsModal',
    components: {
        DetailedTopicView,
        BubbleChartView,
        TopicViewContent,
    },
})
export default class DetailedTopicStatsModal extends Vue {
    @Prop() private eventId: number;
    @Prop() private topicId: number;
    @Prop() private topic: Topic;
    @Prop() private position: GridElementPosition;

    private toggleModal() {
        this.$emit('toggleDetailedTopicStatsModal');
    }
}
