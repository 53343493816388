<template>
    <div id="echart">
        <IEcharts
            :loading="chart_loading"
            class="echartPunchCard"
            :option="options"
            :resizable="resizable"
            :auto-resize="autoresize"
        ></IEcharts>
    </div>
</template>

<script type="text/babel">
import IEcharts from 'vue-echarts-v3/src/full.js';
import axios from 'axios';
import Shepherd from 'shepherd.js';
import { mapGetters } from 'vuex';
import store from '@/vuex/modules/account/store';

export default {
    name: 'BubbleChartView',
    components: {
        IEcharts,
    },
    mounted() {
        this.load();
        if (this.currentUser.show_tour) {
            this.startTour();
        }
    },
    props: {
        eventId: Number,
        topicId: Number,
    },
    data() {
        return {
            tour: new Shepherd.Tour({
                useModalOverlay: true,
                defaultStepOptions: {
                    classes: 'shadow-md bg-purple-dark',
                    scrollTo: true,
                },
                exitOnEsc: true,
            }),
            chart_data: null,
            resizable: true,
            autoresize: true,
            chart_loading: true,
            options: {},
            punchCardOption: {
                legend: {
                    data: [''],
                    left: 'right',
                },
                tooltip: {
                    position: 'inside',
                    formatter: function(params) {
                        // return  params.value[0]+ '<br/>'+ Grade Number
                        //         params.value[1]+ '<br/>'+ Question Number
                        //         params.value[2]+ '<br/>'+ Scale of the circle
                        //         params.value[3]+ '<br/>'+ Number of Users
                        //         params.value[4]+ '<br/>'+ Avg of grades
                        //         params.value[5]+ '<br/>'; Users[[firstname,lastname,grade],[...]] in this rounded grade group
                        var tooltip =
                            'Question ' +
                            (params.value[1] + 1) +
                            ': ' +
                            params.value[6] +
                            '</br>Avg is : ' +
                            params.value[4] +
                            ' of ' +
                            params.value[3] +
                            ' Users';

                        // Add users with their grade to tooltip
                        params.value[5].forEach(function(object, index) {
                            tooltip += '</br>' + object[0] + ' ' + object[1] + ' : ' + object[2];
                            // tooltip += '</br>' + object[0] + (object[1] !== undefined ? object[1] : '') +' : ' + object[2] ;
                        });

                        return tooltip;
                    },
                },
                grid: {
                    left: 2,
                    bottom: 10,
                    right: 10,
                    containLabel: true,
                },
                xAxis: {
                    type: 'category',
                    data: ['0', '1', '1.5', '2', '2.5', '3', '3.5', '4', '4.5', '5', '5.5', '6', ''], // Grade
                    boundaryGap: false,
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: '#999',
                            type: 'dashed',
                        },
                    },
                    axisLine: {
                        show: false,
                    },
                },
                yAxis: {
                    type: 'category',
                    data: [], // Questions Titles
                    axisLine: {
                        show: false,
                    },
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: '#999',
                            type: 'dashed',
                        },
                    },
                },
                series: [
                    {
                        name: '',
                        type: 'scatter',
                        symbolSize: function(val) {
                            return val[2] * 2;
                        },
                        data: [], // Avg Users Grade Grouped togather
                        animationDelay: function(idx) {
                            return idx * 5;
                        },
                    },
                ],
            },
        };
    },
    computed: {
        // mixes the getters into computed with object spread operator
        ...mapGetters({
            currentUser: 'account/currentUser',
        }),
    },
    methods: {
        load() {
            var self = this;

            axios
                .get('/charts/usersByEventAndTopic', {
                    params: {
                        event_id: self.eventId,
                        topic_id: self.topicId,
                    },
                })
                .then(function(response) {
                    self.chart_data = response.data;

                    var sampleData = [];

                    // Add row titles
                    var questions_title = [];
                    var questionIndex = 0;

                    self.chart_data.questions.forEach(function(object, index) {
                        questions_title = questions_title.concat(object.title);
                        questionIndex += 1;
                    });

                    // Initialization of all the sample data
                    for (var i = 0; i < questionIndex; i++) {
                        sampleData[i] = [];
                        for (var j = 0; j <= 6; j = j + 0.5) {
                            sampleData[i][j + ''] = {
                                sum_of_grades: 0,
                                grade_number: 0,
                                number_of_users: 0,
                                users: [],
                                question_title: '',
                            };
                        }
                    }

                    // Iterate over all users and their questions, round their grades and count how many times which grade has occured
                    for (var user in self.chart_data.evaluations) {
                        var index = 0;
                        for (var question in self.chart_data.evaluations[user]) {
                            if (sampleData[index] != undefined) {
                                // Round by 0.5
                                var rounded_grade =
                                    Math.round(self.chart_data.evaluations[user][question].grade * 2) / 2;

                                var grade = self.chart_data.evaluations[user][question].grade;

                                var rounded_grade_key = rounded_grade + '';

                                sampleData[index][rounded_grade_key].sum_of_grades += grade;
                                sampleData[index][rounded_grade_key].grade_number = grade;
                                sampleData[index][rounded_grade_key].number_of_users += 1;

                                // Add the username and grade to users array for displaying in tooltip per each rounded grade group
                                sampleData[index][rounded_grade_key].users.push([
                                    self.chart_data.evaluations[user][question][0].user_firstname,
                                    self.chart_data.evaluations[user][question][1].user_lastname,
                                    grade,
                                ]);
                                if (self.chart_data.evaluations[user][question].question !== undefined) {
                                    sampleData[index][rounded_grade_key].question_title =
                                        self.chart_data.evaluations[user][question].question.title;
                                }
                                index += 1;
                            }
                        }
                    }

                    // The scale factor should never be bigger than the max scale (it is dependend on the amount of users)
                    var maxScale = 30;
                    var scale = maxScale / Object.keys(self.chart_data.evaluations).length;

                    for (var questionIndex in sampleData) {
                        for (var grade in sampleData[questionIndex]) {
                            self.punchCardOption.series[0].data.push([
                                grade,
                                parseInt(questionIndex),
                                sampleData[questionIndex][grade].number_of_users * scale != 0
                                    ? sampleData[questionIndex][grade].number_of_users * scale + 5
                                    : 0,
                                sampleData[questionIndex][grade].number_of_users,
                                (
                                    sampleData[questionIndex][grade].sum_of_grades /
                                    sampleData[questionIndex][grade].number_of_users
                                ).toFixed(2),
                                sampleData[questionIndex][grade].users,
                                sampleData[questionIndex][grade].question_title,
                            ]);
                        }
                    }
                    self.punchCardOption.yAxis.data = questions_title;
                    self.options = self.punchCardOption;
                    self.chart_loading = false;
                })
                .catch(function(error) {
                    console.log(error);
                });
        },
        startTour() {
            this.tour.addStep({
                title: 'Register Bubble Chart',
                text: 'Das Bubble Chart zeigt dir eine grafische Darstellung der Selbsteinschätzungen.',
                buttons: [
                    {
                        action() {
                            return this.complete();
                        },
                        classes: 'button is-primary',
                        text: 'OK',
                    },
                    {
                        action() {
                            return this.cancel();
                        },
                        classes: 'button is-primary',
                        text: 'Tour überspringen',
                    },
                ],
                id: 'creating',
            });
            this.tour.start();
        },
    },
};
</script>

<style scoped>
.echartPunchCard {
    width: auto;
}

#echart {
    height: 700px;
}
</style>
