<template>
<div class="columns">
    <div class="column is-12">

        <svg class="graph" v-bind="graphConfig">

            <!-- Text Current Value
            <text v-bind="textValConfig">{{getRoundedValue}}</text>
            -->

            <!-- Text Name
            <text v-bind="textNameConfig">{{model.name}}</text>
            -->

            <!-- Rounded slider background -->
            <rect v-bind="sliderConfig"/>

            <!-- Goal line in the middle -->
            <line v-bind="lineConfig" />

            <!-- Rect left (negative red) -->
            <rect v-bind="rectLeftConfig" />

            <!-- Rect right (positive green) -->
            <rect v-bind="rectRightConfig" />
        </svg>
    </div>
</div>
</template>

<script>

// Define the min and max grade
const minGrade = 1
const maxGrade = 6

// Define the width and height of the component
const width = 200
const height = 30

const heightSlider = 10;
const radiusCircle = 12;

const lineHeight = 5;

const margin = 0;

export default {
    props: {
        goal: {
            default: 4,
            type: Number,
        },

        value: {
            default: 4,
            type: Number,
        },

        name: {
            default: '',
            type: String,
        }
    },

    mounted() {
        // Set the params to the model
        this.model.goal = this.goal
        this.model.value = this.value

        this.initialize()
    },

    data: function() {
    	return {
            // Model for internal use
            model: {
                goal: 4,
                value: 4,
                name: '',
            },

            // Defines the size of the SVG
            graphConfig: {
                width: width,
                height: height,
            },

            // The current value is displayed on top
            textValConfig: {
                x:0,
                y:height/2 + 5,
                style: {
                    'fill': 'black',
                    'font-family': 'Avenir Next',
                    'font-size': 20,
                    'font-weight': 700,
                }
            },

            // The goal is defined on the bottom
            textNameConfig: {
                x:width/2 - 40,
                y:height - 20,
                style: {
                    fill:'black'
                }
            },

            // Line which is in the middle where the goal is
            lineConfig: {
                x1: width/2 + margin/2,
                y1: height/2 - lineHeight,
                x2: width/2 + margin/2,
                y2:height/2 + lineHeight,
                style: {
                    'stroke': 'black',
                    'stroke-width': 2,
                }
            },

            // The background slider like rounded rectangle
            sliderConfig: {
                x: 0 + margin,
                y:height/2 - heightSlider/2,
                rx:5,
                ry:5,
                width: width - margin,
                height: heightSlider,
                style: {
                    'fill': 'lightgray',
                    'stroke': 'black',
                    'stroke-width':2,
                    'opacity':1.0
                }
            },

            // The left rectangle which is shown when the value is underneath the goa (red)
            rectLeftConfig: {
                x: width/2 + margin/2,
                y: height/2 - heightSlider/2,
                width: 0 + margin,
                height: heightSlider,
                style: {
                    'fill': 'red',
                    'opacity': 0.4,
                }
            },

            // The right rectangle which is shown when the value is over the goal (green)
            rectRightConfig: {
                x: width/2 + margin/2,
                y: height/2 - heightSlider/2,
                width: 0 + margin,
                height: heightSlider,
                style: {
                    'fill': 'green',
                    'opacity': 0.4,
                }
            }
        }
    },

    watch: {
        goal: function(val) {
            // The goal/value needs to be updated in the model
            this.model.goal = this.goal

            // When the model is changed we need to initialize
            this.initialize();
        },

        value: function(val) {
            // The goal/value needs to be updated in the model
            this.model.value = this.value

            // If there is not yet a value, use the goal
            if (this.value == null) {
                this.model.value = this.model.goal
            }

            // When the model is changed we need to initialize
            this.initialize();
        }
    },

    computed: {
        getRoundedValue() {
            // Round and set to fixed one digit
            return Number((Math.round(this.model.value * 10) / 10).toFixed(1));
        },
    },

    methods: {

        resetConfigs() {
            // Reset all changing values
            this.rectRightConfig.width = 0
            this.rectLeftConfig.width = 0
        },

        initialize() {

            // Set the rounded value in the beginning to the value
            this.model.roundedValue = this.model.value

            // Reset all values and position the sliders back to default
            this.resetConfigs()

            // Check if the value is different than the goal
            if (this.model.value != this.model.goal) {
                if (this.model.value > this.model.goal) {
                    var spectrumAbove = (maxGrade - this.model.goal)
                    var pixelPerGrade = (width/2) / spectrumAbove
                    var pixelShift = pixelPerGrade * (this.model.value - this.model.goal)

                    this.rectRightConfig.width = pixelShift + margin/2
                } else {
                    var spectrumUnder = (this.model.goal - minGrade)
                    var pixelPerGrade = (width/2) / spectrumUnder
                    var pixelShift = pixelPerGrade * (this.model.goal - this.model.value)

                    this.rectLeftConfig.width = pixelShift
                    this.rectLeftConfig.x = ((width/2) - (pixelShift - margin/2))
                }
            }
        },

        calculateValueVisualize(newPt) {
            const rectLeftConfig = this.rectLeftConfig
            const rectRightConfig = this.rectRightConfig

            if (this.model.value >= this.model.goal) {

                var rectWidth = newPt.x - (width/2);

                // The rect width needs to be positive
                if (rectWidth >= 0) {
                    rectRightConfig.width = rectWidth
                }

                // Additionally reset the width of the opposite rect (When the mouse moves really fast it sometimes did not set it back to 0)
                rectLeftConfig.width = 0

                // Diff between the goal and the top
                var spectrumAbove = maxGrade - this.model.goal

                var gradePerPixel = spectrumAbove / (width/2)
                var pixelAboveGoal = newPt.x - (width/2)
                var gradeAddition = pixelAboveGoal * gradePerPixel

                this.model.value = this.model.goal + gradeAddition

                // Send the changed value back to the parent
                this.$emit('valueChanged', this.getRoundedValue)
            } else if(this.model.value < this.model.goal) {

                var rectWidth = (width/2) - newPt.x

                // The rect width needs to be positive
                if (rectWidth >= 0) {
                    rectLeftConfig.width = rectWidth
                    rectLeftConfig.x = (width/2) - rectLeftConfig.width
                }

                // Additionally reset the width of the opposite rect (When the mouse moves really fast it sometimes did not set it back to 0)
                rectRightConfig.width = 0

                // Diff between the goal and the top
                var spectrumUnder = this.model.goal - minGrade

                var gradePerPixel = spectrumUnder / (width/2)
                var pixelUnderGoal = (width/2) - newPt.x
                var gradeReduction = pixelUnderGoal * gradePerPixel

                this.model.value = this.model.goal - gradeReduction

                // Send the changed value back to the parent
                this.$emit('valueChanged', this.getRoundedValue)
            }
        }
    },
}

function getMousePos(mouseEvent, point) {
	point.x = (mouseEvent.clientX)
	point.y = (mouseEvent.clientY)
}

function getTouchPos(touchEvent, point) {
	point.x = (touchEvent.touches[0].clientX)
	point.y = (touchEvent.touches[0].clientY)
}
</script>

<style scoped>

</style>
