













import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';

@Component({
   name: 'EmptyListMsg',
})
export default class EmptyListMsg extends Vue {
}
