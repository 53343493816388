
























































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import moment from 'moment';
import { Module } from '@/models/module';
import { Event } from '@/models/event';
import { moduleWebservice } from '@/webservices/module';
import { eventWebservice } from '@/webservices/event';

import DetailedConfirmModal from '@/components/prine/DetailedConfirmModal.vue';
import Shepherd from 'shepherd.js';
import { User } from '@/models/user';
import { userWebservice } from '@/webservices/user';

@Component({
    name: 'ActiveModuleList',
    components: {
        DetailedConfirmModal,
    },
})
export default class ActiveModuleList extends Vue {
    @Prop() private modules: Module[];

    @Action('module/fetchActiveModules')
    private fetchActiveModules: any;

    @Action('module/fetchFinishedModules')
    private fetchFinishedModules: any;

    @Getter('account/currentUser') private currentUser: User;

    private buefyTableKey: number = 0;
    private isLoading: boolean = false;
    private affectedData: Map<string, number> = new Map();
    private isConfirmModalActive: boolean = false;
    private isModuleCoverUploadModalActive: boolean = false;

    private selectedModule: Module = {} as Module;

    private events = [] as Event[];
    private tableSettings = {
        perPage: 15,
        isStriped: true,
        isHoverable: true,
        isPaginated: false,
    };

    private tour = new Shepherd.Tour({
        useModalOverlay: true,
        defaultStepOptions: {
            classes: 'shadow-md bg-purple-dark',
            scrollTo: true,
        },
        exitOnEsc: true,
    });

    private mounted() {
        if (this.currentUser.show_tour) {
            this.startTour(this);
        }
    }

    private async reload() {
        this.isLoading = true;
        await this.reloadModules();
        this.buefyTableKey += 1;
        this.isLoading = false;
    }

    private async reloadModules() {
        await this.fetchFinishedModules();
        await this.fetchActiveModules();
    }

    private async publish(module: Module) {
        await moduleWebservice.publish(module);
        this.reload();
    }

    private async toggleConfirmModal(answer: boolean) {
        if (answer === true) {
            await moduleWebservice.remove(this.selectedModule);
            this.isConfirmModalActive = !this.isConfirmModalActive;
            this.reload();
        } else {
            this.isConfirmModalActive = !this.isConfirmModalActive;
        }
    }

    private activeUsersByEventId(event_id: number) {
        const event = this.events.find((ob) => ob.id === event_id);
        if (event) {
            return event.group.active_users.length;
        }
    }

    private toggleFinished(module: Module) {
        moduleWebservice.toggleFinished(module).then((res) => {
            this.reload();
        });
    }

    private showConfirmModal(module: Module) {
        this.selectedModule = module;
        this.isConfirmModalActive = true;
    }

    private startTour(component: ActiveModuleList) {
        this.tour.addStep({
            title: 'Listenansicht',
            text: 'Dies ist die Listenansicht der aktiven Module mit den aktiven Klassen.',
            attachTo: {
                element: '.table',
                on: 'top',
            },
            canClickTarget: false,
            buttons: [
                {
                    action() {
                        return this.next();
                    },
                    classes: 'button is-primary',
                    text: 'Next',
                },
                {
                    action() {
                        cancelTour();
                        return this.cancel();
                    },
                    classes: 'button is-primary',
                    text: 'Tour überspringen',
                },
            ],
            id: 'creating',
        });
        this.tour.addStep({
            title: 'Module abschliessen',
            text: 'Damit die Ansicht übersichtlich bleibt, können Module auf "abgeschlossen" gesetzt werden.',
            attachTo: {
                element: '.activeColumn',
                on: 'bottom',
            },
            canClickTarget: false,
            buttons: [
                {
                    action() {
                        return this.back();
                    },
                    classes: 'button is-primary',
                    text: 'Back',
                },
                {
                    action() {
                        return this.next();
                    },
                    classes: 'button is-primary',
                    text: 'Next',
                },
                {
                    action() {
                        cancelTour();
                        return this.cancel();
                    },
                    classes: 'button is-primary',
                    text: 'Tour überspringen',
                },
            ],
            id: 'creating',
        });
        this.tour.addStep({
            title: 'Aktive und abgeschlossene Module',
            text: 'Hier kannst du auswählen ob die abgeschlossenen Module auch angezeigt werden sollen.',
            attachTo: {
                element: '.block',
                on: 'bottom',
            },
            canClickTarget: false,
            buttons: [
                {
                    action() {
                        return this.back();
                    },
                    classes: 'button is-primary',
                    text: 'Back',
                },
                {
                    action() {
                        return this.next();
                    },
                    classes: 'button is-primary',
                    text: 'Next',
                },
                {
                    action() {
                        cancelTour();
                        return this.cancel();
                    },
                    classes: 'button is-primary',
                    text: 'Tour überspringen',
                },
            ],
            id: 'creating',
        });
        this.tour.addStep({
            title: 'Klassen-Code',
            text:
                'Jede Klasse hat einen Klassen-Code. Dieser Code muss den Lernenden mitgeteilt werden, damit sie sich in dieser Klasse auch anmelden können.',
            attachTo: {
                element: '.codeColumn',
                on: 'bottom',
            },
            canClickTarget: false,
            buttons: [
                {
                    action() {
                        return this.back();
                    },
                    classes: 'button is-primary',
                    text: 'Back',
                },
                {
                    action() {
                        return this.next();
                    },
                    classes: 'button is-primary',
                    text: 'Next',
                },
                {
                    action() {
                        cancelTour();
                        return this.cancel();
                    },
                    classes: 'button is-primary',
                    text: 'Tour überspringen',
                },
            ],
            id: 'creating',
        });
        this.tour.addStep({
            title: 'Weiterführung der Tour',
            text: 'Um die Tour weiterzuführen, klicke ein Modul in deiner Liste an.',
            canClickTarget: false,
            buttons: [
                {
                    action() {
                        return this.back();
                    },
                    classes: 'button is-primary',
                    text: 'Back',
                },
                {
                    action() {
                        return this.complete();
                    },
                    classes: 'button is-primary',
                    text: 'Tour-Schritt beenden',
                },
            ],
            id: 'creating',
        });
        this.tour.start();

        async function cancelTour() {
            await userWebservice.changeTour(false);
            await userWebservice.getUserInfo().then((response) => {
                component.$store.commit('account/setCurrentUser', response);
            });
            // Show a snackbar message that tour can be enabled in the settings
            component.$buefy.snackbar.open({
                duration: 7000,
                message: `Du hast die Tour abgebrochen. Sie wird dir nicht mehr angezeigt. Falls du sie wieder aktivieren möchtest, kannst du das in den Einstellungen vornehmen.`,
                type: 'is-primary',
                queue: false,
                position: 'is-top',
            });
        }
    }
}
