











































































import axios from 'axios';
import router from 'vue-router';
import { User } from '../../models/user';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { userWebservice } from '../../webservices/user';
@Component({
    name: 'ConfirmEmail',
})
export default class ConfirmEmail extends Vue {
    // Data
    private disabled: boolean = false;
    private resendingToken: boolean = false;
    private errorMsg: string = '';
    private serverErrorMsg: string = '';
    private emailConfirmationToken: string = '';
    private emailConfirmed: boolean = false;

    @Getter('account/loggedIn') private loggedIn: boolean;
    @Getter('account/isEmailConfirmed') private isEmailConfirmed: boolean;

    // Mounted is called initially
    private mounted() {
        // console.log('ConfirmEmail mounted');
        if (this.$route.query.hasOwnProperty('email_confirmation_token')) {
            this.emailConfirmationToken = this.$route.query.email_confirmation_token.toString();
        }
        if (this.emailConfirmationToken !== '') {
            setTimeout(() => {
                this.confirmEmail();
            }, 1500);
        }
    }

    // Methods
    private confirmEmail() {
        if (this.emailConfirmationToken === '') {
            this.errorMsg = 'token';
            return 0;
        }

        const self = this;

        userWebservice
            .confirmEmail(this.emailConfirmationToken)
            .then((response) => {
                self.errorMsg = '';
                self.emailConfirmed = true;
                setTimeout(() => {
                    if (self.loggedIn) {
                        userWebservice.getUserInfo().then((userWebserviceResponse) => {
                            self.$store.commit('account/setCurrentUser', userWebserviceResponse);
                            self.$router.push({ path: '/' });
                        });
                    } else {
                        self.$router.push({ path: '/' });
                    }
                }, 2500);
            })
            .catch((error) => {
                self.serverErrorMsg = 'Der Token sind nicht korrekt';
                console.log(error);
            });
    }

    private resendConfirmationToken() {
        const self = this;
        self.resendingToken = true;
        userWebservice
            .resendEmailConfirmationToken()
            .then((response) => {
                self.resendingToken = false;
            })
            .catch(function(error) {
                console.log(error);
            });
    }
}
