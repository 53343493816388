






























































































































































































































































































































































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import { Question } from '@/models/question';
import { Topic } from '@/models/topic';
import { Solution } from '@/models/solution';
import { Block } from '@/models/block';
import { FormState } from '@/components/prine/enums/FormState';
import * as R from 'ramda';
import { uploadFileWebservice } from '@/webservices/uploadFile';
import HttpException from '@/webservices/HttpException';

interface HTMLInputEvent extends Event {
    target: HTMLInputElement & EventTarget;
}

@Component({
    name: 'QuestionManager',
})
export default class QuestionManager extends Vue {
    public FormState = FormState;

    @Prop() private state: FormState;
    @Prop() private block: Block;

    @Prop() private value: Question[];

    private errorMsg: string = '';
    private disabled: boolean = false;

    private uploading: boolean = false;

    private questions: Question[] = new Array<Question>();
    private selectedQuestion: Question = {} as Question;

    @Watch('value')
    private onValueChange(value: Question[]) {
        this.questions = value;
        console.log('onValueChange fired');
    }

    @Watch('questions')
    private onTopicDataChange(value: Question[]) {
        this.$emit('input', value);
        console.log('onTopicDataChange fired');
    }

    private onSolutionFileChange(e: HTMLInputEvent) {
        if (e.target && e.target.files) {
            const file: File = e.target.files[0];

            if (file != null && file.size > 20971520) {
                this.$buefy.snackbar.open({
                    duration: 3000,
                    message: `Maximale Dateigrösse 20 MB`,
                    type: 'is-danger',
                    queue: true,
                    position: 'is-top',
                });
                return false;
            }
            this.uploadSolution(file);
        }
    }

    private onLinkFileChange(e: HTMLInputEvent) {
        if (e.target && e.target.files) {
            const file: File = e.target.files[0];

            if (file != null && file.size > 20971520) {
                this.$buefy.snackbar.open({
                    duration: 3000,
                    message: `Maximale Dateigrösse 20 MB`,
                    type: 'is-danger',
                    queue: true,
                    position: 'is-top',
                });
                return false;
            }

            this.uploadLink(file);
        }
    }

    private onSourceSolutionFileChange(e: HTMLInputEvent) {
        if (e.target && e.target.files) {
            const file: File = e.target.files[0];

            if (file != null && file.size > 209715200) {
                this.$buefy.snackbar.open({
                    duration: 3000,
                    message: `Maximale Dateigrösse 200 MB`,
                    type: 'is-danger',
                    queue: true,
                    position: 'is-top',
                });
                return false;
            }
            this.uploadSourceSolution(file);
        }
    }

    private onSourceQuestionFileChange(e: HTMLInputEvent) {
        if (e.target && e.target.files) {
            const file: File = e.target.files[0];

            if (file != null && file.size > 209715200) {
                this.$buefy.snackbar.open({
                    duration: 3000,
                    message: `Maximale Dateigrösse 200 MB`,
                    type: 'is-danger',
                    queue: true,
                    position: 'is-top',
                });
                return false;
            }
            this.uploadSourceQuestion(file);
        }
    }

    private mounted() {
        Vue.nextTick(() => {
            if (this.state === FormState.New) {
                this.newQuestions(4);
                console.log('newQuestions');
            }
        });
    }

    private moveUp(question: Question, index: number) {
        console.log(question.order);
        if (question.order > 1) {
            this.swap(question, index, 'up');
        } else {
            return false;
        }
    }

    private moveDown(question: Question, index: number) {
        console.log(question.order);
        const maxOrderNumber = this.questions.length;
        if (question.order < maxOrderNumber) {
            this.swap(question, index, 'down');
        } else {
            return false;
        }
    }

    private swap(requestedQuestion: Question, index: number, direction: string) {
        if (direction === 'up') {
            if (typeof this.questions[index - 1] !== 'undefined') {
                const temp = this.questions[index - 1].order;
                console.log(this.questions[index - 1].title);
                this.questions[index - 1].order = requestedQuestion.order;
                this.questions[index].order = temp;
            }
        } else if (direction === 'down') {
            if (typeof this.questions[index + 1] !== 'undefined') {
                const temp = this.questions[index + 1].order;
                this.questions[index + 1].order = requestedQuestion.order;
                this.questions[index].order = temp;
            }
        }

        this.questions.sort(function(b, a) {
            return b.order - a.order;
        });
    }

    private newQuestions(amount: number = 1) {
        console.log('newQuestions');
        // finding highest order and  highest id number in questions

        let highestOrder: number = 1;
        let highestId: number | null = 1;

        if (this.questions.length >= 1) {
            highestOrder = this.questions.reduce((a, b) => {
                return a.order > b.order ? a : b;
            }).order;

            highestId = this.questions.reduce((a, b) => {
                return a.id! > b.id! ? a : b;
            }).id;
        }
        if (highestId === null) {
            highestId = 1;
        }

        for (let i = 1; i <= amount; i++) {
            this.questions.push({
                title: '',
                description: '',
                link: '',
                is_local_link: false,
                has_solution: false,
                source_question: '',
                source_solution: '',
                solution: {
                    id: 0,
                    content: '',
                    content_type_id: 2,
                },
                solution_id: 0,
                order: highestOrder + i,
                id: highestId + i,
                topics: [] as Topic[],
            });
        }
    }

    private deleteQuestion(requestedQuestion: Question) {
        // check if the question has created_at field
        // emit questionDeleted
        if (requestedQuestion.hasOwnProperty('created_at')) {
            this.$emit('questionDeleted');
        }
        this.questions = this.questions.filter((question) => {
            return requestedQuestion.id !== question.id;
        });
    }

    private emptySolution(question: Question) {
        question.solution.content = '';
    }

    private emptyLink(question: Question) {
        question.link = '';
        question.is_local_link = false;
    }

    private addSolutionToQuestion(question: Question) {
        if (question.title === '') {
            return;
        }
        question.has_solution = true;
        question.solution = {
            id: 0,
            content: '',
            content_type_id: 2,
        } as Solution;
    }

    private openUploadLink(question: Question) {
        if (question.title === '') {
            return;
        }
        this.selectedQuestion = question;
        this.openLinkFileDialog();
    }

    private openUploadSolution(question: Question) {
        if (question.title === '') {
            return;
        }
        this.selectedQuestion = question;
        this.openSolutionFileDialog();
    }

    private openUploadSourceSolution(question: Question) {
        if (question.title === '') {
            return;
        }
        this.selectedQuestion = question;
        this.openSourceSolutionFileDialog();
    }

    private openUploadSourceQuestion(question: Question) {
        if (question.title === '') {
            return;
        }
        this.selectedQuestion = question;
        this.openSourceQuestionFileDialog();
    }

    private openLinkFileDialog() {
        const fileInput = document.getElementById('link-file-input') as HTMLInputElement;
        if (fileInput) {
            fileInput.type = 'text';
            fileInput.value = '';
            fileInput.type = 'file';
            fileInput.click();
        }
    }

    private openSolutionFileDialog() {
        const fileInput = document.getElementById('solution-file-input') as HTMLInputElement;
        if (fileInput) {
            fileInput.type = 'text';
            fileInput.value = '';
            fileInput.type = 'file';
            fileInput.click();
        }
    }

    private openSourceSolutionFileDialog() {
        const fileInput = document.getElementById('source-solution-file-input') as HTMLInputElement;
        if (fileInput) {
            fileInput.type = 'text';
            fileInput.value = '';
            fileInput.type = 'file';
            fileInput.click();
        }
    }

    private openSourceQuestionFileDialog() {
        const fileInput = document.getElementById('source-question-file-input') as HTMLInputElement;
        if (fileInput) {
            fileInput.type = 'text';
            fileInput.value = '';
            fileInput.type = 'file';
            fileInput.click();
        }
    }

    private async uploadLink(file: File) {
        const question = this.questions.find((q) => q.id === this.selectedQuestion.id);
        if (!R.isNil(question)) {
            try {
                const res = await this.uploadFile(file);
                question.link = res.data;
                question.is_local_link = true;
            } catch (error) {
                throw new HttpException(error.response.status, 'There was an error fetching the module by an id');
            }
        }
    }

    private async uploadSolution(file: File) {
        const question = this.questions.find((q) => q.id === this.selectedQuestion.id);
        if (!R.isNil(question)) {
            try {
                const res = await this.uploadFile(file);
                question.solution.content = res.data;
            } catch (error) {
                throw new HttpException(error.response.status, 'There was an error fetching the module by an id');
            }
        }
    }

    private async uploadSourceSolution(file: File) {
        const question = this.questions.find((q) => q.id === this.selectedQuestion.id);
        if (!R.isNil(question)) {
            try {
                const res = await this.uploadSourceFile(file, 'SourceSolution');
                question.source_solution = res.data;
            } catch (error) {
                throw new HttpException(error.response.status, 'There was an error fetching the module by an id');
            }
        }
    }

    private async uploadSourceQuestion(file: File) {
        const question = this.questions.find((q) => q.id === this.selectedQuestion.id);
        if (!R.isNil(question)) {
            try {
                const res = await this.uploadSourceFile(file, 'SourceQuestion');
                question.source_question = res.data;
            } catch (error) {
                throw new HttpException(error.response.status, 'There was an error fetching the module by an id');
            }
        }
    }

    private async uploadFile(file: File) {
        this.uploading = true;
        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await uploadFileWebservice.upload(formData);
            this.$buefy.snackbar.open({
                duration: 3000,
                message: `Success`,
                type: 'is-primary',
                queue: true,
                position: 'is-top',
            });
            this.uploading = false;
            return response;
        } catch (error) {
            this.$buefy.snackbar.open({
                duration: 3000,
                message: `Error`,
                type: 'is-danger',
                queue: true,
                position: 'is-top',
            });
            this.uploading = false;
            throw new HttpException(error.response.status, 'There was an error fetching the module by an id');
        }
    }

    private async uploadSourceFile(file: File, sourceType: string) {
        this.uploading = true;
        const formData = new FormData();
        formData.append('file', file);
        if (this.block.module_id !== null) {
            formData.append('module_id', this.block.module_id.toString());
        }
        if (this.block.id !== null) {
            formData.append('block_id', this.block.id.toString());
        }

        formData.append('type', sourceType);

        try {
            const response = await uploadFileWebservice.uploadSource(formData);
            this.$buefy.snackbar.open({
                duration: 3000,
                message: `Success`,
                type: 'is-primary',
                queue: true,
                position: 'is-top',
            });
            this.uploading = false;
            return response;
        } catch (error) {
            this.$buefy.snackbar.open({
                duration: 3000,
                message: `Error`,
                type: 'is-danger',
                queue: true,
                position: 'is-top',
            });
            this.uploading = false;
            throw new HttpException(error.response.status, 'There was an error fetching the module by an id');
        }
    }

    private getSolutionFileNameFromPath(fullPath: string) {
        if (!R.isNil(fullPath)) {
            const fileName = fullPath.replace(/^.*[\\\/]/, '');
            return fileName.substr(20, fileName.length - 31);
        } else {
            return '';
        }
    }

    private getFileNameFromPath(fullPath: string | null) {
        if (!R.isNil(fullPath)) {
            const filename: string = fullPath.substring(fullPath.lastIndexOf('/') + 1);
            return filename;
        } else {
            return '';
        }
    }

    private webLinkQuestion(question: Question) {
        // console.log(question);
        let link = '';
        if (question.is_local_link) {
            link = process.env.VUE_APP_BACKEND_BASE_URL + question.link;
        } else {
            link = this.addhttp(question.link);
        }
        return link;
    }

    private webLinkSolution(solution: Solution) {
        let link = '';
        if (solution.content_type_id === 2) {
            link = process.env.VUE_APP_BACKEND_BASE_URL + solution.content;
        } else if (solution.content_type_id === 3) {
            link = this.addhttp(solution.content);
        }
        return link;
    }

    private isURL(str: string) {
        const pattern = new RegExp(
            '(https?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
                '(\\#[-a-z\\d_]*)?$',
            'i'
        ); // fragment locator
        return !!pattern.test(str);
    }

    private addhttp(link: string) {
        if (this.isURL(link)) {
            if (!/^(f|ht)tps?:\/\//i.test(link)) {
                link = 'http://' + link;
            }
        }
        return link;
    }
}
