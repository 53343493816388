import axios from 'axios';
import { PiquestSubject } from '@/models/piquestSubject';
import { SchoolLevel } from '@/models/schoollevel';
import { Schooltype } from '@/models/schooltype';
import { Language } from '@/models/language';
import { Country } from '@/models/country';
import { ModulePrice } from '@/models/modulePrice';
import { ModuleMetaData } from '@/models/moduleMetaData';

import { Tag } from '@/models/tag';

import HttpException from './HttpException';

class ModuleMetadataWebservice {
    public async getMetadataById(id: number) {
        try {
            const response = await axios.get<ModuleMetaData>('/modules-metadatas/byId', {
                params: {
                    id,
                },
            });

            return response.data;
        } catch (error) {
            throw new HttpException(error.response.status, 'There was an error fetching the module metadata');
        }
    }

    public async updateMetadata(moduleMetadata: ModuleMetaData) {
        try {
            const metadata = {} as ModuleMetaData;
            metadata.id = moduleMetadata.id;
            metadata.description = moduleMetadata.description;
            metadata.crystals = moduleMetadata.crystals;
            metadata.piquest_subject_id = moduleMetadata.piquest_subject_id;
            metadata.schoollevel_id = moduleMetadata.schoollevel_id;
            metadata.schooltype_id = moduleMetadata.schooltype_id;
            metadata.language_id = moduleMetadata.language_id;
            metadata.country_id = moduleMetadata.country_id;
            metadata.tag_ids = moduleMetadata.tags.map((tag: any) => tag.id);

            const response = await axios.post<ModuleMetaData>('/modules-metadatas/update', {
                metadata,
            });

            return response.data;
        } catch (error) {
            throw new HttpException(error.response.status, 'There was an error updating the module metadata');
        }
    }

    public async tags() {
        try {
            const response = await axios.get<Tag[]>('/modules-metadatas/tags');
            return response.data;
        } catch (error) {
            throw new HttpException(error.response.status, 'There was an error fetching tags');
        }
    }

    public async piquestSubjects() {
        try {
            const response = await axios.get<PiquestSubject[]>('/modules-metadatas/piquestSubjects');
            return response.data;
        } catch (error) {
            throw new HttpException(error.response.status, 'There was an error fetching piquestSubjects');
        }
    }

    public async schoollevels() {
        try {
            const response = await axios.get<SchoolLevel[]>('/modules-metadatas/schoollevels');
            return response.data;
        } catch (error) {
            throw new HttpException(error.response.status, 'There was an error fetching schoollevels');
        }
    }

    public async schooltypes() {
        try {
            const response = await axios.get<Schooltype[]>('/modules-metadatas/schooltypes');
            return response.data;
        } catch (error) {
            throw new HttpException(error.response.status, 'There was an error fetching schooltypes');
        }
    }

    public async languages() {
        try {
            const response = await axios.get<Language[]>('/modules-metadatas/languages');
            return response.data;
        } catch (error) {
            throw new HttpException(error.response.status, 'There was an error fetching languages');
        }
    }

    public async countries() {
        try {
            const response = await axios.get<Country[]>('/modules-metadatas/countries');
            return response.data;
        } catch (error) {
            throw new HttpException(error.response.status, 'There was an error fetching countries');
        }
    }

    public async prices() {
        try {
            const response = await axios.get<ModulePrice[]>('/modules-metadatas/prices');
            return response.data;
        } catch (error) {
            throw new HttpException(error.response.status, 'There was an error fetching prices');
        }
    }
}

export const moduleMetadataWebservice = new ModuleMetadataWebservice();
