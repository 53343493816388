
















































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import { User } from '@/models/user';
import { userWebservice } from '@/webservices/user';

@Component({
    name: 'AccountDropdown',
})
export default class AccountDropdown extends Vue {
    @Getter('account/loggedIn') private loggedIn: boolean;
    @Getter('account/currentUser') private currentUser: User;

    private routeTo(url: string) {
        this.$router.push(url);
    }

    private activateTour() {
        this.$buefy.dialog.confirm({
            title: 'piQuest-Tour',
            message: 'Willst du die <b>piQuest-Tour</b> aktivieren und starten?',
            confirmText: 'Aktivieren',
            type: 'is-primary',
            hasIcon: true,
            onConfirm: () => this.startTour(),
        });
    }

    private async startTour() {
        await userWebservice.changeTour(true);
        await userWebservice.getUserInfo().then((response) => {
            this.$store.commit('account/setCurrentUser', response);
        });
        if (this.$route.path !== ('/' || '/modules')) {
            this.routeTo('/');
            return;
        }
        // reload the page
        this.$router.go(0);
    }
}
