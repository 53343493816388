

































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import moment from 'moment';
import FeedContainer from '@/components/prine/feed/FeedContainer.vue';
import { feedbackWebservice } from '@/webservices/feedback';
import { FeedbackStudent } from '@/models/feedbackStudent';

@Component({
    name: 'ActivityFeedFeedback',
    components: {
        FeedContainer,
    },
})
export default class ActivityFeedFeedback extends Vue {
    private feedbacks: FeedbackStudent[] = [];
    private isLoading: boolean = false;
    private loadNewestFeedbacksInterval: NodeJS.Timeout;
    private take: number = 8;

    private mounted() {
        this.loadFeedbacks();

        this.loadNewestFeedbacksInterval = setInterval(async () => {
            this.loadFeedbacks();
        }, 60 * 1000); // Every minute
    }

    private destroyed() {
        clearInterval(this.loadNewestFeedbacksInterval);
    }

    private async loadMoreFeed() {
        this.take += this.take;
        this.loadFeedbacks();
    }

    private async loadFeedbacks() {
        this.isLoading = true;
        this.feedbacks = await feedbackWebservice.loadNewestStudentFeedbacks(this.take);
        this.isLoading = false;
    }

    private async toggleSolved(feedback_id: number) {
        this.isLoading = true;
        // Call webservice
        await feedbackWebservice.toggleSolved(feedback_id);
        this.loadFeedbacks();
    }

    private createEmail(element: FeedbackStudent) {
        if (element.question.topics[0].block && element.question.topics[0].block.module) {
            return (
                'mailto:' +
                element.user.email +
                '?subject=Vielen Dank für Dein Feedback zum PiQuest-Modul ' +
                element.question.topics[0].block.module.name +
                ' - Topic ' +
                element.question.topics[0].block.order +
                '.' +
                element.question.topics[0].order +
                '&body=Dein Feedback: ' +
                element.content +
                '%0d%0a %0d%0a' +
                'PS: piQuest verschickt sämtliche Korrespondenz in Du-Form.'
            );
        }
    }
}
