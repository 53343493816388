

























































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import moment from 'moment';
import { Module } from '@/models/module';
import { Event } from '@/models/event';
import { moduleWebservice } from '@/webservices/module';
import { eventWebservice } from '@/webservices/event';
import { ModuleStatusValues } from '@/models/moduleStatus';

import DetailedConfirmModal from '@/components/prine/DetailedConfirmModal.vue';
import ActivateModuleModal from '@/components/module/manager/ActivateModuleModal.vue';
import ShareModuleModal from '@/components/module/manager/ShareModuleModal.vue';
import PublishModuleModal from '@/components/module/manager/PublishModuleModal.vue';
import EditPublishMetadataModal from '@/components/module/manager/EditPublishMetadataModal.vue';
import OwnModuleDuplicateButton from '@/components/module/manager/tabs/own/OwnModuleDuplicateButton.vue';

@Component({
    name: 'OwnModuleList',
    components: {
        DetailedConfirmModal,
        ShareModuleModal,
        ActivateModuleModal,
        PublishModuleModal,
        EditPublishMetadataModal,
        OwnModuleDuplicateButton,
    },
})
export default class OwnModuleList extends Vue {
    @Prop() private modules: Module[];

    // private affectedData: Map<string, number> = new Map();
    private affectedData: Map<string, number | string[] | number[]> = new Map();

    private isConfirmModalActive: boolean = false;
    private isActivateModalActive: boolean = false;
    private isShareModalActive: boolean = false;
    private isPublishModalActive: boolean = false;
    private isEditPublishModalActive: boolean = false;

    private moduleStatus = ModuleStatusValues;
    private selectedModule: Module = {} as Module;

    private events = [] as Event[];
    private tableSettings = {
        perPage: 15,
        isStriped: true,
        isHoverable: true,
        isPaginated: false,
    };

    @Action('module/fetchAllModules')
    private fetchAllModules: any;

    private reload() {
        this.fetchAllModules();
    }

    private async publish(module: Module) {
        await moduleWebservice.publish(module);
        this.reload();
    }

    private async showConfirmModal(module: Module) {
        this.affectedData.set(this.$tc('keywords.block', 2).toString(), module.blocks_count);

        const users = await moduleWebservice.sharedUsers(module.id!);
        const emails = users.map((user) => user.email);
        this.affectedData.set(this.$t('labels.alreadySharedWith').toString(), emails);

        this.selectedModule = module;
        this.isConfirmModalActive = true;
    }

    private showShareModal(module: Module) {
        this.selectedModule = module;
        this.isShareModalActive = true;
    }

    private showActivateModal(module: Module) {
        this.selectedModule = module;
        this.isActivateModalActive = true;
    }

    private showPublishModal(module: Module) {
        this.togglePublishModuleModal();
        this.selectedModule = module;
    }

    private showEditPublishModal(module: Module) {
        this.toggleEditPublishModal();
        this.selectedModule = module;
    }

    private async toggleConfirmModal(answer: boolean) {
        if (answer === true) {
            await moduleWebservice.remove(this.selectedModule);
            this.isConfirmModalActive = !this.isConfirmModalActive;
            this.reload();
        } else {
            this.isConfirmModalActive = !this.isConfirmModalActive;
        }
    }

    private toggleFinished(module: Module) {
        moduleWebservice.toggleFinished(module).then((res) => {
            this.reload();
        });
    }

    private toggleActivateModuleModal() {
        this.isActivateModalActive = !this.isActivateModalActive;
    }

    private toggleShareModuleModal() {
        this.isShareModalActive = !this.isShareModalActive;
    }

    private togglePublishModuleModal() {
        this.isPublishModalActive = !this.isPublishModalActive;
    }

    private toggleEditPublishModal() {
        this.isEditPublishModalActive = !this.isEditPublishModalActive;
    }
}
