import axios from 'axios';
import { User } from '../models/user';
import HttpException from './HttpException';

class UserWebservice {
    /**
     * Call the login method on api
     *
     * @param username Email address
     * @param password Password
     */
    public login(email: string, password: string) {
        return axios.post('/auth/login', {
            email,
            password,
        });
    }

    public register(user: User) {
        return axios.post('/auth/register', {
            firstname: user.firstname,
            lastname: user.lastname,
            email: user.email,
            password: user.password,
            password_confirmation: user.password_confirmation,
        });
    }

    public logout() {
        return axios.post('/auth/logout');
    }

    public async getUserInfo() {
        try {
            const response = await axios.get<User>('/users/userinfo');
            return response.data;
        } catch (error) {
            throw new HttpException(error.response.status, 'There was an error fetching the user');
        }
    }

    public async getUserByEmail(email: string) {
        try {
            const response = await axios.get('/users/getUserByEmail', {
                params: {
                    email,
                },
            });

            return response.data;
        } catch (error) {
            throw new HttpException(error.response.status, 'There was an error fetching the user bythe email');
        }
    }

    public changeName(firstname: string, lastname: string) {
        return axios.post('account/updateName', {
            firstname,
            lastname,
        });
    }

    public changeTour(show_tour: boolean) {
        return axios.post('account/updateTour', {
            show_tour,
        });
    }

    public confirmInfo(user: User) {
        return axios.post('/auth/confirmInfo', {
            firstname: user.firstname,
            lastname: user.lastname,
            email: user.email,
        });
    }

    public changeEmail(new_email: string) {
        return axios.post('account/updateEmail', {
            new_email,
        });
    }

    public changePassword(current_password: string, new_password: string) {
        return axios.post('account/updatePassword', {
            current_password,
            new_password,
        });
    }

    public confirmEmail(email_confirmation_token: string) {
        return axios.post('/auth/emailConfirmation', {
            email_confirmation_token,
        });
    }

    public resendEmailConfirmationToken() {
        return axios.post('/auth/resendEmailConfirmationToken');
    }

    public forgotPassword(email: string) {
        return axios.post('/auth/forgotPassword', {
            email,
        });
    }

    public resetPassword(token: string, user: User) {
        return axios.post('/auth/resetPassword', {
            token,
            password: user.password,
            password_confirmation: user.password_confirmation,
        });
    }
}

export const userWebservice = new UserWebservice();
