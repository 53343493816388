






























import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import { ViewType } from '@/components/prine/enums/ViewType';
import { moduleWebservice } from '@/webservices/module';
import { Module } from '@/models/module';

import ModuleTab from '@/components/module/manager/tabs/ModuleTab.vue';
import ActivityFeedStudent from './ActivityFeedStudent.vue';

import ActiveModuleList from './ActiveModuleList.vue';
import ActiveModuleGrid from './ActiveModuleGrid.vue';
import EmptyListMsg from '@/components/prine/EmptyListMsg.vue';

import * as R from 'ramda';

@Component({
    name: 'ActiveTab',
    components: {
        ModuleTab,
        ActivityFeedStudent,
        ActiveModuleList,
        ActiveModuleGrid,
        EmptyListMsg,
    },
})
export default class ActiveTab extends Vue {
    private ViewType = ViewType;

    private activeKey: string = 'Aktive';
    private finishedKey: string = 'Abgeschlossene';

    @Getter('module/activeModules')
    private activeModules: Module[];

    @Getter('module/finishedModules')
    private finishedModules: Module[];

    private get activeModulesCheckboxes() {
        return this.$store.state.module.activeModulesCheckboxes;
    }

    private set activeModulesCheckboxes(value) {
        this.$store.commit('module/setActiveModulesCheckboxes', value);
    }

    private get combinedModules() {
        let combinedModules: Module[] = [];

        if (this.activeModulesCheckboxes.indexOf(this.activeKey) > -1) {
            combinedModules = combinedModules.concat(this.activeModules);
        }

        if (this.activeModulesCheckboxes.indexOf(this.finishedKey) > -1) {
            combinedModules = combinedModules.concat(this.finishedModules);
        }

        return combinedModules;
    }
}
