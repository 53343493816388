const en = {
    errors: {
        validation: {
            title: 'Please enter a title',
            description: 'Please enter a description',
            name: 'Please enter a name',
            email: 'Please enter a valid email',
            emailTaken: 'This e-mail address is already in use',
            password: 'Please enter a valid password',
            passwordIsIncorrect: 'Current typed password is incorrect',
            currentPassword: 'Please enter the current password',
            shortPassword: 'The password must be at least 8 characters long',
            firstName: 'Please enter your first name',
            lastName: 'Please enter your last name',
            shortFirstNameOrLastName: 'The firstname / lastname must be at least two characters long',
            address: 'Please enter an address',
            postcode: 'Please enter a ZIP code',
            city: 'Please enter a city',
            country: 'Please enter a country',
            useNumericValue: 'Please use a numeric value',
        },
        server: {
            invalidVerificationCode: 'The Verification code is not valid!',
            invalidToken: 'Invalid Token',
        },
    },
    keywords: {
        yes: 'Yes',
        no: 'No',
        save: 'Save',
        update: 'Update',
        remove: 'Remove',
        duplicate: 'Duplicate',
        back: 'Back',
        cancel: 'Cancel',
        close: 'Close',
        logout: 'Logout',
        login: 'Login',
        register: 'Sign up',
        title: 'Title',
        account: 'Account',
        myAccount: 'My Account',
        subscription: 'Subscription',
        settings: 'Settings',
        privacy: 'Privacy',
        email: 'Email',
        password: 'Password',
        new: 'New',
        description: 'Description',
        firstname: 'First name',
        lastname: 'Last name',
        at: '',
        actions: 'Actions',
        creator: 'Creator',
        search: 'Search',
        send: 'Send',
        confirm: 'Confirm',
        edit: 'Edit',
        private: 'private',
        users: 'Users',
        phone: 'Phone',
        address: 'Address',
        additionalAddress: 'Additional Address',
        postcode: 'Postcode',
        place: 'City',
        country: 'Country',
        order: 'Order',
        abbreviation: 'Abbreviation',
        name: 'Name',
        income: 'Income',
        perMonth: 'per month',
        perYear: 'per year',
        all: 'All',
        active: 'Active',

        block: 'block | blocks',
        topic: 'topic | topics',
        preview: 'Preview',
        class: 'class',
        student: 'LernendeR | Lernende',
        teacher: 'Lehrperson | Lehrpersonen',
        subject: 'Fach',
        schoollevel: 'Stufe/Alter',
        schooltype: 'Schultyp',
        language: 'Sprache',
        tags: 'Tags',
        piquestCurrency: 'Diamonds',
    },
    labels: {
        login: 'Login',
        loginOut: 'Logging out',
        passwordForgotten: 'Forgot password?',
        youAreJoining: 'You are joining',
        updateAt: 'Updated at',
        changedBy: 'Changed by',
        addNewQuestion: 'Add a new question',
        passwordRecovery: 'Password recovery',
        forgotPassword: 'Forgot password?',
        resetPassword: 'Reset password',
        changePassword: 'Change password',
        verificationCode: 'Verification Code',
        ResendVerificationEmailAgain: 'Resend verification email',
        selectAll: 'Select All',
        memberSince: 'Member since',
        currentPassword: 'Current Password',
        newPassword: 'New Password',
        confirmEmail: 'Confirm Email',
        editAccount: 'Edit Account',
        accountDetails: 'Account details',
        newQuestion: 'New Question',
        enterDate: 'Please choose a date',
        newTopic: 'New Topic',
        deleteQuestion: 'Delete question',
        updateTopic: 'Update Topic',
        newBlock: 'New Block',
        updateBlock: 'Update Block',
        alreadySharedWith: 'Already shared with',
        confirmation: 'Bestätigen',
        send: 'senden',
        register: 'Registrieren',
        duplicateModule: 'Modul duplizieren',
        deleteModule: 'Modul löschen',
        previewModule: 'Modul-Vorschau im Store anzeigen',

        numberOfCrystals: 'Anzahl Diamonds',
        studentOverview: 'Lernenden-Übersicht',
        exportToPdf: 'Export to PDF',
    },
    placeholder: {
        linkOrUploadFile: 'Link or file',
        noFileChosen: 'No file chosen',
        browseAndUpload: 'Browse and upload',
        uploadQuestionSource: 'Upload question source',
        uploadSolutionSource: 'Upload solution source',
    },
    tooltips: {
        browseAndUpload: 'Browse and upload',
    },
    messages: {
        logOutFirst: 'You need to logout first',
        passwordRecoveryEmailHasBeenSent: 'Password recovery email has been sent',
        passwordChangedSuccessfully: 'Password changed successfully',
        AnErrorHasOccurredPleaseTryAgain: 'An error has occurred, please try again!',
        RecoveryEmailIsOnlyValidFor3Hours: 'Password Recovery Email is only valid for 3 hours!',
        thankYouEmailConfirmed: 'Thank you, your e-mail address has been confirmed!',
        youHaveSuccessfulyLoggedIn: 'You have Successfully logged In!',
        noChangesDetected: 'No changes detected!',
        changesSavedSuccessfully: 'Changes saved Successfully.',
        LinkCopiedToClipboard: 'The following link has been copied to the clipboard: ',
        contactUsIfYouNeedHelp: 'Please contact us if you need help',
        areYouSureYouWantToDelete: 'Are you sure?',
        pleaseConfirmYourInformation: 'Please confirm your information',
        thankYouForConfirmingYourInformation: 'Thank you for confirming your information!',
        pleaseWaitForAccountConfirmationTitle: 'Account needs to be verified',
        pleaseWaitForAccountConfirmationText: 'Please wait till the account is confirmed. Normally it takes around 48h till its activated. In the meantime you can already have a look through the piQuest store.',
        pleaseWaitForAccountConfirmationEmail: 'If there is an issue with your account or the 48h are over, please write us to the following email',
        successfullyActivated: 'successfully Activated',
        emailConfirmationRequest: 'Bestätige deine E-Mail-Adresse',
        confirmationCode: 'Bitte gebe den Bestätigungscode ein',
        requestEmail: 'E-Mail nochmals anfordern',
        sendConfirmationEmail:'Bestätigungsmail wird versendet...',
        thankYouForConfirmingEmail: 'Vielen Dank, deine E-Mail-Adresse wurde bestätigt!',
        enterFirstname: 'Bitte geben Sie Ihren Vornamen ein',
        enterLastname: 'Bitte geben Sie Ihren Nachnamen ein',
        enterPassword: 'Bitte Passwort eingeben',
        passwordConfirmation: 'Bitte bestätigen Sie Ihr Passwort',
        enterValidEmail: 'Bitte geben Sie eine gültige E-Mail-Adresse ein',
        eMailIsAlreadyUsed: '>Diese E-Mail-Adresse wird schon verwendet',
        instructionEmailHasBeenSent: 'Eine Email mit weiteren Instruktionen wurde an die folgende Email Adresse geschickt: ',
        userWillBeLoggedOut: 'Benutzer wird abgemeldet...',
    },

    emptyMessages: {
        noEntryHeader: 'No entries found',
        noEntryBody: 'Unfortunately there are no entries here.',
    },

    confirmRemoveMessages: {
        confirmDelete: 'Please type <span class="has-text-weight-semibold is-uppercase has-text-danger">"{0}"</span> to proceed or close this modal to cancel.',
        module: {
            header: 'You are going to remove "{0}" Module. Removed Modules CANNOT be restored! Are you ABSOLUTELY sure?',
            body: 'With removing the Module all its dependant blocks, topics, questions and student evaluations will be DELETED too',
        },
        moduleColumn: {
            header: 'You are going to remove column number "{0}".',
            body: 'With removing the Column all its dependant block, topics, questions and student evaluations will be DELETED too',
        },
        moduleRow: {
            header: 'You are going to remove row number "{0}".',
            body: 'With removing the Row all its dependant topics, questions and student evaluations will be DELETED too',
        },
        block: {
            header: 'You are going to remove block "{0}".',
            body: "By removing the block all of its topics, questions and student evaluations (including student evaluations from other teacher you have shared the module) will be DELETED too.",
        },
        topic: {
            header: 'You are going to remove topic "{0}".',
            body: "By removing the topic all of its questions and student evaluations (including student evaluations from other teacher you have shared the module) will be DELETED too.",
        },
        questions: {
            header: 'You are going to remove question/s from topic "{0}".',
            body: "By removing the question/s all student evaluations (including student evaluations from other teachers you have shared the module) will be DELETED too.",
        },
    },

    toastMessages: {
        delete: {
            topic: 'The topic was successfully deleted',
        },
        store: {
            topic: 'The topic was successfully stored',
            email: 'The email was successfully sent',
        },
        update: {
            account: 'The account was successfully updated',
            topic: 'The topic was successfully updated',
        },
        error: {
        },
    },

    navigation: {
        main: {
            objects: 'My Projects',
            reports: 'Todo',
            experiences: 'My Experiences',
            approval: 'New Knowledge',
            setup: 'Knowledge',
            system: 'Admin',
            register: 'Sign up',
            login: 'Login',
            logout: 'Logout',
        },
    },
};

export default en;
