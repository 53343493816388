



























































import axios from 'axios';
import router from 'vue-router';
import { User } from '../../models/user';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { userWebservice } from '../../webservices/user';
@Component({
    name: 'ForgotPassword',
})
export default class ForgotPassword extends Vue {
    // Data
    private user: User = {
        id: 0,
        firstname: '',
        lastname: '',
        email: '',
        new_email: '',
        email_confirmation: '',
        password: '',
        password_confirmation: '',
        new_password: '',
        email_confirmed: false,
        activated: false,
        role_id: 0,
        goal: 0,
        show_tour: false,
    };
    private disabled: boolean = false;
    private errorMsg: string = '';
    private emailSent: boolean = false;

    // Mounted is called initially

    private mounted() {
        console.log('ForgotPassword mounted');
    }

    // Methods
    private sendEmail() {
        if (!this.validateEmail(this.user.email)) {
            this.errorMsg = 'Bitte geben Sie eine gültige E-Mail-Adresse ein';
            return 0;
        }

        const self = this;
        self.disabled = true;

        userWebservice
            .forgotPassword(this.user.email)
            .then(function(response) {
                self.errorMsg = '';
                self.emailSent = true;
            })
            .catch(function(error) {
                self.errorMsg = 'Ein Fehler ist aufgetreten, bitte versuchen Sie es erneut';
                self.disabled = false;
                console.log(error);
            });
    }

    private validateEmail(email: string) {
        /* tslint:disable-next-line:max-line-length */
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email.toLowerCase());
    }
}
