import axios from 'axios';
import { Event } from '../models/event';
import HttpException from './HttpException';

class EventWebservice {
    public async getEventById(event_id: number) {
        try {
            const response = await axios.get<Event>('/events/byId', {
                params: {
                    event_id,
                },
            });

            return response.data;
        } catch (error) {
            throw new HttpException(error.response.status, 'There was an error fetching the event by an id');
        }
    }
}

export const eventWebservice = new EventWebservice();
