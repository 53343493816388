






































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Topic } from '@/models/topic';
import { GridElementPosition } from '@/components/prine/gridtable/GridElementPosition';
import { Getter } from 'vuex-class';
import { User } from '@/models/user';
import * as R from 'ramda';
import { Evaluation } from '@/models/evaluation';
import GridElementTopicStatsModal from './GridElementTopicStatsModal.vue';
import { UserExtended } from '@/models/userextended';

@Component({
    name: 'GridElementTopic',
    components: {
        'topic-stats-modal': GridElementTopicStatsModal,
    },
})
export default class GridElementTopic extends Vue {
    @Prop() private topic: Topic;
    @Prop() private position: GridElementPosition;
    @Prop() private user: UserExtended;

    @Getter('account/currentUser') private currentUser: User;

    private avg: number = 0;
    private isStatsModalActive: boolean = false;
    private currentlyHovered: boolean = false;

    private mounted() {
        this.avg = this.calculateAverage();
    }

    private get getBackground() {
        // For the topics which have not yet evaluations use gray background
        if (this.isExistingTopic && this.avgPerTopic === 0) {
            return 'background-color: rgba(225,225,225, 1.0)';
        }

        // For the blocks where it has no topic use white background
        if (!this.isExistingTopic) {
            return '';
        }

        const minAlpha = 0.2;
        const maxAlpha = 0.8;

        const minGrade = 1;
        const maxGrade = 6;

        if (this.avgPerTopic >= this.user.goal) {
            // Green
            const diffAlpha = maxAlpha - minAlpha;
            const diffGrade = maxGrade - this.user.goal;
            const factor = diffAlpha / diffGrade;

            // f(grade) = (grade - goal) * factor + minAlpha
            let alpha = (this.avgPerTopic - this.user.goal) * factor + minAlpha;

            // If the goal is 6 and the avg aswell then we need to manually set the alpha value to the highest
            if (this.user.goal === 6 && this.avgPerTopic === 6) {
                alpha = 0.8;
            }

            return (
                'background-image: -webkit-linear-gradient(90deg, rgba(45,186,112, ' +
                alpha +
                ') 0%, rgba(45,186,112, ' +
                alpha +
                ') ' +
                this.calculateUserProgressPerTopic(this.topic) +
                '%, rgb(255, 255, 255) ' +
                this.calculateUserProgressPerTopic(this.topic) +
                '%'
            );
        } else {
            // Red
            const diffAlpha = maxAlpha - minAlpha;
            const diffGrade = this.user.goal - minAlpha;
            const factor = diffAlpha / diffGrade;

            // f(grade) = (goal - grade) * factor + minAlpha
            const alpha = (this.user.goal - this.avgPerTopic) * factor + minAlpha;

            return (
                'background-image: -webkit-linear-gradient(90deg, rgba(191,77,40, ' +
                alpha +
                ') 0%, rgba(191,77,40, ' +
                alpha +
                ') ' +
                this.calculateUserProgressPerTopic(this.topic) +
                '%, rgb(255, 255, 255) ' +
                this.calculateUserProgressPerTopic(this.topic) +
                '%'
            );
        }
    }

    private calculateAverage() {
        if (!R.isNil(this.topic.evaluations_per_user)) {
            if (this.user.id in this.topic.evaluations_per_user) {
                // Directly return 0 if there are no evaluations
                if (this.evaluations.length === 0) {
                    return 0;
                }

                let gradeSum = 0;

                this.evaluations.forEach((evaluation) => {
                    gradeSum += evaluation.grade;
                });

                return gradeSum / this.evaluations.length;
            }
        }
        return 0;
    }

    private toggleStatsModal() {
        if (this.isExistingTopic && this.evaluations.length > 0) {
            this.isStatsModalActive = !this.isStatsModalActive;
        }
    }

    private get evaluations() {
        if (!R.isNil(this.topic.evaluations_per_user)) {
            if (this.user.id in this.topic.evaluations_per_user) {
                return this.topic.evaluations_per_user[this.user.id].evaluations;
            } else {
                return new Array<Evaluation>();
            }
        } else {
            return new Array<Evaluation>();
        }
    }

    private get avgPerTopic() {
        return Math.round(this.avg * 100) / 100;
    }

    private get isExistingTopic(): boolean {
        return this.topic.hasOwnProperty('title');
    }

    private calculateUserProgressPerTopic(topic: Topic): number {
        if (topic.evaluations_per_user[this.user.id] && topic.evaluations_per_user[this.user.id].evaluations) {
            return Math.round(
                (topic.evaluations_per_user[this.user.id].evaluations.length / topic.questions.length) * 100
            );
        } else {
            return 0;
        }
    }
}
