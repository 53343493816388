
















import axios from 'axios';
import router from 'vue-router';
import { User } from '../../models/user';

import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { userWebservice } from '../../webservices/user';

@Component({
    name: 'Logout',
})
export default class Logout extends Vue {
    // Mounted is called initially
    private mounted() {
        console.log('Logout Mounted');
        this.logout();
    }

    // Methods
    private logout() {
        const self = this;
        userWebservice
            .logout()
            .then(function(response) {
                self.$store.commit('account/logout');
                setTimeout(() => {
                    localStorage.clear();
                    self.$router.push({ path: '/auth/login' });
                }, 300);
            })
            .catch(function(error) {
                console.log(error);
            });
    }
}
