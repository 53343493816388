const de = {
    errors: {
        validation: {
            title: 'Bitte einen Titel eingeben',
            description: 'Bitte eine Beschreibung eingeben',
            name: 'Bitte einen Namen eingeben',
            email: 'Bitte eine gültige Email-Adresse eingeben',
            emailTaken: 'Diese E-Mail-Adresse wird schon verwendet',
            password: 'Bitte ein gültiges Passwort eingeben',
            passwordIsIncorrect: 'Das aktuell eingegebene Passwort ist falsch',
            currentPassword: 'Bitte das aktuelle Passwort eingeben',
            shortPassword: 'Das Passwort muss mindestens 8 Zeichen lang sein',
            firstName: 'Bitte den Vornamen eingeben',
            lastName: 'Bitte den Nachnamen eigeben',
            shortFirstNameOrLastName: 'Der Vorname / Nachname muss mindestens zwei Zeichen lang sein',
            address: 'Bitte eine Adresse eingeben',
            postcode: 'Bitte eine PLZ eingeben',
            city: 'Bitte eine Stadt eingeben',
            country: 'Bitte ein Land eingeben',
            useNumericValue: 'Muss zwingend eine Zahl sein',
        },
        server: {
            invalidVerificationCode: 'Der Bestätigungscode ist nicht gültig!',
            invalidToken: 'Ungültiges Token',
        },
    },
    keywords: {
        yes: 'Ja',
        no: 'Nein',
        save: 'Speichern',
        update: 'Update',
        remove: 'Löschen',
        duplicate: 'Duplizieren',
        back: 'Zurück',
        cancel: 'Abbrechen',
        close: 'Schliessen',
        logout: 'Abmelden',
        login: 'Anmelden',
        register: 'Registrieren',
        title: 'Titel',
        account: 'Benutzerkonto',
        myAccount: 'Mein Konto',
        subscription: 'Abo',
        settings: 'Einstellungen',
        privacy: 'Privatssphäre',
        email: 'E-Mail',
        password: 'Passwort',
        new: 'Neue',
        description: 'Beschrieb',
        firstname: 'Vorname',
        lastname: 'Nachname',
        at: 'bei',
        actions: 'Aktionen',
        creator: 'Ersteller',
        search: 'Suche',
        send: 'Senden',
        confirm: 'Bestätigen',
        edit: 'Editieren',
        private: 'private',
        users: 'BenutzerIn',
        phone: 'Telefon',
        address: 'Adresse',
        additionalAddress: 'Zusätzliche Adresse',
        postcode: 'PLZ',
        place: 'Stadt',
        country: 'Land',
        order: 'Sortierung',
        abbreviation: 'Abkürzung',
        name: 'Name',
        income: 'Einkommen',
        perMonth: 'Per Monat',
        perYear: 'Per Jahr',
        all: 'Alle',
        active: 'Aktiv',

        block: 'Block | Blöcke',
        topic: 'Themenbereich | Themenbereiche',
        preview: 'Vorschau',
        class: 'Klasse',
        student: 'LernendeR | Lernende',
        teacher: 'Lehrperson | Lehrpersonen',
        subject: 'Schulfach',
        schoollevel: 'Stufe/Alter',
        schooltype: 'Schultyp',
        language: 'Sprache',
        tags: 'Tags',
        piquestCurrency: 'Diamonds',
    },
    labels: {
        login: 'Anmelden',
        loginOut: 'Abmelden',
        passwordForgotten: 'Passwort vergessen?',
        youAreJoining: 'Sie treten',
        updateAt: 'Aktualisiert am',
        changedBy: 'Geändert von',
        addNewQuestion: 'Neue Frage hinzufügen',
        passwordRecovery: 'Passwort Wiederherstellung',
        forgotPassword: 'Passwort vergessen?',
        resetPassword: 'Passwort zurücksetzen',
        changePassword: 'Passwort ändern',
        verificationCode: 'Bestätigungscode',
        ResendVerificationEmail: 'Bestätigungsmail erneut senden',
        selectAll: 'Alle wählen',
        memberSince: 'Mitglied seit',
        currentPassword: 'Aktuelles Passwort',
        newPassword: 'Neues Passwort',
        confirmEmail: 'Bestätigungs-E-Mail',
        editAccount: 'Konto bearbeiten',
        accountDetails: 'Kontodetails',
        newQuestion: 'Neue Frage',
        deleteQuestion: 'Frage löschen',
        enterDate: 'Bitte ein Datum auswählen',
        newTopic: 'Neuer Themenbereich',
        updateTopic: 'Themenbereich aktualisieren',
        newBlock: 'Neuer Block',
        updateBlock: 'Block aktualisieren',
        alreadySharedWith: 'Bereits geteilt mit',
        confirmation: 'Bestätigen',
        send: 'Senden',
        register: 'Registrieren',
        duplicateModule: 'Modul duplizieren',
        deleteModule: 'Modul löschen',
        previewModule: 'Modul-Vorschau im Store anzeigen',

        numberOfCrystals: 'Anzahl Diamonds',
        studentOverview: 'Lernenden-Übersicht',
        exportToPdf: 'Export to PDF',
    },
    placeholder: {
        linkOrUploadFile: 'Link oder Datei',
        noFileChosen: 'Keine Datei ausgewählt',
        browseAndUpload: 'Durchsuchen und hochladen',
        uploadQuestionSource: 'Originaldatei hochladen',
        uploadSolutionSource: 'Originaldatei (Lösung) hochladen',
    },
    tooltips: {
        browseAndUpload: 'Durchsuchen und hochladen',
    },
    messages: {
        logOutFirst: 'Sie müssen sich zuerst abmelden',
        passwordRecoveryEmailHasBeenSent: 'Passwort Wiederherstellung E-Mail wurde gesendet',
        passwordChangedSuccessfully: 'Das Passwort wurde erfolgreich geändert',
        AnErrorHasOccurredPleaseTryAgain: 'Ein Fehler ist aufgetreten, bitte erneut versuchen!',
        RecoveryEmailIsOnlyValidFor3Hours: 'Passwort Wiederherstellung E-Mail ist nur für 3 Stunden gültig!',
        thankYouEmailConfirmed: 'Vielen Dank, die E-Mail-Adresse wurde bestätigt!',
        youHaveSuccessfulyLoggedIn: 'Erfolgreich angemeldet!',
        noChangesDetected: 'Keine Änderungen festgestellt!',
        changesSavedSuccessfully: 'Änderungen erfolgreich gespeichert.',
        LinkCopiedToClipboard: 'Der folgende Link wurde ins Clipboard kopiert: ',
        contactUsIfYouNeedHelp: 'Bitte kontaktieren Sie uns, wenn Sie Hilfe benötigen',
        areYouSureYouWantToDelete: 'Wirklich löschen?',
        pleaseConfirmYourInformation: 'Bitte bestätigen Sie Ihre Angaben',
        thankYouForConfirmingYourInformation: 'Vielen Dank für die Bestätigung Ihrer Angaben!',
        pleaseWaitForAccountConfirmationTitle: 'Bald können Sie mit piQuest loslegen',
        pleaseWaitForAccountConfirmationText: 'Ihr Konto wird in Kürze von piQuest freigegeben. In der Zwischenzeit können Sie mit dem Link unten schon mal im piQuest Store nach Modulen stöbern. Normalerweise wird das Konto innerhalb von 24h aktiviert oder Sie hören per Email von uns.',
        pleaseWaitForAccountConfirmationEmail: 'Falls etwas mit der Aktivierung nicht geklappt hat, melden Sie sich bitte an folgende Email',
        successfullyActivated: 'Erfolgreich aktiviert',
        emailConfirmationRequest: 'Bestätigen Sie Ihre E-Mail-Adresse',
        confirmationCode: 'Bitte den Bestätigungscode eingeben',
        requestEmail: 'E-Mail nochmals anfordern',
        sendConfirmationEmail:'Bestätigungsmail wird versendet...',
        thankYouForConfirmingEmail: 'Vielen Dank, Ihre E-Mail-Adresse wurde bestätigt!',
        enterFirstname: 'Bitte geben Sie Ihren Vornamen ein',
        enterLastname: 'Bitte geben Sie Ihren Nachnamen ein',
        enterPassword: 'Bitte Passwort eingeben',
        passwordConfirmation: 'Bitte bestätigen Sie Ihr Passwort',
        enterValidEmail: 'Bitte geben Sie eine gültige E-Mail-Adresse ein',
        eMailIsAlreadyUsed: '>Diese E-Mail-Adresse wird schon verwendet',
        instructionEmailHasBeenSent: 'Eine Email mit weiteren Instruktionen wurde an die folgende Email Adresse geschickt: ',
        userWillBeLoggedOut: 'BenutzerIn wird abgemeldet...',
    },

    emptyMessages: {
        noEntryHeader: 'Keine Einträge gefunden',
        noEntryBody: 'Leider gibt es hier keine Einträge.',
    },

    confirmRemoveMessages: {
        confirmDelete: 'Bitte geben Sie <span class="has-text-weight-semibold is-uppercase has-text-danger">"{0}"</span> ein, um fortzufahren, oder beenden Sie diesen Modus, um den Vorgang abzubrechen',
        module: {
            header: 'Sie werden das Modul "{0}" entfernen. Entfernte Module können NICHT wiederhergestellt werden! Sind Sie ABSOLUT sicher?',
            body: 'Mit dem Entfernen des Moduls werden alle abhängigen Blöcke, Themen, Fragen und Studentenevaluationen gelöscht',
        },
        moduleColumn: {
            header: 'Sie werden die Spalte "{0}" löschen.',
            body: 'Mit dem Entfernen der Spalte werden abhängige Blöcke, Themen, Fragen, Ereignisse und Studentenevaluationen gelöscht',
        },
        moduleRow: {
            header: 'Sie werden die Zeile "{0}" löschen.',
            body: 'Mit dem Entfernen der Zeile werden abhängige Themen, Fragen, Ereignisse und Studentenevaluationen gelöscht',
        },
        block: {
            header: 'Sie werden den Block "{0}" löschen.',
            body: "Mit dem Entfernen des Blockes werden alle Themen, Fragen und Studentenevaluationen (auch die von anderen Lehrer/innen, welche das Modul von Ihnen geteilt bekommen haben) gelöscht.",
        },
        topic: {
            header: 'Sie werden das Thema "{0}" löschen.',
            body: "Mit dem Entfernen des Themas werden alle Fragen und Studentenevaluationen (auch die von anderen Lehrer/innen, welche das Modul von Ihnen geteilt bekommen haben) gelöscht.",
        },
        questions: {
            header: 'Sie werden Fragen vom Thema "{0}" löschen.',
            body: "Mit dem Entfernen der Fragen, werden alle Studentenevaluationen (auch die von anderen Lehrer/innen, welche das Modul von Ihnen geteilt bekommen haben) gelöscht. ",
        },
    },

    toastMessages: {
        delete: {
            topic: 'Der Themenbereich wurde erfolgreich gelöscht',
        },
        store: {
            topic: 'Der Themenbereich wurde erfolgreich gespeichert',
            email: 'Das Email wurde erfolgreich versendet',
        },
        update: {
            account: 'Das Konto wurde erfolgreich aktualisiert',
            topic: 'Der Themenbereich wurde erfolgreich aktualisiert',
        },
        error: {
        },
    },

    navigation: {
        main: {
            objects: 'Meine Projekte',
            reports: 'Zu Tun',
            experiences: 'Meine Erfahrungen',
            approval: 'Neues Wissen',
            setup: 'Wissen',
            system: 'Admin',
            register: 'Registrieren',
            login: 'Anmelden',
            logout: 'Abmelden',
        },
    },
};

export default de;
