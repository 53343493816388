






























































import { Component, Prop, Vue } from 'vue-property-decorator';
import moment from 'moment';

@Component({
    name: 'Footer',
    components: {},
})
export default class Footer extends Vue {
    protected version: string = '';

    private get currentYear() {
        return moment().format('YYYY');
    }
}
