





































































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';

import { moduleWebservice } from '@/webservices/module';

import { Module } from '@/models/module';
import Modal, { ModalOptions } from '@/components/prine/modal/Modal.vue';

import { PiquestSubject } from '@/models/piquestSubject';
import { SchoolLevel } from '@/models/schoollevel';
import { Schooltype } from '@/models/schooltype';
import { Language } from '@/models/language';
import { Country } from '@/models/country';
import { ModulePrice } from '@/models/modulePrice';
import { Tag } from '@/models/tag';

import { ModuleMetaData } from '@/models/moduleMetaData';
import * as R from 'ramda';

@Component({
    name: 'PublishModuleModal',
    components: {
        Modal,
    },
})
export default class PublishModuleModal extends Vue {
    private moduleData: Module = {} as Module;

    private disabled: boolean = false;
    private loaded: boolean = false;
    private errorMsg: string = '';
    private isLoadingModuleData: boolean = true;

    private selectedTags: Tag[] = [];

    @Getter('moduleMetadata/piquestSubjects') private piquestSubjects: PiquestSubject[];
    @Getter('moduleMetadata/schoollevels') private schoollevels: SchoolLevel[];
    @Getter('moduleMetadata/schooltypes') private schooltypes: Schooltype[];
    @Getter('moduleMetadata/languages') private languages: Language[];
    @Getter('moduleMetadata/countries') private countries: Country[];
    @Getter('moduleMetadata/modulePrices') private modulePrices: ModulePrice[];
    @Getter('moduleMetadata/tags') private tags: Tag[];

    @Action('subject/loadSubjects') private loadSubjects: any;

    @Action('module/fetchAllModules')
    private fetchAllModules: any;

    private modalOptions: ModalOptions = {
        width: null,
        height: null,
        showDelete: false,
        isLoading: false,
        saveButtonText: 'Veröffentlichen',
        saveDisabled: true,
    };

    @Prop() private module: Module;

    private async mounted() {
        await this.loadModule(this.module);
        this.moduleData.metaData = {
            crystals: this.modulePrices[0].crystals,
            piquest_subject_id: this.piquestSubjects[0].id,
            schoollevel_id: this.schoollevels[0].id,
            schooltype_id: this.schooltypes[0].id,
            language_id: this.languages[0].id,
            country_id: this.countries[0].id,
            description: this.moduleData.description,
            tags: [] as Tag[],
            tag_ids: [] as number[],
        } as ModuleMetaData;
        this.loaded = true;
        if (this.moduleData.metaData.description === null) {
            this.errorMsg = 'description';
            this.moduleData.metaData.description = '';
        }
    }

    get isNotEmpty() {
        if (this.moduleData.blocks && this.moduleData.blocks.length !== 0) {
            let haveQuestions: boolean = false;
            this.moduleData.blocks.forEach((block) => {
                if (block.topics.length !== 0) {
                    block.topics.forEach((topic) => {
                        if (topic.questions.length !== 0) {
                            haveQuestions = true;
                            this.modalOptions.saveDisabled = false;
                            return true;
                        }
                    });
                }
                if (haveQuestions) {
                    return true;
                }
            });
            return haveQuestions;
        } else {
            return false;
        }
    }

    /**
     * Load the module by a given id
     */
    private async loadModule(module: Module) {
        if (module.id) {
            this.moduleData = await moduleWebservice.getModuleById(module.id);
            this.isLoadingModuleData = false;
        }
    }

    /**
     * Emits the toggle method in the parent
     */
    private toggleModal() {
        this.$emit('togglePublishModuleModal');
    }

    /**
     * Store or update the block
     */
    private async activate() {
        if (!this.validate()) {
            return;
        }

        this.modalOptions.isLoading = true;

        // Set the selected tags manually
        // There was a bug setting it directly in the mutliselect, probably because it could be null
        if (this.moduleData.metaData) {
            this.moduleData.metaData.tags = this.selectedTags;
        }
        await moduleWebservice.publish(this.moduleData);
        this.showSuccessNotification();

        this.fetchAllModules();
        this.toggleModal();
        this.modalOptions.isLoading = false;
    }

    /**
     * Show a snackbar notification banner
     */
    private showSuccessNotification() {
        this.$buefy.snackbar.open({
            duration: 5000,
            message: 'Modul ist im Store veröffentlicht. Bitte warten Sie auf die Bestätigung durch den Administrator',
            type: 'is-success',
            queue: true,
            position: 'is-top',
        });
    }

    private validate() {
        if (this.moduleData.metaData && R.isEmpty(this.moduleData.metaData.description)) {
            this.errorMsg = 'description';
            return false;
        }

        this.errorMsg = '';
        return true;
    }

    private customMultiselectLabel({ title }: { title: string }) {
        return `${title}`;
    }
}
