import Vue from 'vue';
import Vuex, { MutationTree, ActionTree } from 'vuex';

Vue.use(Vuex);

interface State {
    manageHomeworksActiveTab: number;

    manageOpenHomeworks: {
        perPage: number;
        currentPage: number;
        openedDetailed: number[];
    };

    manageClosedHomeworks: {
        perPage: number;
        currentPage: number;
        openedDetailed: number[];
    };

    manageExamsActiveTab: number;

    manageOpenExams: {
        perPage: number;
        currentPage: number;
        openedDetailed: number[];
    };

    manageClosedExams: {
        perPage: number;
        currentPage: number;
        openedDetailed: number[];
    };

    manageModulesActiveTabIndex: number;
}

const mutations: MutationTree<State> = {
    setManageHomeworksActiveTab: (currentState, activeTab) => (currentState.manageHomeworksActiveTab = activeTab),

    setManageOpenHomeworksPerPage: (currentState, perPage) => (currentState.manageOpenHomeworks.perPage = perPage),

    setManageOpenHomeworksCurrentPage: (currentState, currentPage) =>
        (currentState.manageOpenHomeworks.currentPage = currentPage),

    setManageClosedHomeworksPerPage: (currentState, perPage) => (currentState.manageClosedHomeworks.perPage = perPage),

    setManageClosedHomeworksCurrentPage: (currentState, currentPage) =>
        (currentState.manageClosedHomeworks.currentPage = currentPage),

    setManageOpenHomeworksOpenedDetailed: (currentState, value) =>
        (currentState.manageOpenHomeworks.openedDetailed = value),

    setManageClosedHomeworksOpenedDetailed: (currentState, value) =>
        (currentState.manageClosedHomeworks.openedDetailed = value),

    setManageExamsActiveTab: (currentState, activeTab) => (currentState.manageExamsActiveTab = activeTab),

    setManageOpenExamsPerPage: (currentState, perPage) => (currentState.manageOpenExams.perPage = perPage),

    setManageOpenExamsCurrentPage: (currentState, currentPage) =>
        (currentState.manageOpenExams.currentPage = currentPage),

    setManageClosedExamsPerPage: (currentState, perPage) => (currentState.manageClosedExams.perPage = perPage),

    setManageClosedExamsCurrentPage: (currentState, currentPage) =>
        (currentState.manageClosedExams.currentPage = currentPage),

    setManageOpenExamsOpenedDetailed: (currentState, value) => (currentState.manageOpenExams.openedDetailed = value),

    setManageClosedExamsOpenedDetailed: (currentState, value) =>
        (currentState.manageClosedExams.openedDetailed = value),

    setManageModulesActiveTabIndex: (currentState, value) => (currentState.manageModulesActiveTabIndex = value),
};

const actions: ActionTree<State, any> = {};

const getters = {
    manageModulesActiveTabIndex(currentState: State) {
        return currentState.manageModulesActiveTabIndex;
    },
};

const state: State = {
    manageHomeworksActiveTab: 0,
    manageOpenHomeworks: {
        perPage: 10,
        currentPage: 1,
        openedDetailed: [],
    },
    manageClosedHomeworks: {
        perPage: 10,
        currentPage: 1,
        openedDetailed: [],
    },
    manageExamsActiveTab: 0,
    manageOpenExams: {
        perPage: 10,
        currentPage: 1,
        openedDetailed: [],
    },
    manageClosedExams: {
        perPage: 10,
        currentPage: 1,
        openedDetailed: [],
    },
    manageModulesActiveTabIndex: 0,
};

const module = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};

export default module;
