












































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';

import { moduleWebservice } from '@/webservices/module';
import { subjectWebservice } from '@/webservices/subject';
import { Module } from '@/models/module';

import Modal, { ModalOptions } from '@/components/prine/modal/Modal.vue';
import CreateGroupModal from '@/components/group/CreateGroupModal.vue';
import CreateSubjectModal from '@/components/subject/CreateSubjectModal.vue';
import { Group } from '@/models/group';
import { Subject } from '@/models/subject';

import * as R from 'ramda';
import { AxiosError, AxiosResponse, AxiosTransformer } from 'axios';

@Component({
    name: 'ActivateModuleModal',
    components: {
        Modal,
        CreateGroupModal,
        CreateSubjectModal,
    },
})
export default class ActivateModuleModal extends Vue {
    private moduleData: Module = {} as Module;

    private disabled: boolean = false;
    private errorMsg: string = '';
    private isCreateGroupModalActive: boolean = false;
    private isCreateSubjectModalActive: boolean = false;
    private isLoadingModuleData: boolean = true;

    private group: Group = {} as Group;
    private subject: Subject = {} as Subject;

    @Getter('group/groups') private groups: Group[];
    @Getter('subject/subjects') private subjects: Subject[];

    @Action('group/loadGroups') private loadGroups: any;
    @Action('subject/loadSubjects') private loadSubjects: any;

    @Action('module/fetchAllModules')
    private fetchAllModules: any;

    private modalOptions: ModalOptions = {
        width: 1000,
        height: null,
        showDelete: false,
        isLoading: false,
        saveButtonText: 'Speichern',
        saveDisabled: true,
    };

    @Prop() private module: Module;

    private mounted() {
        this.loadModule(this.module);
        this.loadGroups();
        this.loadSubjects();
        this.setFirstSubject();
        this.setFirstGroup();
    }

    get isNotEmpty() {
        if (this.moduleData.blocks && this.moduleData.blocks.length !== 0) {
            let haveQuestions: boolean = false;
            this.moduleData.blocks.forEach((block) => {
                if (block.topics.length !== 0) {
                    block.topics.forEach((topic) => {
                        if (topic.questions.length !== 0) {
                            haveQuestions = true;
                            this.modalOptions.saveDisabled = false;
                            return true;
                        }
                    });
                }
                if (haveQuestions) {
                    return true;
                }
            });
            return haveQuestions;
        } else {
            return false;
        }
    }

    private setFirstSubject() {
        if (this.subjects.length >= 1) {
            this.subject = this.subjects[0];
        }
    }

    private setFirstGroup() {
        if (this.groups.length >= 1) {
            this.group = this.groups[0];
        }
    }

    /**
     * Load the module by a given id
     */
    private async loadModule(module: Module) {
        if (module.id) {
            this.moduleData = await moduleWebservice.getModuleById(module.id);
            this.isLoadingModuleData = false;
        }
    }

    private async fetchGroups(newGroupName: string = '') {
        await this.loadGroups();
        if (newGroupName !== '') {
            this.groups.find((group) => {
                if (newGroupName === group.name) {
                    this.group = group;
                    return true;
                }
                return false;
            });
        }
    }

    private async fetchSubjects(newSubjectTitle: string = '') {
        await this.loadSubjects();
        if (newSubjectTitle !== '') {
            this.subjects.find((subject) => {
                if (newSubjectTitle === subject.title) {
                    this.subject = subject;
                    return true;
                }
                return false;
            });
        }
    }

    /**
     * Emits the toggle method in the parent
     */
    private toggleModal() {
        this.$emit('toggleActivateModuleModal');
    }

    /**
     * Store or update the block
     */
    private async activate() {
        if (!this.validate()) {
            return;
        }

        this.modalOptions.isLoading = true;

        moduleWebservice
            .activate(this.module, this.group, this.subject)
            .then((result: AxiosResponse) => {
                this.fetchAllModules();
                this.toggleModal();
                this.modalOptions.isLoading = false;
            })
            .catch((error: AxiosError) => {
                if (error.response?.data === 'isActivated') {
                    this.errorMsg = 'isActivated';
                }
                this.modalOptions.isLoading = false;
            });
    }

    private validate() {
        if (R.isEmpty(this.group)) {
            this.errorMsg = 'group';
            return false;
        }

        if (R.isEmpty(this.subject)) {
            this.errorMsg = 'subject';
            return false;
        }

        // There needs to be at least one active users, otherwise it will not activate the module
        /*
        if (this.group.active_users.length === 0) {
            this.errorMsg = 'noActiveUsers';
            return false;
        }
        */

        return true;
    }

    private toggleCreateGroupModal() {
        this.isCreateGroupModalActive = !this.isCreateGroupModalActive;
    }

    private toggleCreateSubjectModal() {
        this.isCreateSubjectModalActive = !this.isCreateSubjectModalActive;
    }
}
