import Vue from 'vue';
import Vuex, { MutationTree, ActionTree } from 'vuex';
import { Group } from '../../../models/group';
import { groupWebservice } from '@/webservices/group';

Vue.use(Vuex);

interface State {
    groups: Group[];
}

const mutations: MutationTree<State> = {
    setGroups: (currentState, groups) => (
        (groups = groups.filter((group: Group) => {
            return group.name !== 'MySelf';
        })),
        (currentState.groups = groups)
    ),
};

const actions: ActionTree<State, any> = {
    async loadGroups({ commit }) {
        try {
            const response = await groupWebservice.getAll();
            commit('setGroups', response.data);
        } catch (error) {
            console.error(error);
        }
    },
};

const getters = {
    groups(currentState: State) {
        return currentState.groups;
    },
};

const state: State = {
    groups: Array<Group>(),
};

const module = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};

export default module;
