import Vue from 'vue';
import Vuex, { MutationTree, ActionTree } from 'vuex';
import { Exam } from '../../../models/exam';

Vue.use(Vuex);

interface State {
    open_exams: Exam[];
    closed_exams: Exam[];
}

const mutations: MutationTree<State> = {
    setOpenExams: (currentState, exams: Exam[]) => (currentState.open_exams = exams),
    setClosedExams: (currentState, exams: Exam[]) => (currentState.closed_exams = exams),
};

const actions: ActionTree<State, any> = {};

const getters = {
    openExams(currentState: State) {
        return currentState.open_exams;
    },

    closedExams(currentState: State) {
        return state.closed_exams;
    },
};

const state: State = {
    open_exams: Array<Exam>(),
    closed_exams: Array<Exam>(),
};

const module = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};

export default module;
