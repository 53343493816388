import Vue from 'vue';
import Vuex, { MutationTree, ActionTree } from 'vuex';

import { ModuleMetaData } from '@/models/moduleMetaData';
import { PiquestSubject } from '@/models/piquestSubject';
import { SchoolLevel } from '@/models/schoollevel';
import { Schooltype } from '@/models/schooltype';
import { Language } from '@/models/language';
import { Country } from '@/models/country';
import { ModulePrice } from '@/models/modulePrice';
import { Tag } from '@/models/tag';

import { moduleMetadataWebservice } from '@/webservices/moduleMetadata';

Vue.use(Vuex);

interface State {
    piquestSubjects: PiquestSubject[];
    schoollevels: SchoolLevel[];
    schooltypes: Schooltype[];
    languages: Language[];
    countries: Country[];
    modulePrices: ModulePrice[];
    tags: Tag[];
}

const mutations: MutationTree<State> = {
    setPiquestSubjects: (currentState, piquestSubjects: PiquestSubject[]) => {
        currentState.piquestSubjects = piquestSubjects;
    },

    setSchoollevels: (currentState, schoollevels: SchoolLevel[]) => {
        currentState.schoollevels = schoollevels;
    },

    setSchooltypes: (currentState, schooltypes: Schooltype[]) => {
        currentState.schooltypes = schooltypes;
    },

    setLanguages: (currentState, languages: Language[]) => {
        currentState.languages = languages;
    },

    setCountries: (currentState, countries: Country[]) => {
        currentState.countries = countries;
    },

    setModulePrices: (currentState, modulePrices: ModulePrice[]) => {
        currentState.modulePrices = modulePrices;
    },

    setTags: (currentState, tags: Tag[]) => {
        currentState.tags = tags;
    },
};

const actions: ActionTree<State, any> = {
    async fetchAllMetadata() {
        try {
            this.dispatch('moduleMetadata/fetchPiquestSubjects');
            this.dispatch('moduleMetadata/fetchSchoollevels');
            this.dispatch('moduleMetadata/fetchSchooltypes');
            this.dispatch('moduleMetadata/fetchLanguages');
            this.dispatch('moduleMetadata/fetchCountries');
            this.dispatch('moduleMetadata/fetchModulePrices');
            this.dispatch('moduleMetadata/fetchTags');
        } catch (error) {
            console.error(error);
        }
    },

    async fetchTags({ commit, state }) {
        try {
            const res = await moduleMetadataWebservice.tags();
            commit('setTags', res);
        } catch (error) {
            console.error(error);
        }
    },

    async fetchPiquestSubjects({ commit, state }) {
        try {
            const res = await moduleMetadataWebservice.piquestSubjects();
            commit('setPiquestSubjects', res);
        } catch (error) {
            console.error(error);
        }
    },

    async fetchSchoollevels({ commit, state }) {
        try {
            const res = await moduleMetadataWebservice.schoollevels();
            commit('setSchoollevels', res);
        } catch (error) {
            console.error(error);
        }
    },

    async fetchSchooltypes({ commit, state }) {
        try {
            const res = await moduleMetadataWebservice.schooltypes();
            commit('setSchooltypes', res);
        } catch (error) {
            console.error(error);
        }
    },

    async fetchLanguages({ commit, state }) {
        try {
            const res = await moduleMetadataWebservice.languages();
            commit('setLanguages', res);
        } catch (error) {
            console.error(error);
        }
    },

    async fetchCountries({ commit, state }) {
        try {
            const res = await moduleMetadataWebservice.countries();
            commit('setCountries', res);
        } catch (error) {
            console.error(error);
        }
    },

    async fetchModulePrices({ commit, state }) {
        try {
            const res = await moduleMetadataWebservice.prices();
            commit('setModulePrices', res);
        } catch (error) {
            console.error(error);
        }
    },
};

const getters = {
    piquestSubjects(currentState: State) {
        return currentState.piquestSubjects;
    },

    schoollevels(currentState: State) {
        return currentState.schoollevels;
    },

    schooltypes(currentState: State) {
        return currentState.schooltypes;
    },

    languages(currentState: State) {
        return currentState.languages;
    },

    countries(currentState: State) {
        return currentState.countries;
    },

    modulePrices(currentState: State) {
        return currentState.modulePrices;
    },

    tags(currentState: State) {
        return currentState.tags;
    },
};

const defaultState: State = {
    piquestSubjects: [],
    schoollevels: [],
    schooltypes: [],
    languages: [],
    countries: [],
    modulePrices: [],
    tags: [],
};

const module = {
    namespaced: true,
    state: defaultState,
    getters,
    mutations,
    actions,
};

export default module;
