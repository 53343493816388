








import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import { Module } from '@/models/module';
import SharedModuleCard from './SharedModuleCard.vue';

@Component({
    name: 'SharedModuleGrid',
    components: {
        SharedModuleCard,
    },
})
export default class SharedModuleGrid extends Vue {
    @Getter('module/sharedModules')
    private sharedModules: Module[];
}
