import axios from 'axios';
import { Subject } from '../models/subject';
import { Group } from '../models/group';
import { User } from '../models/user';

class SubjectWebservice {
    public getAll() {
        return axios.get('/subjects');
    }

    public store(subject: Subject) {
        return axios.post('/subjects/store', {
            title: subject.title,
            token: subject.token,
        });
    }

    public update(subject: Subject) {
        return axios.post('/subjects/update', {
            title: subject.title,
            token: subject.token,
            id: subject.id,
        });
    }

    public remove(subject: Subject) {
        return axios.post('/subjects/remove', {
            id: subject.id,
        });
    }

    public getUserSubjectsByGroup(user: User, group: Group) {
        return axios.get('/evaluations/getEvaluationsGroupedBySubjectsByUserId', {
            params: {
                user_id: user.id,
                group_id: group.id,
            },
        });
    }

    public fetchUserNotDoneEvents(user: User, group: Group, subject: Subject) {
        return axios.get('/events/notDoneEvents', {
            params: {
                userId: user.id,
                groupId: group.id,
                subjectId: subject.id,
            },
        });
    }
}

export const subjectWebservice = new SubjectWebservice();
