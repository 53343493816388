import axios from 'axios';
import { TopicType } from '../models/topicType';
import HttpException from './HttpException';

class TopicTypeWebservice {
    public async getAll() {
        try {
            const response = await axios.get<TopicType[]>('/topic_types');
            return response.data;
        } catch (error) {
            throw new HttpException(error.response.status, 'There was an error fetching the topic types');
        }
    }
}

export const topicTypeWebservice = new TopicTypeWebservice();
