



















































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import CreateSubjectModal from './CreateSubjectModal.vue';

import { Group } from '../../models/group';
import { User } from '../../models/user';
import { Topic } from '../../models/topic';
import { Subject } from '../../models/subject';
import { groupWebservice } from '../../webservices/group';
import { subjectWebservice } from '../../webservices/subject';

@Component({
    name: 'ManageSubjects',
    components: {
        CreateSubjectModal,
    },
})
export default class ManageGroups extends Vue {
    private isCreateSubjectModalActive: boolean = false;

    private selectedSubject: Subject = {
        title: '',
        token: '',
        id: 0,
        topics: Array<Topic>(),
    };

    private tableSettings = {
        openedDetailed: Array<number>(),
        perPage: 10,
    };

    @Getter('subject/subjects') private subjects: Subject[];

    // Mounted is called initially
    private mounted() {
        this.fetchSubjects();
    }

    private toggleCreateSubjectModal(subject: Subject) {
        this.selectedSubject = subject;
        this.isCreateSubjectModalActive = !this.isCreateSubjectModalActive;
    }

    private fetchSubjects() {
        // console.log('fetch subject got fired from parent')
        const self = this;
        // Fetch all groups
        subjectWebservice.getAll().then(function(response) {
            // Store in vuex
            self.$store.commit('subject/setSubjects', response.data);
        });
    }
}
