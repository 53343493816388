export class Base64Helper {
    public async getBase64FromUrl(url: string) {
        const data = await fetch(url);
        const blob = await data.blob();
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob); 
            reader.onloadend = () => {
                let base64data = reader.result   
                resolve(base64data);
            }
        });
    }

    public getBase64Image(img: HTMLImageElement) {
        const canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext("2d");
        ctx?.drawImage(img, 0, 0);
        return canvas.toDataURL("image/png");
        // return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
    }
      
}