








































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import CreateGroupModal from './CreateGroupModal.vue';
import UserSubjectsModal from './../subject/UserSubjectsModal.vue';
import { Group } from '../../models/group';
import { User } from '../../models/user';
import { Subject } from '../../models/subject';
import { groupWebservice } from '../../webservices/group';
import { subjectWebservice } from '../../webservices/subject';

@Component({
    name: 'ManageGroups',
    components: {
        CreateGroupModal,
        UserSubjectsModal,
    },
})
export default class ManageGroups extends Vue {
    private isCreateModalActive: boolean = false;
    private isUserSubjectsModalActive: boolean = false;
    private selectedGroup: Group = {
        name: '',
        token: '',
        id: 0,
        active_users: [],
    };
    private selectedUser: User = {
        id: 0,
        firstname: '',
        lastname: '',
        email: '',
        new_email: '',
        email_confirmation: '',
        password: '',
        password_confirmation: '',
        new_password: '',
        email_confirmed: false,
        activated: false,
        role_id: 0,
        goal: 0,
        show_tour: false,
    };

    private tableSettings = {
        openedDetailed: Array<number>(),
    };

    @Getter('group/groups') private groups: Group[];

    // Mounted is called initially
    private mounted() {
        this.fetchGroups();
    }

    private toggleUserSubjectsModal(user: User) {
        this.selectedUser = user;
        this.isUserSubjectsModalActive = !this.isUserSubjectsModalActive;
    }

    private toggleCreateGroupModal(group: Group) {
        this.selectedGroup = group;
        this.isCreateModalActive = !this.isCreateModalActive;
    }

    private toggleDetails(group: Group) {
        const self = this;
        if (this.tableSettings.openedDetailed.includes(group.id)) {
            this.tableSettings.openedDetailed = [];
        } else {
            this.tableSettings.openedDetailed = [];
            setTimeout(function() {
                self.tableSettings.openedDetailed.push(group.id);
                self.setSelectedGroup(group);
            }, 100);
        }
    }

    private fetchGroups() {
        const self = this;
        // Fetch all groups
        groupWebservice.getAll().then(function(response) {
            // Store in vuex
            self.$store.commit('group/setGroups', response.data);
        });
    }

    private toggleUserActive(user: User) {
        const self = this;
        // Fetch all groups

        groupWebservice.toggleUserActive(user, self.selectedGroup).then(function(response) {
            self.fetchGroups();
        });
    }

    private setSelectedGroup(group: Group) {
        this.selectedGroup = group;
    }
}
