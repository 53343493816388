

















import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';

@Component({
    name: 'OfflineChecker',
})
export default class OfflineChecker extends Vue {

    private isOnline: boolean = true;
    private eventTypes = ['online', 'offline', 'load'];

    private mounted() {
        // Register event listeners for the 3 types
        this.eventTypes.forEach(event =>
            window.addEventListener(event, this.updateOnlineStatus)
        );
    }

    private beforeDestroy() {
        // Remove event listeners for the 3 types
        this.eventTypes.forEach(event =>
            window.removeEventListener(event, this.updateOnlineStatus)
        );
    }

    private updateOnlineStatus() {
        this.isOnline = navigator.onLine || false;
    }

    private reload() {
        location.reload();
    }
}
