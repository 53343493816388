var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-table',{attrs:{"data":_vm.modules,"mobile-cards":true,"perPage":_vm.tableSettings.perPage,"opened-detailed":_vm.tableSettings.openedDetailed,"striped":_vm.tableSettings.isStriped,"paginated":_vm.tableSettings.isPaginated,"hoverable":_vm.tableSettings.isHoverable,"default-sort":"name","default-sort-direction":"asc"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('b-table-column',{attrs:{"field":"name","label":_vm.$t('keywords.name'),"sortable":""}},[_c('router-link',{attrs:{"to":{ name: 'ModuleViewer', query: { module_id: props.row.id } }}},[_vm._v(" "+_vm._s(props.row.name)+" ")]),(props.row.status.name !== 'purchased')?_c('span',{staticClass:"tag is-small is-danger is-pulled-right"},[_c('span',[_vm._v("Beta")])]):_vm._e()],1),_c('b-table-column',{attrs:{"field":"tools","label":_vm.$t('keywords.actions')}},[(
                        (props.row.status.name !== 'active' &&
                            props.row.status.name !== 'finished' &&
                            props.row.published_module_id === 0) ||
                            props.row.status.name === 'purchased'
                    )?_c('a',{staticClass:"button is-primary action-button",attrs:{"data-cy":'activateModuleButton_' + props.row.id},on:{"click":function($event){return _vm.showActivateModal(props.row)}}},[_c('span',{staticClass:"icon is-small"},[_c('i',{staticClass:"fas fa-graduation-cap"})]),_c('span',[_vm._v("Für Klasse aufschalten")])]):_vm._e(),(
                        props.row.status.name !== 'active' &&
                            props.row.status.name !== 'finished' &&
                            props.row.status.name !== 'purchased' &&
                            props.row.published_module_id !== 0
                    )?_c('p',[_c('a',{staticClass:"m-t-10 button is-primary action-button",attrs:{"target":"_blank","href":_vm.storeAppUrl + '/modules/' + props.row.published_module_id.toString(),"title":"Das Modul wurde mittlerweile im piQuest Store veröffentlicht. Diese Beta Version ist\n                        veraltet"}},[_c('span',{},[_vm._v("piQuest Store")])])]):_vm._e()]),_c('b-table-column',{attrs:{"field":"remove","label":_vm.$t('keywords.remove'),"centered":""}},[_c('a',{staticClass:"delete-icon",on:{"click":function($event){return _vm.showConfirmModal(props.row)}}},[_c('span',{staticClass:"icon"},[_c('i',{staticClass:"fa fa-trash"})])])])]}}])}),(_vm.isConfirmModalActive)?_c('detailed-confirm-modal',{attrs:{"name":_vm.selectedModule.name,"localizationKey":"module","affectedData":_vm.affectedData},on:{"toggleConfirmModal":_vm.toggleConfirmModal}}):_vm._e(),(_vm.isActivateModalActive)?_c('activate-module-modal',{attrs:{"module":_vm.selectedModule},on:{"toggleActivateModuleModal":_vm.toggleActivateModuleModal,"reload":_vm.reload}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }