




import { Component, Vue } from 'vue-property-decorator';
import { userWebservice } from '@/webservices/user';
import { demoViewMagicLogin } from '@/webservices/demoViewMagicLinkLogin';

@Component({
    name: 'DemoViewMagicLinkAutoLogin',
})
export default class DemoViewMagicLinkAutoLogin extends Vue {
    private demoViewToken: string = '';

    private mounted() {
        if (!this.$route.query.hasOwnProperty('token')) {
            this.$router.push({ name: 'Login' });
        }

        this.demoViewToken = this.$route.query.token.toString();
        demoViewMagicLogin
            .login(this.demoViewToken)
            .then((response) => {
                this.$store.commit('account/setApiToken', response.data.api_token);

                userWebservice.getUserInfo().then((getUserInfoResponse) => {
                    this.$store.commit('account/setCurrentUser', getUserInfoResponse);
                    this.$store.commit('account/setHaveAccess', true);
                    this.$router.push({ path: '/' });
                });
            })
            .catch(() => {
                this.$router.push({ name: 'Login' });
            });
    }
}
