import Vue from 'vue';
import Vuex, { MutationTree, ActionTree } from 'vuex';
import { Module } from '@/models/module';
import { Block } from '@/models/block';
import { Event } from '@/models/event';
import { moduleWebservice } from '@/webservices/module';
import { ModuleStatus } from '@/models/moduleStatus';
import { Subject } from '@/models/subject';
import { UserExtended } from '@/models/userextended';
import { ViewType } from '@/components/prine/enums/ViewType';
import { ModuleMetaData } from '@/models/moduleMetaData';

Vue.use(Vuex);

interface State {
    selectedModule: Module;

    activeModules: Module[];
    finishedModules: Module[];
    sharedModules: Module[];

    activeModulesCheckboxes: string[];
    ownModules: Module[];

    currentModuleViewType: ViewType;
}

const mutations: MutationTree<State> = {
    setSelectedModule: (currentState, newSelectedModule: Module) => (currentState.selectedModule = newSelectedModule),

    setActiveModules: (currentState, newActiveModules: Module[]) => (currentState.activeModules = newActiveModules),

    setFinishedModules: (currentState, newFinishedModules: Module[]) =>
        (currentState.finishedModules = newFinishedModules),

    setSharedModules: (currentState, newSharedModules: Module[]) => (currentState.sharedModules = newSharedModules),

    setOwnModules: (currentState, newOwnModules: Module[]) => (currentState.ownModules = newOwnModules),
    setCurrentModuleViewType: (currentState, newCurrentModuleViewType: ViewType) =>
        (currentState.currentModuleViewType = newCurrentModuleViewType),

    setActiveModulesCheckboxes: (currentState, checkboxes: string[]) =>
        (currentState.activeModulesCheckboxes = checkboxes),

    resetSelectedModule: (currentState) =>
        (currentState.selectedModule = {
            rows: 6,
            columns: 6,
            id: null,
            name: '',
            blocks: Array<Block>(),
            users: Array<UserExtended>(),
            description: '',
            status_id: 1, // Draft
            status: {} as ModuleStatus,
            admin_id: 0, // Don't forget to update admin_id
            blocks_count: 0,
            subject: {} as Subject,
            subject_id: 0,
            group_name: '',
            cover_path: '',
            event_id: 0,
            author: null,
            activeModules: [],
            event: {} as Event,
            events: [],
            metaData: null,
            module_meta_data_id: null,
            published_module_id: 0,
            questions_count: 0,
        }),
};

const actions: ActionTree<State, any> = {
    async reloadSelectedModule({ commit, state }) {
        try {
            const selectedModule = await moduleWebservice.getModuleById(state.selectedModule.id!);
            commit('setSelectedModule', selectedModule);
        } catch (error) {
            console.error(error);
        }
    },

    async fetchAllModules({ commit, state }) {
        try {
            this.dispatch('module/fetchActiveModules');
            this.dispatch('module/fetchFinishedModules');
            this.dispatch('module/fetchSharedModules');
            this.dispatch('module/fetchOwnModules');
        } catch (error) {
            console.error(error);
        }
    },

    async fetchActiveModules({ commit, state }) {
        try {
            const activeModules = await moduleWebservice.getActiveModules();
            commit('setActiveModules', activeModules);
        } catch (error) {
            console.error(error);
        }
    },

    async fetchFinishedModules({ commit, state }) {
        try {
            const finishedModule = await moduleWebservice.getFinishedModules();
            commit('setFinishedModules', finishedModule);
        } catch (error) {
            console.error(error);
        }
    },

    async fetchSharedModules({ commit, state }) {
        try {
            const sharedModules = await moduleWebservice.getSharedModules();
            commit('setSharedModules', sharedModules);
        } catch (error) {
            console.error(error);
        }
    },

    async fetchOwnModules({ commit, state }) {
        try {
            const ownModules = await moduleWebservice.getDraftModules();
            commit('setOwnModules', ownModules);
        } catch (error) {
            console.error(error);
        }
    },
};

const getters = {
    selectedModule(currentState: State) {
        return currentState.selectedModule;
    },

    activeModules(currentState: State) {
        return currentState.activeModules;
    },

    finishedModules(currentState: State) {
        return currentState.finishedModules;
    },

    sharedModules(currentState: State) {
        return currentState.sharedModules;
    },

    ownModules(currentState: State) {
        return currentState.ownModules;
    },

    activeModulesCheckboxes(currentState: State) {
        return currentState.activeModulesCheckboxes;
    },

    currentModuleViewType(currentState: State) {
        return currentState.currentModuleViewType;
    },
};

const defaultState: State = {
    selectedModule: {
        rows: 6,
        columns: 6,
        id: 0,
        name: '',
        blocks: Array<Block>(),
        users: Array<UserExtended>(),
        description: '',
        status_id: 1, // Draft
        status: {} as ModuleStatus,
        admin_id: 0, // Don't forget to update admin_id
        blocks_count: 0,
        subject: {} as Subject,
        subject_id: 0,
        group_name: '',
        cover_path: '',
        event_id: 0,
        author: null,
        activeModules: [],
        event: {} as Event,
        events: [],
        metaData: null,
        module_meta_data_id: null,
        published_module_id: 0,
        questions_count: 0,
    },
    activeModules: [],
    sharedModules: [],
    finishedModules: [],
    ownModules: [],
    activeModulesCheckboxes: ['Aktive'],
    currentModuleViewType: ViewType.Grid,
};

const module = {
    namespaced: true,
    state: defaultState,
    getters,
    mutations,
    actions,
};

export default module;
