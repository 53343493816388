import axios from 'axios';
import { Block } from '../models/block';

class BlockWebservice {
    public getBlocksByModuleId(module_id: number) {
        return axios.get('/blocks', {
            params: {
                module_id,
            },
        });
    }

    public store(block: Block) {
        return axios.post('/blocks/store', {
            name: block.name,
            description: block.description,
            order: block.order,
            module_id: block.module_id,
        });
    }

    public update(block: Block) {
        return axios.post('/blocks/update', {
            id: block.id,
            name: block.name,
            order: block.order,
            description: block.description,
        });
    }

    public remove(block: Block) {
        return axios.post('/blocks/remove', {
            id: block.id,
        });
    }
}

export const blockWebservice = new BlockWebservice();
