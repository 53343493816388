








import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import { Module } from '@/models/module';
import ActiveModuleCard from './ActiveModuleCard.vue';

@Component({
    name: 'ActiveModuleGrid',
    components: {
        ActiveModuleCard,
    },
})
export default class ActiveModuleGrid extends Vue {
    @Prop() private modules: Module[];
}
