

















































































import axios from 'axios';
import router from 'vue-router';
import { User } from '../../models/user';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { userWebservice } from '../../webservices/user';
@Component({
    name: 'ResetPassword',
})
export default class ResetPassword extends Vue {
    // Data
    private user: User = {
        id: 0,
        firstname: '',
        lastname: '',
        email: '',
        new_email: '',
        email_confirmation: '',
        password: '',
        password_confirmation: '',
        new_password: '',
        email_confirmed: false,
        activated: false,
        role_id: 0,
        goal: 0,
        show_tour: false,
    };
    private disabled: boolean = false;
    private passwordChanged: boolean = false;
    private errorMsg: string = '';
    private token: string = '';

    private mounted() {
        // console.log('ResetPassword mounted');
        if (this.$route.query.hasOwnProperty('token')) {
            this.token = this.$route.query.token.toString();
        }
    }

    // Methods
    private resetPassword() {
        if (this.user.password === '') {
            this.errorMsg = 'Bitte Passwort eingeben!';
            return 0;
        } else if (this.user.password.length < 8) {
            this.errorMsg = 'Das Passwort muss mindestens 8 Zeichen lang sein!';
            return 0;
        } else if (this.user.password_confirmation === '') {
            this.errorMsg = 'Bitte bestätigen Sie Ihr Passwort!';
            return 0;
        } else if (this.user.password_confirmation !== this.user.password) {
            this.errorMsg = 'Die Passwörter stimmen nicht überein!';
            return 0;
        }

        const self = this;
        self.disabled = true;
        self.errorMsg = '';

        userWebservice
            .resetPassword(this.token, this.user)
            .then((response) => {
                self.errorMsg = '';
                self.passwordChanged = true;
                setTimeout(() => {
                    self.$router.push({ path: '/auth/login' });
                }, 3000);
            })
            .catch((error) => {
                self.disabled = false;
                self.errorMsg = 'Ein Fehler ist aufgetreten, bitte versuchen Sie es erneut!';
                console.log(error);
            });
    }

    private validateEmail(email: string) {
        /* tslint:disable-next-line:max-line-length */
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email.toLowerCase());
    }
}
