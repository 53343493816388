








import { Component, Prop, Vue } from 'vue-property-decorator';
import { Module } from '@/models/module';
import { moduleWebservice } from '@/webservices/module';

@Component({
    components: {},
})
export default class OwnModuleDuplicateButton extends Vue {
    private newTitle: string = '';
    @Prop() private moduleId: number;

    private duplicatePrompt() {
        this.$buefy.dialog.prompt({
            message: 'Geben Sie einen neuen Namen für das kopierte Modul ein',
            inputAttrs: {
                placeholder: 'z.B. Mein kopiertes Modul',
                minLength: 1,
            },
            confirmText: 'Modul kopieren',
            trapFocus: true,
            onConfirm: async (value) => {
                this.newTitle = value;
                await this.duplicateModule();
                this.$emit('reloadModules');
            },
        });
    }

    private async duplicateModule() {
        await moduleWebservice.duplicate(this.moduleId, this.newTitle);
    }
}
