import axios from 'axios';
import { Module } from '../models/module';
import HttpException from './HttpException';
import { Group } from '@/models/group';
import { Subject } from '@/models/subject';
import { QuestionStats } from '@/models/questionstats';
import { UserStats } from '@/models/userstats';
import { User } from '@/models/user';
import { Tag } from '@/models/tag';

class ModuleWebservice {
    public async getModuleById(id: number) {
        try {
            const response = await axios.get<Module>('/modules/byId', {
                params: {
                    id,
                },
            });

            return response.data;
        } catch (error) {
            throw new HttpException(error.response.status, 'There was an error fetching the module by an id');
        }
    }

    public async getModuleWithEvaluationsById(id: number, event_id: number) {
        try {
            const response = await axios.get<Module>('/modules/withEvaluationsById', {
                params: {
                    id,
                    event_id,
                },
            });

            return response.data;
        } catch (error) {
            throw new HttpException(error.response.status, 'There was an error fetching the module by an id');
        }
    }

    public getUserModules() {
        return axios.get<Module[]>('/modules');
    }

    public async getDraftModules() {
        try {
            const response = await axios.get<Module[]>('/modules/draft');

            return response.data;
        } catch (error) {
            throw new HttpException(error.response.status, 'There was an error fetching the draft modules');
        }
    }

    public async getPublishedModules() {
        try {
            const response = await axios.get<Module[]>('/modules/published');

            return response.data;
        } catch (error) {
            throw new HttpException(error.response.status, 'There was an error fetching the published modules');
        }
    }

    public async getSharedModules() {
        try {
            const response = await axios.get<Module[]>('/modules/shared');

            return response.data;
        } catch (error) {
            throw new HttpException(error.response.status, 'There was an error fetching the shared modules');
        }
    }

    public async getPurchasedModules() {
        try {
            const response = await axios.get<Module[]>('/modules/purchased');

            return response.data;
        } catch (error) {
            throw new HttpException(error.response.status, 'There was an error fetching the purchased modules');
        }
    }

    public async getFinishedModules() {
        try {
            const response = await axios.get<Module[]>('/modules/finished');

            return response.data;
        } catch (error) {
            throw new HttpException(error.response.status, 'There was an error fetching the finished modules');
        }
    }

    public async getActiveModules() {
        try {
            const response = await axios.get<Module[]>('/modules/active');

            return response.data;
        } catch (error) {
            throw new HttpException(error.response.status, 'There was an error fetching the active modules');
        }
    }

    public store(module: Module) {
        return axios.post('/modules/store', {
            name: module.name,
            description: module.description,
        });
    }

    public publish(module: Module) {
        if (module.metaData) {
            module.metaData.tag_ids = module.metaData.tags.map((tag: any) => tag.id);
            module.metaData.tags = [] as Tag[];
        }
        return axios.post('/modules/publish', {
            id: module.id,
            metaData: module.metaData,
        });
    }

    public activate(module: Module, group: Group, subject: Subject) {
        return axios.post('/modules/activate', {
            id: module.id,
            group_id: group.id,
            subject_id: subject.id,
        });
    }

    public purchase(module: Module) {
        return axios.post('/modules/purchase', {
            id: module.id,
            admin_id: module.admin_id,
        });
    }

    public update(module: Module) {
        return axios.post('/modules/update', {
            name: module.name,
            description: module.description,
            id: module.id,
            status_id: module.status_id,
            rows: module.rows,
            columns: module.columns,
        });
    }

    public remove(module: Module) {
        return axios.post('/modules/remove', {
            id: module.id,
        });
    }

    public toggleFinished(module: Module) {
        return axios.post('/modules/toggleFinished', {
            module_id: module.id,
        });
    }

    public uploadPicture(module: Module, formData: FormData) {
        return axios.post('/modules/updateCover', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    }

    public async loadUsersByTopic(event_id: number, topic_id: number) {
        try {
            const response = await axios.get<UserStats[]>('/modules/loadUsersByTopic', {
                params: {
                    event_id,
                    topic_id,
                },
            });

            return response.data;
        } catch (error) {
            throw new HttpException(error.response.status, 'There was an error fetching the users stats by an id');
        }
    }

    public async loadQuestionsByTopic(event_id: number, topic_id: number) {
        try {
            const response = await axios.get<QuestionStats[]>('/modules/loadQuestionsByTopic', {
                params: {
                    event_id,
                    topic_id,
                },
            });

            return response.data;
        } catch (error) {
            throw new HttpException(error.response.status, 'There was an error fetching the question stats by an id');
        }
    }

    public async share(module_id: number, to_admin_id: number) {
        try {
            const response = await axios.post('/modules/share', {
                id: module_id,
                to_admin_id,
            });

            return response.data;
        } catch (error) {
            throw new HttpException(error.response.status, 'There was an error sharing the module');
        }
    }

    public async sharedUsers(module_id: number) {
        try {
            const response = await axios.get<User[]>('/modules/sharedUsers', {
                params: {
                    id: module_id,
                },
            });
            return response.data;
        } catch (error) {
            throw new HttpException(error.response.status, 'No user was found');
        }
    }

    public async duplicate(module_id: number, new_title: string) {
        try {
            const response = await axios.post('/modules/duplicate', {
                module_id,
                new_title,
            });

            return response.data;
        } catch (error) {
            throw new HttpException(error.response.status, 'There was an error duplicating the module');
        }
    }

    public async getStudentProgressReport(module_id: number, user_id: number) {
        try {
            const response = await axios.get('/modules/getStudentProgressReport', {
                params: {
                    module_id,
                    user_id,
                },
            });
            return response.data;
        } catch (error) {
            throw new HttpException(error.response.status, 'Error loading progress report');
        }
    }
}

export const moduleWebservice = new ModuleWebservice();
