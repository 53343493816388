






















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';

import GridTable from '@/components/prine/gridtable/GridTable.vue';
import GridElementTopic from '@/components/module/viewer/elements/topic/GridElementTopic.vue';
import GridElementBlock from '@/components/module/viewer/elements/block/GridElementBlock.vue';
import { moduleWebservice } from '@/webservices/module';

import { Topic } from '@/models/topic';
import { Block } from '@/models/block';
import { Module } from '@/models/module';
import { User } from '@/models/user';
import { Question } from '@/models/question';

@Component({
    name: 'ModuleViewer',
    components: {
        GridTable,
        GridElementTopic,
        GridElementBlock,
    },
})
export default class ModuleViewer extends Vue {
    @Getter('account/currentUser') private currentUser: User;
    @Getter('module/selectedModule') private module: Module;
    @Action('module/reloadSelectedModule') private reloadSelectedModule: any;

    private loaded: boolean = false;
    private goal: number = 4;

    private data = {
        header: new Array<Block>(),
        body: new Array<Topic>(),
    };

    @Watch('module')
    private onModuleValueChange() {
        this.data = this.initializeModule();
    }

    private async mounted() {
        // Call load module if there is a module_id in the url
        if (this.$route.query.hasOwnProperty('module_id')) {
            await this.loadModuleById(Number(this.$route.query.module_id));
        } else {
            // There could be already a module from earlier set. We need to overwrite this
            this.$store.commit('module/resetSelectedModule');
            this.data = this.initializeModule();
            this.loaded = true;
        }
    }

    /**
     * Load the module by a given id
     */
    private async loadModuleById(id: number) {
        const loadedModule = await moduleWebservice.getModuleById(id);

        this.$store.commit('module/setSelectedModule', loadedModule);

        this.data = this.initializeModule();
        this.loaded = true;
    }

    private initializeModule() {
        const blocks = new Array<Block>();
        let allTopics = new Array<Topic>();

        // CREATE HEADER data
        // Create all blocks
        for (let columnNumber = 1; columnNumber <= this.module.columns; columnNumber++) {
            let block: Block | undefined = this.module.blocks.find((el) => {
                return el.order === columnNumber;
            });

            // Create the block if it wasn't found
            if (block === undefined) {
                block = {} as Block;
                block.id = null;
                block.order = columnNumber;
            }

            // Set reference to topics as well and push it to the array
            blocks.push(block);
        }

        const topics = new Array<Topic>();

        // CREATE BODY data
        // Iterate over all rows and columns and fetch or create the topic
        for (let rowNumber = 1; rowNumber <= this.module.rows; rowNumber++) {
            // Create the topics and connect them to the blocks
            for (let columnNumber = 1; columnNumber <= this.module.columns; columnNumber++) {
                const block: Block | undefined = this.module.blocks.find((el) => {
                    return el.order === columnNumber;
                });

                let topic: Topic | undefined;

                // Fetch the topic in the given block if there is an element at that order (position)
                if (block !== undefined && block.hasOwnProperty('topics') && block.topics.length > 0) {
                    topic = block.topics.find((el) => {
                        return el.order === rowNumber;
                    });
                }

                // Create the topic if it wasn't found
                if (topic === undefined) {
                    topic = {} as Topic;
                    topic.id = null;
                    topic.questions = Array<Question>();
                    topic.order = rowNumber;
                }

                topics.push(topic);
            }
        }

        // Store all topics together in this array
        allTopics = allTopics.concat(topics);

        // Prepare the data for the grid-table
        const data = {
            header: blocks,
            body: allTopics,
        };

        return data;
    }
}
