















































import { Component, Vue } from 'vue-property-decorator';
import { ViewType } from '@/components/prine/enums/ViewType';

@Component({
    name: 'ModuleTab',
})
export default class ModuleTab extends Vue {
    private ViewType = ViewType;

    private isSideBarClosed: boolean = false;

    private get viewState() {
        return this.$store.state.module.currentModuleViewType;
    }

    private set viewState(value) {
        this.$store.commit('module/setCurrentModuleViewType', value);
    }

    private toggleGridAndListView() {
        if (this.viewState === ViewType.Grid) {
            this.viewState = ViewType.List;
        } else {
            this.viewState = ViewType.Grid;
        }
    }

    private loadGridView() {
        this.viewState = ViewType.Grid;
    }

    private loadListView() {
        this.viewState = ViewType.List;
    }

    private toggleSideBar() {
        this.isSideBarClosed = !this.isSideBarClosed;
    }
}
