



















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    name: 'BackToTop',
})
export default class BackToTop extends Vue {
    @Prop({
        type: Number,
        default: 0,
    })
    private bottom: number;

    @Prop({
        type: Number,
        default: 0,
    })
    private right: number;

    @Prop({
        type: String,
        default: '0',
    })
    private visibleoffsetbottom: string;

    @Prop({
        type: String,
        default: '0',
    })
    private visibleoffset: string;

    @Prop({
        type: String,
        default: 'Top',
    })
    private text: string;

    private visible: boolean = false;

    private mounted() {
        window.addEventListener('scroll', this.catchScroll);
    }

    private destroyed() {
        window.removeEventListener('scroll', this.catchScroll);
    }

    private catchScroll() {
        const pastTopOffset = window.pageYOffset > parseInt(this.visibleoffset);
        const pastBottomOffset =
            window.innerHeight + window.pageYOffset >= document.body.offsetHeight - parseInt(this.visibleoffsetbottom);
        this.visible = parseInt(this.visibleoffsetbottom) > 0 ? pastTopOffset && !pastBottomOffset : pastTopOffset;
    }

    private backToTop() {
        this.smoothscroll();
        this.$emit('scrolled');
    }

    private smoothscroll() {
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    }
}
