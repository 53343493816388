







































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';

import { Topic } from '@/models/topic';
import { TopicType } from '@/models/topicType';
import { Question } from '@/models/question';
import { topicWebservice } from '@/webservices/topic';
import { topicTypeWebservice } from '@/webservices/topicType';
import { GridElementPosition } from '@/components/prine/gridtable/GridElementPosition';
import Modal, { ModalOptions } from '@/components/prine/modal/Modal.vue';
import { FormState } from '@/components/prine/enums/FormState';
import { Block } from '@/models/block';
import QuestionManager from './QuestionManager.vue';
import * as R from 'ramda';
import { HtmlHelper } from '@/components/prine/helpers/HtmlHelper';
import DetailedConfirmModal from '@/components/prine/DetailedConfirmModal.vue';

@Component({
    name: 'CreateTopicModal',
    components: {
        Modal,
        QuestionManager,
        DetailedConfirmModal,
    },
})
export default class CreateTopicModal extends Vue {
    public FormState = FormState;
    private htmlHelper: HtmlHelper = new HtmlHelper();

    private state: FormState = FormState.New;
    private topicTypes: TopicType[] = [];

    private topicData = {
        questions: Array<Question>(),
    } as Topic;

    private errorMsg: string = '';
    private disabled: boolean = false;

    private affectedData: Map<string, number> = new Map();
    private isConfirmModalActive: boolean = false;
    private isDeleteQuestionsConfirmModalActive: boolean = false;
    private questionDeleted: boolean = false;

    private modalOptions: ModalOptions = {
        width: 1200,
        height: null,
        showDelete: true,
        isLoading: false,
        saveButtonText: 'Speichern',
        saveDisabled: false,
    };

    @Prop() private position: GridElementPosition;
    @Prop() private topic: Topic;
    @Prop() private block: Block;

    @Action('module/reloadSelectedModule') private reloadSelectedModule: any;

    private async mounted() {
        this.htmlHelper.setFocusById('title');

        this.topicData = JSON.parse(JSON.stringify(this.topic));
        this.topicData.order = this.position.row;
        this.topicData.block_id = this.block.id;

        if (this.topicData.hasOwnProperty('id') && this.topicData.id !== null) {
            this.state = FormState.Update;
            this.modalOptions.showDelete = true;
        }

        // Load all topic types
        this.topicTypes = await topicTypeWebservice.getAll();

        // Preselect the first if not yet set
        if (R.isNil(this.topicData.topic_type) || R.isEmpty(this.topicData.topic_type)) {
            this.topicData.topic_type = R.findLast(R.propEq('name', 'normal'))(this.topicTypes) as TopicType | null;
        }
    }

    private toggleModal() {
        this.$emit('toggleCreateTopicModal');
    }

    private store() {
        if (R.isNil(this.topicData.title) || R.isEmpty(this.topicData.title)) {
            this.errorMsg = 'title';
            return 0;
        }

        this.modalOptions.isLoading = true;

        if (this.state === FormState.Update) {
            if (this.questionDeleted) {
                this.toggleDeleteQuestionsConfirmModal();
            } else {
                this.updateTopic();
            }
        } else {
            this.storeTopic();
        }
    }

    private storeTopic() {
        this.removeEmptyQuestions();

        topicWebservice.store(this.topicData).then((response) => {
            this.block.topics.push(response.data);
            this.toggleModal();
            this.reloadSelectedModule();
            this.modalOptions.isLoading = false;
        });
    }

    private updateTopic() {
        this.removeEmptyQuestions();
        topicWebservice.update(this.topicData).then((response) => {
            this.toggleModal();
            this.reloadSelectedModule();
            this.modalOptions.isLoading = false;
        });
    }

    private removeEmptyQuestions() {
        // filtering out the empty questions
        this.topicData.questions = this.topicData.questions.filter(function(question) {
            return question.title !== '';
        });
    }

    private async toggleConfirmModal(answer: boolean) {
        console.log('toggleConfirmModal triggered');
        if (this.topic.questions.length === 0 || answer === true) {
            this.remove();
        } else {
            this.isConfirmModalActive = !this.isConfirmModalActive;
        }
    }

    private async toggleDeleteQuestionsConfirmModal(answer: boolean = false) {
        console.log('toggleDeleteQuestionsConfirmModal triggered');
        if ((this.topic.questions.length === 0 && !this.questionDeleted) || answer === true) {
            this.updateTopic();
        } else {
            this.isDeleteQuestionsConfirmModalActive = !this.isDeleteQuestionsConfirmModalActive;
        }
    }

    private remove() {
        this.modalOptions.isLoading = true;
        topicWebservice.remove(this.topicData).then((response) => {
            this.toggleModal();
            this.reloadSelectedModule();
            this.modalOptions.isLoading = false;
        });
    }

    private get modalTitle() {
        if (this.state === FormState.New) {
            return this.position.column + '.' + this.position.row + ' ' + this.$t('labels.newTopic').toString();
        } else {
            return this.position.column + '.' + this.position.row + ' ' + this.$t('labels.updateTopic').toString();
        }
    }
}
