



















import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';

import GridTable from '@/components/prine/gridtable/GridTable.vue';
import GridElementTopic from '@/components/module/stats/module-progress-student/elements/topic/GridElementTopic.vue';

import { Topic } from '@/models/topic';
import { Block } from '@/models/block';
import { Module } from '@/models/module';
import { User } from '@/models/user';
import { Question } from '@/models/question';

@Component({
    name: 'ModuleProgressStudentGridTable',
    components: {
        GridTable,
        GridElementTopic,
    },
})
export default class ModuleProgressStudentGridTable extends Vue {
    @Getter('module/selectedModule') private module: Module;

    @Prop() private user: User;

    private loaded: boolean = false;

    private data = {
        header: new Array<Block>(),
        body: new Array<Topic>(),
    };

    private mounted() {
        // The grid table needs a certain structure of the data (header, body)
        this.data = this.initializeModule();
    }

    private initializeModule() {
        const blocks = new Array<Block>();
        let allTopics = new Array<Topic>();

        // CREATE HEADER data
        // Create all blocks
        for (let columnNumber = 1; columnNumber <= this.module.columns; columnNumber++) {
            let block: Block | undefined = this.module.blocks.find((el) => {
                return el.order === columnNumber;
            });

            // Create the block if it wasn't found
            if (block === undefined) {
                block = {} as Block;
                block.id = null;
                block.order = columnNumber;
            }

            // Set reference to topics as well and push it to the array
            blocks.push(block);
        }

        const topics = new Array<Topic>();

        // CREATE BODY data
        // Iterate over all rows and columns and fetch or create the topic
        for (let rowNumber = 1; rowNumber <= this.module.rows; rowNumber++) {
            // Create the topics and connect them to the blocks
            for (let columnNumber = 1; columnNumber <= this.module.columns; columnNumber++) {
                const block: Block | undefined = this.module.blocks.find((el) => {
                    return el.order === columnNumber;
                });

                let topic: Topic | undefined;

                // Fetch the topic in the given block if there is an element at that order (position)
                if (block !== undefined && block.hasOwnProperty('topics') && block.topics.length > 0) {
                    topic = block.topics.find((el) => {
                        return el.order === rowNumber;
                    });
                }

                // Create the topic if it wasn't found
                if (topic === undefined) {
                    topic = {} as Topic;
                    topic.id = null;
                    topic.questions = Array<Question>();
                    topic.order = rowNumber;
                }

                topics.push(topic);
            }
        }

        // Store all topics together in this array
        allTopics = allTopics.concat(topics);

        // Prepare the data for the grid-table
        const data = {
            header: blocks,
            body: allTopics,
        };

        return data;
    }
}
