




























































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import moment from 'moment';
import { Module } from '@/models/module';
import { Event } from '@/models/event';
import { moduleWebservice } from '@/webservices/module';
import { eventWebservice } from '@/webservices/event';

import DetailedConfirmModal from '@/components/prine/DetailedConfirmModal.vue';
import ActivateModuleModal from '@/components/module/manager/ActivateModuleModal.vue';

@Component({
    name: 'SharedModuleList',
    components: {
        DetailedConfirmModal,
        ActivateModuleModal,
    },
})
export default class SharedModuleList extends Vue {
    @Prop() private modules: Module[];

    private affectedData: Map<string, number> = new Map();
    private isConfirmModalActive: boolean = false;
    private isActivateModalActive: boolean = false;
    private selectedModule: Module = {} as Module;

    private events = [] as Event[];
    private tableSettings = {
        perPage: 15,
        isStriped: true,
        isHoverable: true,
        isPaginated: false,
    };

    @Action('module/fetchAllModules')
    private fetchAllModules: any;

    private reload() {
        this.fetchAllModules();
    }

    private get storeAppUrl() {
        return process.env.VUE_APP_STORE_URL;
    }

    private async publish(module: Module) {
        await moduleWebservice.publish(module);
        this.reload();
    }

    private showConfirmModal(module: Module) {
        this.selectedModule = module;
        this.isConfirmModalActive = true;
    }

    private async toggleConfirmModal(answer: boolean) {
        if (answer === true) {
            await moduleWebservice.remove(this.selectedModule);
            this.isConfirmModalActive = !this.isConfirmModalActive;
            this.reload();
        } else {
            this.isConfirmModalActive = !this.isConfirmModalActive;
        }
    }

    private toggleFinished(module: Module) {
        moduleWebservice.toggleFinished(module).then((res) => {
            this.reload();
        });
    }

    private toggleActivateModuleModal() {
        this.isActivateModalActive = !this.isActivateModalActive;
    }

    private showActivateModal(module: Module) {
        this.selectedModule = module;
        this.isActivateModalActive = true;
    }
}
