
































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Group } from '../../models/group';
import { groupWebservice } from '../../webservices/group';
import ConfirmModal from './../prine/ConfirmModal.vue';
import { FormState } from './../prine/enums/FormState';

@Component({
    name: 'CreateGroupModal',
    components: {
        ConfirmModal,
    },
})
export default class CreateGroupModal extends Vue {
    private FormState = FormState;

    private group: Group = {
        name: '',
        token: '',
        id: 0,
        active_users: [],
    };
    private isConfirmModalActive: boolean = false;
    private errorMsg: string = '';
    private serverError: string = '';
    private state: FormState = FormState.New;

    @Prop() private selectedGroup: Group;

    // Mounted is called initially
    private mounted() {
        if (this.selectedGroup && this.selectedGroup.hasOwnProperty('name')) {
            // this.subject =this.selectedSubject;
            this.group = JSON.parse(JSON.stringify(this.selectedGroup));
            this.state = FormState.Update;
        }
    }

    private store() {
        if (this.group.name === '') {
            this.errorMsg = 'name';
            return false;
        }
        const self = this;
        if (this.state === FormState.New) {
            groupWebservice
                .store(this.group.name)
                .then(function(response) {
                    self.toggleModal();
                })
                .catch(function(error) {
                    self.serverError = error.response.data;
                    console.log(error);
                });
        } else if (this.state === FormState.Update) {
            groupWebservice
                .update(this.group)
                .then(function(response) {
                    self.toggleModal();
                })
                .catch(function(error) {
                    self.serverError = error.response.data;
                    console.log(error);
                });
        }
    }

    private fetchGroups() {
        this.$emit('fetchGroups', this.group.name);
    }

    private toggleModal() {
        this.fetchGroups();
        this.$emit('toggleCreateGroupModal');
    }

    private toggleConfirmModal() {
        this.isConfirmModalActive = !this.isConfirmModalActive;
    }
}
