




































import router from 'vue-router';
import { User } from '@/models/user';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

@Component({
    name: 'AccountNotActivated',
})
export default class AccountNotActivated extends Vue {
    @Getter('account/loggedIn') private loggedIn: boolean;
    @Getter('account/currentUser') private currentUser: User;
    @Getter('account/isActivated') private isActivated: User;

    @Watch('isActivated')
    private onIsActivatedChange() {
        if (this.isActivated) {
            setTimeout(() => {
                this.$router.push({ path: '/' });
            }, 3000);
        }
    }

    private get storeAppUrl() {
        return process.env.VUE_APP_STORE_URL;
    }
}
