



















































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import ModuleProgressStudentBox from './ModuleProgressStudentBox.vue';
import { User } from '@/models/user';
import { Module } from '@/models/module';

import { moduleWebservice } from '@/webservices/module';
import html2canvas from 'html2canvas';
import { jsPDF, jsPDFOptions } from 'jspdf';
import moment from 'moment';
import * as log from 'loglevel';
import { Base64Helper } from '@/components/prine/helpers/Base64Helper';

interface StudentReport {
    days_worked_count: number;
    evaluations_count: number;
    progress_percentage: number;
    questions_count: number;
    carefully_evaluated_percentage: number;
}

@Component({
    name: 'ModuleProgressStudentContainer',
    components: {
        ModuleProgressStudentBox,
    },
})
export default class ModuleProgressStudentContainer extends Vue {
    private module_id: number = 0;
    private event_id: number = 0;
    private loaded: boolean = false;
    private isSearching: boolean = false;
    private generatingPDF: boolean = false;
    private interruptGeneratingPDF: boolean = false;

    private searchQuery: string = '';
    private filteredUsers: User[] = new Array<User>();
    private originalUsers: User[] = new Array<User>();
    private pdfSaveFileName: string = '';
    private progressBarWidth: number = 0;
    private canvases: number = 0;
    private numberOfStudents: number = 0;

    private pdfPageWidth: number = 1240;
    private pdfPageHeight: number = 1754;
    private pdfTopLeftPadding: number = 40;
    private pdfLeftPadding: number = 80;
    private pdfTopPadding: number = 80;

    private pdfPageOptions: jsPDFOptions = {
        format: [this.pdfPageWidth, this.pdfPageHeight], // A4 150 DPI
        unit: 'px',
        orientation: 'portrait',
    };
    private progressBoxWidth: number = 24;
    private progressBoxHeight: number = 20;
    private columnSize: string = 'is-3';
    private base64Helper: Base64Helper = new Base64Helper();

    @Getter('module/selectedModule') private module: Module;

    @Watch('searchQuery')
    private onSearchQueryValueChange() {
        this.search();
    }

    private async mounted() {
        // Call load module if there is a module_id in the url
        if (this.$route.query.hasOwnProperty('module_id') && this.$route.query.hasOwnProperty('event_id')) {
            this.module_id = Number(this.$route.query.module_id);
            this.event_id = Number(this.$route.query.event_id);

            // Store as current selected module
            const loadedModule = await moduleWebservice.getModuleWithEvaluationsById(this.module_id, this.event_id);
            this.$store.commit('module/setSelectedModule', loadedModule);

            this.filteredUsers = JSON.parse(JSON.stringify(this.module.users));
            this.originalUsers = JSON.parse(JSON.stringify(this.module.users));

            this.progressBoxWidth += this.module.columns * 40;
            this.progressBoxHeight += this.module.rows * 35;

            this.loaded = true;
        }
    }

    private search() {
        this.isSearching = true;

        const regExQuery = new RegExp(this.searchQuery.trim(), 'i');

        let originalUsersCopy = new Array<User>();
        originalUsersCopy = JSON.parse(JSON.stringify(this.originalUsers));

        this.filteredUsers = originalUsersCopy.filter((user: User) => {
            const userMatch = user.firstname.search(regExQuery) !== -1 || user.lastname.search(regExQuery) !== -1;
            return userMatch;
        });

        this.isSearching = false;
    }

    private clearSearch() {
        // Reset the search query
        this.searchQuery = '';
    }

    // start of print methods

    private async exportToPdf() {
        const pdf = new jsPDF(this.pdfPageOptions);

        this.pdfSaveFileName = 'Student_report_[' + moment().format('Do-MMMM-YYYYTHH-mm-ss') + '].pdf';

        this.numberOfStudents = this.filteredUsers.length;

        log.debug(
            '%cNumber of Students: ' + this.numberOfStudents,
            'color: #00ff00; font-style: bold; font-size: 22px'
        );

        const i = 0;
        if (this.generatingPDF === true) {
            return false;
        }

        this.generatingPDF = true;
        this.progressBarWidth = 0;

        const currentDateTime = moment().format('DD.MM.YYYY');
        const pageNumber = 1;

        const logoElement = document.getElementById('piquest-logo') as HTMLImageElement;
        const logoImgData = this.base64Helper.getBase64Image(logoElement);

        // Setting column size to 12
        // fix blank pdf page issue in smaller screens
        this.columnSize = 'is-12';
        setTimeout(() => {
            this.convertHtml2canvas(0, pdf, currentDateTime, pageNumber, logoImgData);
        }, 1000);
    }

    private async convertHtml2canvas(
        i = 0,
        pdf: jsPDF,
        currentDateTime: string,
        pageNumber: number,
        logoImgData: string
    ) {
        // check if user wants to interrupt the proccess
        if (this.interruptGeneratingPDF) {
            this.generatingPDF = false;
            this.interruptGeneratingPDF = false;
            this.columnSize = 'is-3';
            this.progressBarWidth = 0;
            return;
        }

        // load student progress report (progress percentage, days worked, ...)
        const studentReport: StudentReport = await moduleWebservice.getStudentProgressReport(
            this.module_id,
            this.filteredUsers[i].id
        );

        setTimeout(() => {
            const student_container_el_id = 'progress-box-container-' + i;
            const student_progress_el_id = 'progress-box-' + i;
            const student_name_el_id = 'student-name-container-' + i;

            // const student_element: HTMLElement | null = document.getElementById(student_container_el_id);
            const student_element: HTMLElement | null = document
                .getElementById(student_progress_el_id)
                ?.getElementsByClassName('card-content')[0] as HTMLElement;

            if (!student_element) {
                this.generatingPDF = false;
                return;
            }

            html2canvas(student_element, {
                width: this.progressBoxWidth,
                height: this.progressBoxHeight,
                scale: 1.4, // different browsers use different scale if not set.
                backgroundColor: '#FFFFFF',
                logging: false,
                foreignObjectRendering: false,
                removeContainer: true,
                onclone: (clonedDoc) => {
                    // Document is cloned
                    // here we can modify the cloned document elements
                    // any modification can be done and will not affect the real document
                    const cloned_student_progress_el = clonedDoc.getElementById(student_progress_el_id);

                    if (cloned_student_progress_el) {
                        cloned_student_progress_el.style.width = this.progressBoxWidth + 'px';
                        cloned_student_progress_el.style.height = this.progressBoxHeight + 'px';
                        cloned_student_progress_el.style.backgroundColor = '#fff';
                        cloned_student_progress_el.style.padding = '0px';
                        cloned_student_progress_el.style.paddingBottom = '0px';
                        cloned_student_progress_el.style.margin = '10px';
                        cloned_student_progress_el.style.border = '0px';
                        cloned_student_progress_el.style.opacity = '1';
                        cloned_student_progress_el.style.border = '1px solid #d8d8d8';
                    }
                },
            }).then(async (canvas) => {
                const imgData = canvas.toDataURL('image/png', 1);
                const canvasWidth = canvas.width;
                const canvasHeight = canvas.height;

                const cloned_student_name_el_id = document.getElementById(student_name_el_id);

                pdf.addImage(logoImgData, 'png', this.pdfPageWidth - 160, 30, 130, 170);

                pdf.setFont('NexaHeavy', 'normal');
                pdf.setTextColor(25, 54, 81);
                pdf.setFontSize(94);
                pdf.text(this.module.name.toUpperCase(), this.pdfLeftPadding, 280);

                pdf.setFont('NexaBold', 'normal');
                pdf.setTextColor(70, 70, 70);

                pdf.setFontSize(30);
                pdf.text(
                    'BEARBEITETE AUFTRÄGE: ' + studentReport.progress_percentage + '%',
                    this.pdfLeftPadding + 740,
                    450
                );
                pdf.text('ARBEITSTAGE: ' + studentReport.days_worked_count, this.pdfLeftPadding + 740, 500);
                pdf.text(
                    'RELIABILITY: ' + studentReport.carefully_evaluated_percentage + '%',
                    this.pdfLeftPadding + 740,
                    550
                );
                pdf.setFontSize(16);
                pdf.text('Anteil der Aufgaben, bei denen Du mehr als 5 Sekunden', this.pdfLeftPadding + 740, 570);
                pdf.text('investiert hast.', this.pdfLeftPadding + 740, 585);

                pdf.setFontSize(38);
                pdf.text('NAME:', this.pdfLeftPadding, 450);
                pdf.text('' + cloned_student_name_el_id?.innerText, this.pdfLeftPadding + 130, 450);

                pdf.text('DATUM:', this.pdfLeftPadding, 520);
                pdf.text(currentDateTime, this.pdfLeftPadding + 130, 520);

                pdf.text('KLASSE:', this.pdfLeftPadding, 590);
                pdf.text(this.module.event.group.name, this.pdfLeftPadding + 130, 590);

                pdf.text('FACH:', this.pdfLeftPadding, 660);
                pdf.text(this.module.subject.title, this.pdfLeftPadding + 130, 660);

                pdf.text('ZIEL:', this.pdfLeftPadding, 730);
                const goal: string = this.filteredUsers[i].goal ? this.filteredUsers[i].goal.toString() : '';
                pdf.text(goal, this.pdfLeftPadding + 130, 730);

                pdf.text('TEST: ', this.pdfPageWidth / 2, 730);

                const canvas_x_point = this.pdfPageWidth / 2 - canvasWidth / 2;
                const canvas_y_point = this.pdfPageHeight / 2 - 120;

                // Add student progress-box
                pdf.addImage(imgData, 'png', canvas_x_point, canvas_y_point, canvasWidth, canvasHeight);

                // Draw lines
                const after_canvas = canvas_y_point + canvasHeight + 60;
                pdf.setDrawColor(140, 140, 140);
                let line_y = 0;
                pdf.setLineWidth(1);
                for (let index = 0; index <= 7; index++) {
                    line_y = after_canvas + index * 45;
                    if (this.pdfPageHeight - 120 > line_y) {
                        pdf.line(
                            this.pdfLeftPadding + 40,
                            line_y,
                            this.pdfPageWidth - this.pdfLeftPadding - 40,
                            line_y
                        );
                    } else {
                        break;
                    }
                }

                pdf.setDrawColor(25, 54, 81);
                pdf.setLineWidth(3);
                pdf.line(
                    this.pdfLeftPadding,
                    this.pdfPageHeight - 90,
                    this.pdfPageWidth - this.pdfLeftPadding,
                    this.pdfPageHeight - 90
                );

                pdf.setTextColor(25, 54, 81);
                pdf.text('SEITE | ' + pageNumber, this.pdfPageWidth - 210, this.pdfPageHeight - 55);
                pdf.setTextColor(0, 0, 0);

                const studentName = this.filteredUsers[i].firstname + ' ' + this.filteredUsers[i].lastname;

                log.debug(
                    '%c ' + (i + 1) + '. Student : ' + studentName,
                    'color: #fdc05d; font-style: bold; font-size: 24px'
                );
                log.debug('PDF Page Width and Height => ' + this.pdfPageWidth + ':' + this.pdfPageHeight);
                log.debug('Canvas Width and Height => ' + canvas.width + ':' + canvas.height);
                log.debug('X,Y position of page to add the canvas: ' + canvas_x_point + ',' + canvas_y_point);

                if (i === this.numberOfStudents - 1) {
                    log.debug('Finished all. Saving PDF file');
                    this.progressBarWidth = 100;
                    pdf.save(this.pdfSaveFileName);
                    this.generatingPDF = false;
                    this.columnSize = 'is-3';
                } else {
                    log.debug('%c\n\nAdding a new page', 'color: #ff0004; font-style: bold; font-size: 18px');
                    pdf.addPage();
                    pageNumber++;
                    log.debug('Finished converting ' + studentName + ' progress box to Image.');
                    this.progressBarWidth = Math.floor((i / this.numberOfStudents) * 100);
                    this.convertHtml2canvas(i + 1, pdf, currentDateTime, pageNumber, logoImgData);
                }
                log.debug('\n\n\n');
            });
        }, 50);
    }
}
