



























































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import { generalFeedbackWebservice } from '@/webservices/generalFeedback';

@Component({
   name: 'GeneralFeedbackButton',
})
export default class GeneralFeedbackButton extends Vue {
    private isModalActive: boolean = false;
    private isSending: boolean = false;
    private feedbackTypes = [
                                'Ich habe eine Frage',
                                'Ich möchte euch gratulieren',
                                'Ich vermisse eine Funktion',
                                'Ich habe einen Fehler gefunden',
                                'Etwas anderes',
                            ];

    private selectedFeedbackType: string = 'Ich habe eine Frage';
    private feedbackContent: string = '';

    private toggleModal() {
        this.isModalActive = !this.isModalActive;
    }

    private send() {
        this.isSending = true;
        generalFeedbackWebservice.send(this.selectedFeedbackType, this.feedbackContent)
        .then((response) => {
            this.$buefy.snackbar.open({
                duration: 3000,
                message: `Das Feedback wurde erfolgreich verschickt`,
                type: 'is-primary',
                queue: true,
                position: 'is-top',
            });
            this.feedbackContent = '';
            this.isSending = false;
            this.toggleModal();
        }).catch((error) => {
            console.log(error);
        });
    }
}
