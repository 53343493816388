
















import { Component, Prop, Vue } from 'vue-property-decorator';
import { Topic } from '@/models/topic.ts';
import { GridElementPosition } from './GridElementPosition';

@Component
export default class GridTable extends Vue {

    @Prop() private data: any;
    @Prop({ type: Number, default: 150, }) private columnMin: number;
    @Prop({ type: Number, default: 1000, }) private columnMax: number;

    private getGridContainerStyle() {
        return 'grid-template-columns: ' + ('minmax(' + this.columnMin + 'px, ' + this.columnMax + 'px) ').repeat(this.data.header.length);
    }

    /**
     * Returns an index for the binding of the id.
     * It needs to be unique and we need to add an additional appendix.
     *
     * @param element any
     * @param appendix string
     *
     * @returns string The combined unique id
     */
    private getIndex(element: any, appendix: string): string {
        // Buefy does not care if there are multiple null entries, so only create this combined id when its not null
        return (element.id !== null) ? element.id + '_' + appendix : element.id
    }

    /*
        Get Position by index

        Example data:
            Table numbering (row.column)
            1.1     2.1     3.1     4.1     5.1
            1.2     2.2     3.2     4.2     5.2
            1.3     2.3     3.3     4.3     5.3
    */
    private getPosition(index: number): GridElementPosition {
        return {
            /*
                The column is the ongoing index modulo by the length, we start counting at 1 and not 0
                There for add the + 1
            */
            column: (index % this.data.header.length) + 1,

            /*
                The row is the increased index (also because we start counting with 1) divided
                by the header length and always rounded up (ceiling).
            */
            row: Math.ceil(++index / this.data.header.length),
        };
    }
}

