






























import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';

@Component({
    name: 'NotAuthorized',
})
export default class NotAuthorized extends Vue {
    @Getter('account/haveAccess') private haveAccess: boolean;

    @Watch('haveAccess')
    private onHaveAccessChange() {
        this.checkAccess();
    }

    private mounted() {
        this.checkAccess();
    }

    private checkAccess() {
        if (this.haveAccess) {
            this.$router.push({ name: 'Home' });
        }
    }
}
